import React from "react";
import { Nav, NavItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Route } from 'react-router';
import UsersListPage from '../../UsersPage/components/UsersListPage';
import ToolsConfigurator from '../Scenario/ToolsScenario';
import ToolsCalendar from './ToolsCalendar';
import ToolsCalendarModal from './ToolsCalendarModal';
import styled from 'styled-components';

export default function ToolsCalendarPage(props) {
  const Margin20 = styled.div`
    margin-top: 20px;
`;
  return (
    <div>
        <style>
            {`body {
                        background: #f1f2f7;
                    }`}
        </style>
      <Margin20 />
      <Route exact path={`${props.match.url}`} component={ToolsCalendar} />
      <Route exact path={`${props.match.url}/:id`} component={ToolsCalendarModal}/>
    </div>
  )
}
