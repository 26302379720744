import React from "react";
import { connect } from "react-redux";
import { FormGroup, ControlLabel, FormControl, Row, Col } from "react-bootstrap";
import {
    flatRateChanged,
    workingHoursChanged,
    nameHoursChanged
} from "../actions/externalActions";
import { parseFloatWithZeroFallback } from "../../utils";
import styled from "styled-components";
import isExternalDisabled from "../selectors/isExternalDisabled";

const HoursTable = styled.table`
    border: none !important;
    td {
        padding: 10px;
    }
`;
@connect(
    state => ({
        ...state.externals.external,
        disabled: isExternalDisabled(state)
    }),
    dispatch => ({
        flatRateChanged: ev => dispatch(flatRateChanged(ev.target.checked)),
        workingHoursChanged: value => dispatch(workingHoursChanged(value)),
        nameHoursChanged: (ev, name) => dispatch(nameHoursChanged(ev.target.value, name))
    })
)
export default class HoursForm extends React.Component {
    render() {
        let p = this.props;
        let hoursFieldsWithTypes = [];
        const calculateWorkingHours = (p) => {
            let sum = 0;
            p.hoursTypeKeys.forEach(key => sum += parseFloatWithZeroFallback(p[key]));
            p.workingHoursChanged(sum);
            return sum;
        }
        const generateTypes = (p) => {
            let result = [];
            p.hoursTypeKeys.forEach((type) => {
                    result.push(
                        <tr>
                            <td>
                                <ControlLabel>{type.slice(0,type.length-5)}</ControlLabel>
                            </td>
                            <td>
                                <FormControl
                                    disabled={this.props.disabled}
                                    type="number"
                                    className="input-very-small"
                                    value={p[type]}
                                    onChange={(ev)=>p.nameHoursChanged(ev, type)}
                                />
                            </td>
                        </tr>
                    );
                }
            )
            return result;
        }
        if (!p.flatRate) {
            hoursFieldsWithTypes = (
                <HoursTable>
                    <tbody>
                        <tr>
                            {generateTypes(p)}
                        </tr>
                    </tbody>
                </HoursTable>
            );
        }

        let workingHours = p.flatRate
            ? p.workingHours
            : calculateWorkingHours(p);
        return (
            <div>
                <Row>
                    <Col md={5}>
                        <FormGroup className="form-inline">
                            <ControlLabel>Working hours</ControlLabel>
                            <FormControl
                                type="number"
                                className="input-very-small"
                                disabled={!p.flatRate || this.props.disabled}
                                value={workingHours}
                                onChange={ev => p.workingHoursChanged(ev.target.value)}
                            />

                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        {/*<input*/}
                        {/*    type="checkbox"*/}
                        {/*    className="checkbox-big inline"*/}
                        {/*    checked={p.flatRate}*/}
                        {/*    onChange={p.flatRateChanged}*/}
                        {/*    disabled={this.props.disabled}*/}
                        {/*/>*/}
                        {/*<ControlLabel>Flat rate</ControlLabel>*/}
                    </Col>
                </Row>
                {hoursFieldsWithTypes}
            </div>
        );
    }
}
