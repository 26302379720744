import { deleteUser } from "./actions/usersListActions";
import React from "react";
import { connect } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { PERMISSION_EDIT_USERS } from "../../../common/src/permissions";
import SortableTableHeader from "../components/common/AutoTable/SortableTableHeader";
import ConfirmableButton from "../components/common/ConfirmableButton";
import IconButton from "../components/common/IconButton";
import { userHasPermissionSelector } from "../selectors/authSelectors";
import { encodeParams } from "../utils";
import LinkCell from "../components/common/AutoTable/cells/LinkCell";
import SimpleText from "../components/common/AutoTable/cells/SimpleText";
import ChangeUserButton from "./components/ChangeUserButton";

const SimpleTextOrLinkCell = props => {
    if (props.row.editable) {
        return <LinkCell {...props} />;
    } else {
        return <SimpleText {...props} />;
    }
};

export default {
    header: props => <SortableTableHeader {...props} query={props.runtimeProps.query} />,
    rowsSelector: state =>
        state.users.usersList.users.map(u => ({
            ...u,
            editable:
                userHasPermissionSelector(state, PERMISSION_EDIT_USERS) &&
                state.auth.user.group.editableGroups.indexOf(u.group._id) !== -1
        })),
    columns: [
        {
            title: "Name",
            valueSelector: r => r.displayName,
            cell: SimpleTextOrLinkCell,
            to: r => encodeParams`/dashboard/users/list/${r._id}`,
            sortKey: "displayName"
        },
        {
            title: "Email",
            valueSelector: r => r.email,
            cell: SimpleTextOrLinkCell,
            to: r => encodeParams`/dashboard/users/list/${r._id}`,
            sortKey: "email"
        },
        {
            title: "Access group",
            valueSelector: r => r.group && r.group.name,
            cell: SimpleTextOrLinkCell,
            to: r => encodeParams`/dashboard/users/list/${r._id}`,
            sortKey: "group.name"
        },
        {
            title: "Blocked",
            valueSelector: r => r.blocked,
            cell: ({ value }) => <input type="checkbox" disabled={true} checked={value} className="checkbox-big" />,
            sortKey: "blocked"
        },
        {
            title: "Actions",
            valueSelector: r => r._id,
            cell: connect()(({ value, row, dispatch }) => {
                return (
                    <div>
                        {row.editable && (
                            <div>
                                <LinkContainer to={encodeParams`/dashboard/users/list/${value}`}>
                                    <IconButton glyph="pencil" bsStyle="primary" marginRight />
                                </LinkContainer>
                                <ConfirmableButton onConfirm={_ => dispatch(deleteUser(row._id))} />
                                <ChangeUserButton user={row} />
                            </div>
                        )}
                    </div>
                );
            })
        }
    ]
};
