import { stringify as stringifyQueryString } from "querystring";
import React from "react";
import { Link } from "react-router-dom";

const SortableLink = ({ prop, children, query, queryOverrides }) => {
    let sortMode = null;
    let symbol = null;
    if (query.sortBy === "-" + prop) {
        sortMode = "asc";
        symbol = <span>&#x25B2;</span>;
    } else if (query.sortBy === prop) {
        sortMode = "desc";
        symbol = <span>&#x25BC;</span>;
    }

    return (
        <th>
            <Link
                to={
                    "?" +
                    stringifyQueryString({
                        ...query,
                        sortBy: sortMode === "desc" ? "-" + prop : prop,
                        ...queryOverrides
                    })
                }
            >
                {children}
                {symbol}
            </Link>
        </th>
    );
};

export default ({ query, model, queryOverrides }) => (
    <thead>
        <tr>
            {model.columns.map((c, i) =>
                c.sortKey ? (
                    <SortableLink query={query} prop={c.sortKey} key={i} queryOverrides={queryOverrides}>
                        {c.title}
                    </SortableLink>
                ) : (
                    <th key={i}>{c.title}</th>
                )
            )}
        </tr>
    </thead>
);
