import {apiFetch} from "../../../../actions/apiActions";
import {toast} from "react-toastify";
import {makeActionCreator} from "../../../../utils";

export const DATA_CHANGED = "CLIENT_PANEL.CLIENT_PANEL_DASHBOARD.DATA_CHANGED";
export const DATA_CHANGED_ORDER_NAME = "CLIENT_PANEL.CLIENT_PANEL_DASHBOARD.DATA_CHANGED_ORDER_NAME";
export const dataChanged = makeActionCreator(DATA_CHANGED, "payload");
export const dataChangedOrderName = makeActionCreator(DATA_CHANGED_ORDER_NAME, "_id", "payload");

export function loadActiveOrders(state) {
    return async (dispatch, getState) => {
        let requestUrl = `/api/customer-panel/dashboard-stats`;
        let userId;
        if(getState().router.location.pathname.indexOf('/dashboard/simulations/customer/') !== -1) {
            userId = getState().router.location.pathname.split('/')[4];
            requestUrl = `${requestUrl}/${userId}`;
        }
        try {
            const resp = await dispatch(apiFetch(requestUrl, {}));
            const data = await resp.json();
            if (!resp.ok) {
                throw new Error(data.message);
            }
            dispatch(dataChanged(data));
        } catch (e) {
            console.error(e);
            toast("Failed to load orders: " + e.message, { type: "error" });
        }
    };
}

function updateOrderName() {
    return async (dispatch, getState) => {
        try {
            let resp = await dispatch(
                apiFetch("/api/customer-panel/dashboard-stats/order-name", {
                    method: "POST",
                    body: JSON.stringify({
                        data: getState().clientPanel.clientPanelDashboard.data.ordersTableRows.map(r=>{ return {_id: r._id, orderName: r.orderName} })
                    })
                })
            );
            let data = await resp.json();
            if (resp.status !== 200) {
                throw new Error(data.message);
            }
        } catch (e) {
            toast(e.message, { type: "error" });
        }
    }
}

export function setOrderName(value, rowId) {
    return async (dispatch) => {
        dispatch(dataChangedOrderName(rowId, value))
        dispatch(updateOrderName());
    }
}
