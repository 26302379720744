// import { ORDER_DATA_CHANGED, ORDER_RESET } from "../actions/orderActions";
import {ORDER_CALENDAR_CHANGED} from "../actions/calendarActions";
import {ORDER_DATA_CHANGED, ORDER_RESET} from "../actions/orderActions";

const makeInitialState = _ => ({
    calendar: null
});

export default function calendar(state = makeInitialState(), action) {
    switch (action.type) {
        case ORDER_DATA_CHANGED:
            return action.payload.calendar;
        case ORDER_RESET:
            return makeInitialState();
        case ORDER_CALENDAR_CHANGED:
            return {
                ...state,
                calendar: action.payload
            };
        default:
            return state;
    }
}
