import { makeActionCreator } from "../../utils";
import { apiFetch } from "../../actions/apiActions";
import download from "downloadjs";
import { toast } from "react-toastify";

export const STATE_DEFAULT = "STATE_DEFAULT";
export const STATE_LOADING = "STATE_LOADING";
export const STATE_LOADED = "STATE_LOADED";
export const STATE_ERROR = "STATE_ERROR";

export const TIME_RANGE_CHANGED = "OFFICE.APPROVED_REPORTS_LIST.TIME_RANGE_CHANGED";
export const STATE_CHANGED = "OFFICE.APPROVED_REPORTS_LIST.STATE_CHANGED";
export const ERROR_CHANGED = "OFFICE.APPROVED_REPORTS_LIST.ERROR_CHANGED";
export const REPORTS_CHANGED = "OFFICE.APPROVED_REPORTS_LIST.REPORTS_CHANGED";
export const SHOW_GENERATE_REPORT_MODAL_CHANGED = "OFFICE.APPROVED_REPORTS_LIST.SHOW_GENERATE_REPORT_MODAL_CHANGED";
export const MODAL_DATE_START_CHANGED = "OFFICE.APPROVED_REPORTS_LIST.MODAL_DATE_START_CHANGED";
export const MODAL_DATE_END_CHANGED = "OFFICE.APPROVED_REPORTS_LIST.MODAL_DATE_END_CHANGED";
export const MODAL_EMPLOYEE_CHANGED = "OFFICE.APPROVED_REPORTS_LIST.MODAL_EMPLOYEE_CHANGED";
export const MODAL_EMPLOYER_CHANGED = "OFFICE.APPROVED_REPORTS_LIST.MODAL_EMPLOYER_CHANGED";
export const MODAL_COMPANY_CHANGED = "OFFICE.APPROVED_REPORTS_LIST.MODAL_COMPANY_CHANGED";
export const MODAL_CUSTOMER_CHANGED = "OFFICE.APPROVED_REPORTS_LIST.MODAL_CUSTOMER_CHANGED";
export const MODAL_LOCATION_CHANGED = "OFFICE.APPROVED_REPORTS_LIST.MODAL_LOCATION_CHANGED";

export const MODAL_ORDER_CHANGED = "OFFICE.APPROVED_REPORTS_LIST.MODAL_ORDER_CHANGED";

export const stateChanged = makeActionCreator(STATE_CHANGED, "payload");
export const errorChanged = makeActionCreator(ERROR_CHANGED, "payload");
export const reportsChanged = makeActionCreator(REPORTS_CHANGED, "payload");
export const showGenerateReportModalChanged = makeActionCreator(SHOW_GENERATE_REPORT_MODAL_CHANGED, "payload");
export const timeRangeChanged = makeActionCreator(TIME_RANGE_CHANGED, "payload");
export const modalDateStartChanged = makeActionCreator(MODAL_DATE_START_CHANGED, "payload");
export const modalDateEndChanged = makeActionCreator(MODAL_DATE_END_CHANGED, "payload");
export const modalEmployeeChanged = makeActionCreator(MODAL_EMPLOYEE_CHANGED, "payload");
export const modalEmployerChanged = makeActionCreator(MODAL_EMPLOYER_CHANGED, "payload");
export const modalCompanyChanged = makeActionCreator(MODAL_COMPANY_CHANGED, "payload");
export const modalOrderChanged = makeActionCreator(MODAL_ORDER_CHANGED, "payload");
export const modalCustomerChanged = makeActionCreator(MODAL_CUSTOMER_CHANGED, "payload");
export const modalLocationChanged = makeActionCreator(MODAL_LOCATION_CHANGED, "payload");

export function refreshApprovedReports() {
    return (dispatch, getState) => {
        const state = getState().office.approvedReportsTable;
        return dispatch(loadRequested(state));
    };
}

export function loadRequested({ week, year } = {}) {
    return async dispatch => {
        try {
            dispatch(stateChanged(STATE_LOADING));
            dispatch(timeRangeChanged({ week, year }));
            let resp = await dispatch(apiFetch("/api/reports/approved", {}, { week, year }));
            let data = await resp.json();
            if (resp.status !== 200) {
                throw new Error(data.message);
            }
            dispatch(reportsChanged(data));
            dispatch(stateChanged(STATE_LOADED));
        } catch (e) {
            dispatch(errorChanged(e.message));
            dispatch(stateChanged(STATE_ERROR));
        }
    };
}

export function generateReport() {
    return async (dispatch, getState) => {
        try {
            dispatch(showGenerateReportModalChanged(false));
            dispatch(stateChanged(STATE_LOADING));
            let {
                modalDateEnd,
                modalDateStart,
                modalEmployee,
                modalEmployer,
                modalCompany,
                modalOrder,
                modalCustomer,
                modalLocation
            } = getState().office.approvedReportsTable;
            let resp = await dispatch(
                apiFetch("/api/reports/working-hours-report", undefined, {//test

                    dateEnd: modalDateEnd.toString(),
                    dateStart: modalDateStart.toString(),
                    // dateStart: "2022-09-30T23:00:00.000Z",
                    // dateStart: "2022-11-22T23:00:00.000Z",
                    employee: modalEmployee,
                    employer: modalEmployer,
                    company: modalCompany,
                    order: modalOrder,
                    // order: "633dcfb4444f9e68c12296d3",// przetestować dla Magny
                    // order: "5a4ba781fbad0645b7994f9c",// przetestować dla Magny to na tym testowałem nowe zlecenie i nowe raporty
                    customer: modalCustomer,
                    location: modalLocation
                })
            );
            if (resp.status !== 200) {
                throw new Error((await resp.json()).message);
            }

            let data = await resp.blob();
            download(data);

        } catch (e) {
            toast(e.message, { type: "error" });
        } finally {
            dispatch(stateChanged(STATE_LOADED));
        }
    };
}
