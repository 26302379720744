import React from 'react';
import {PartContainer} from "../common";
import {connect} from "react-redux";

function Details({orderName, orderParts}) {
    return (
        <>
            <PartContainer >{orderParts}</PartContainer>
        </>
    )
}

const mapStateToProps = ({clientPanel}) => {
    return {
        ...clientPanel.detailsPageReducer.data
    }
}

export default connect(mapStateToProps, null)(Details);
