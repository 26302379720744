import {
    STATE_DEFAULT,
    STATE_CHANGED,
    ERROR_CHANGED,
    EXTERNAL_DATA_CHANGED,
    REMARKS_CHANGED,
    FLAT_RATE_CHANGED,
    WORKING_HOURS_CHANGED,
    FROM_TIME_CHANGED,
    TO_TIME_CHANGED,
    EXTERNAL_MODAL_OPEN_CHANGED,
    HIDDEN_FROM_CLIENT_CHANGED,
    SHOW_REMARKS_CHANGED,
    QA_ROW_REMARKS_CHANGED,
    QA_ROW_SHOW_REMARKS_CHANGED,
    HIDE_REASON_CHANGED,
    PRODUCT_QA_CHANGED,
    FROM_TIME_TO_TIME_CHANGED,
    NAME_HOURS_CHANGED
} from "../actions/externalActions";
import { selectObjectKeys, updateRow } from "../../utils";

export const initialState = {
    id: null,
    externalModalOpen: false,
    state: STATE_DEFAULT,
    flatRate: false,
    error: null,
    controlDate: null,
    orderNumber: null,
    connectedOrder: null,
    connectedReport: null,
    remarks: null,
    formularNumber: null,
    hiddenFromClient: false,
    showRemarks: false,
    workingHours: 0,
    hideReason: null,
    productQA: null,
    scenario: null
};

export default function external(state = initialState, action) {
    switch (action.type) {
        case STATE_CHANGED:
            return {
                ...state,
                state: action.payload
            };
        case ERROR_CHANGED:
            return {
                ...state,
                error: action.payload
            };
        case EXTERNAL_DATA_CHANGED: {
            let p = action.payload;
            let sum = 0;
            p.hoursTypeKeys.forEach(type => {sum += p[type]})

            return {
                ...state,
                ...selectObjectKeys(p, [
                    "controlDate",
                    "connectedOrder",
                    "orderNumber",
                    "fromTime",
                    "toTime",
                    "formularNumber",
                    "remarks",
                    "connectedReport",
                    "hiddenFromClient",
                    "showRemarks",
                    "scenario",
                    ...p.hoursTypeKeys
                ]),
                hoursTypeKeys: p.hoursTypeKeys,
                productQA: p.productQA.map(r => ({
                    ...r,
                    showRemarks: r.showRemarks || false
                })),
                id: p._id ? p._id : null,
                workingHours: sum
            };
        }
        case PRODUCT_QA_CHANGED:
            return {
                ...state,
                productQA: action.payload
            };
        case REMARKS_CHANGED:
            return {
                ...state,
                remarks: action.payload
            };
        case FLAT_RATE_CHANGED:
            return {
                ...state,
                flatRate: action.payload
            };
        case WORKING_HOURS_CHANGED:
            return {
                ...state,
                workingHours: action.payload
            };
        case NAME_HOURS_CHANGED:
            return {
                ...state,
                [action.name]: action.payload
            }
        case FROM_TIME_CHANGED:
            return {
                ...state,
                fromTime: action.payload
            };
        case TO_TIME_CHANGED:
            return {
                ...state,
                toTime: action.payload
            };
        case EXTERNAL_MODAL_OPEN_CHANGED:
            return {
                ...state,
                externalModalOpen: action.payload
            };
        case HIDDEN_FROM_CLIENT_CHANGED:
            return {
                ...state,
                hiddenFromClient: action.payload
            };
        case SHOW_REMARKS_CHANGED:
            return {
                ...state,
                showRemarks: action.payload
            };
        case HIDE_REASON_CHANGED:
            return {
                ...state,
                hideReason: action.payload
            };
        case QA_ROW_REMARKS_CHANGED:
            return {
                ...state,
                productQA: updateRow(state.productQA, "remarks", action)
            };
        case QA_ROW_SHOW_REMARKS_CHANGED:
            return {
                ...state,
                productQA: updateRow(state.productQA, "showRemarks", action)
            };
        case FROM_TIME_TO_TIME_CHANGED:
            return {
                ...state,
                fromTime: action.fromTime,
                toTime: action.toTime
            };
        default:
            return state;
    }
}
