import { makeActionCreator } from '../../utils';
import { apiFetch } from '../../actions/apiActions';
import { toast } from 'react-toastify';
import { push } from 'react-router-redux';

export const TOOLS_IS_LOADING = "TOOLS_IS_LOADING";
export const TOOLS_CALENDAR_TITLE = "TOOLS_CALENDAR_TITLE";
export const TOOLS_CALENDAR_DESCRIPTION = "TOOLS_CALENDAR_DESCRIPTION";
export const TOOLS_HOLIDAYS = "TOOLS_HOLIDAYS";
export const TOOLS_HOLIDAY_ADD = "TOOLS_HOLIDAY_ADD";
export const TOOLS_HOLIDAY_ADD_TIME = "TOOLS_HOLIDAY_ADD_TIME";
export const TOOLS_HOLIDAY_NEW_HOLIDAY_TO_SAVE_REPORT_HEADER = "TOOLS_HOLIDAY_NEW_HOLIDAY_TO_SAVE_REPORT_HEADER";
export const TOOLS_HOLIDAY_NEW_HOLIDAY_TO_SAVE_HEADER = "TOOLS_HOLIDAY_NEW_HOLIDAY_TO_SAVE_HEADER";
export const TOOLS_HOLIDAY_NEW_HOLIDAY_TO_SAVE_HEADER_AND_REPORT_HEADER = "TOOLS_HOLIDAY_NEW_HOLIDAY_TO_SAVE_HEADER_AND_REPORT_HEADER";

export const TIME_ARRAY_CHANGED = "TIME_ARRAY_CHANGED";
export const TOOLS_HOLIDAY_TIME_CHANGED = "TOOLS_HOLIDAY_TIME_CHANGED";
export const TIME_ELEMENT_ADD = "TIME_ELEMENT_ADD";
export const TIME_ELEMENT_DELETE = "TIME_ELEMENT_DELETE";
export const TOOLS_ALL_TIME_LIST = "TOOLS_ALL_TIME_LIST";
export const TIME_ELEMENT_AUTHOR = "TIME_ELEMENT_AUTHOR";
export const TOOLS_HOLIDAY_NEW_HOLIDAY_TO_SAVE = "TOOLS_HOLIDAY_NEW_HOLIDAY_TO_SAVE";

const setToolsLoading = makeActionCreator(TOOLS_IS_LOADING, "isLoading");
const setToolsAllTimeList = makeActionCreator(TOOLS_ALL_TIME_LIST, "payload");
export const setToolsHolidays = makeActionCreator(TOOLS_HOLIDAYS, "payload");
export const addHoliday = makeActionCreator(TOOLS_HOLIDAY_ADD, "color");
export const addDateToHoliday = makeActionCreator(TOOLS_HOLIDAY_ADD_TIME, "holidayIndex", "payload");
export const setTime = makeActionCreator(TOOLS_HOLIDAY_TIME_CHANGED, "holidayIndex", "timeIndex", "payload");

export const setTimeArray = makeActionCreator(TIME_ARRAY_CHANGED, "payload");
export const addTimeElement = makeActionCreator(TIME_ELEMENT_ADD);
export const deleteTimeElement = makeActionCreator(TIME_ELEMENT_DELETE, "holidayIndex", "timeIndex");
export const setCalendarTitle= makeActionCreator(TOOLS_CALENDAR_TITLE, "payload");
export const setCalendarDescription= makeActionCreator(TOOLS_CALENDAR_DESCRIPTION, "payload");
export const setAuthor = makeActionCreator(TIME_ELEMENT_AUTHOR, "payload");
export const setReportHeaderName = makeActionCreator(TOOLS_HOLIDAY_NEW_HOLIDAY_TO_SAVE_REPORT_HEADER, "holidayColor", "payload");
export const setHeaderName = makeActionCreator(TOOLS_HOLIDAY_NEW_HOLIDAY_TO_SAVE_HEADER, "holidayColor", "payload");
export const setReportHeaderNameAndHeaderName = makeActionCreator(TOOLS_HOLIDAY_NEW_HOLIDAY_TO_SAVE_HEADER_AND_REPORT_HEADER, "holidayIndex", "reportHeaderName", "name");
export const setNewHolidayToSave = makeActionCreator(TOOLS_HOLIDAY_NEW_HOLIDAY_TO_SAVE, "payload");
export function addCalendarTools (calendarName) {
  return async (dispatch, getState) => {
    try {
      dispatch(setToolsLoading(true));
      let resp = await dispatch(
        apiFetch("/api/tools/holiday/new", {
          method: "POST",
          body: JSON.stringify({
            calendarTitle: calendarName
          })
        })
      );
      let data = await resp.json();
      if (resp.status !== 200) {
        throw new Error(data.message);
      }
      toast("Created new time template!", { type: "success" });
      dispatch(getAllTimeTools());
      dispatch(setToolsHolidays(data.holidays));
      dispatch(setToolsLoading(false));
      dispatch(push(`/dashboard/calendar/${data._id}`));
    } catch (e) {
      toast(e.message, { type: "error" });
    }
  }
}

export function getAllTimeTools() {
  return async (dispatch) => {
    try {
      let resp = await dispatch(
        apiFetch("/api/tools/holiday/all", {
          method: "GET"
        })
      );
      let data = await resp.json();
      if (resp.status !== 200) {
        throw new Error(data.message);
      }
      dispatch(setToolsAllTimeList(data.calendarData));
    } catch (e) {
      toast(e.message, {type: 'error'});
    }
  }
}

export function getCalendar(id) {
  return async (dispatch) => {
    dispatch(setNewHolidayToSave([]));
    dispatch(setCalendarTitle(""));
    dispatch(setToolsHolidays([]));
    dispatch(setToolsLoading(true));
    try {
      let resp = await dispatch(
        apiFetch(`/api/tools/holiday/${id}`, {
          method: "GET"
        })
      );
      let data = await resp.json();
      if (resp.status !== 200) {
        throw new Error(data.message);
      }
      dispatch(setCalendarTitle(data.data.calendarTitle));
      dispatch(setCalendarDescription(data.data.calendarDescription));
      dispatch(setToolsHolidays(data.data.holidays));
      dispatch(setAuthor(data.data.author[0]));
      dispatch(setToolsLoading(false));
    } catch (e) {
      toast(e.message, {type: 'error'});
    }
  }
}

export function saveCalendarTools(id, modalVersionMessage) {
  return async (dispatch, getState) => {
    try {

      dispatch(setToolsLoading(true));
      const toolsData = getState().toolsReducer;
      let preparedHolidays = toolsData.newHolidaysToSave[0];
      if(!toolsData.newHolidaysToSave[0]) {
        preparedHolidays = {"name" : "holiday",
          "reportHeaderName" : "Holiday",
          "dates" : []}
      }
      if(toolsData.newHolidaysToSave.length > 1) {
        preparedHolidays.dates = [...toolsData.newHolidaysToSave[1].dates, ...toolsData.newHolidaysToSave[0].dates]
      }
      let resp = await dispatch(
        apiFetch(`/api/tools/holiday/${id}`, {
          method: "PATCH",
          body: JSON.stringify({
            holidays: preparedHolidays,
            calendarTitle: toolsData.calendarTitle,
            calendarDescription: toolsData.calendarDescription,
            reasonOfChange: modalVersionMessage
          })
        })
      );
      let data = await resp.json();
      if (resp.status !== 200) {
        throw new Error(data.message);
      }
      dispatch(getAllTimeTools());
      dispatch(push(`/dashboard/calendar`));

      dispatch(setToolsLoading(false));
    } catch (e) {
      toast(e.message, {type: 'error'});
    }
  }
}
