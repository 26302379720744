import { combineReducers } from "redux";
import desktop from "./desktop";
import companiesList from "./companiesList";
import simulation from "./simulation";

export default combineReducers({
    companiesList,
    desktop,
    simulation
});
