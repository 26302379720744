import React from 'react';
import styled, {css} from "styled-components";

const MainContainer = styled.div`
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    flex-shrink: 0;
    position: sticky;
    z-index: 1100;
    left: auto;
    right: 0px;
    box-shadow: none;
    backdrop-filter: none;
    color: rgb(123, 128, 154);
    top: 0.75rem;
    min-height: 4.6875rem;
    display: grid;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 0.75rem;
    padding: 0.5rem 0px;
    background-color: transparent !important;
`;

const InsideContainer = styled.div`
    min-height: auto;
    padding: 0.25rem 1rem;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
`;

const LeftContainer = styled.div`
    justify-content: stretch !important;
    width: max-content !important;
`;

const H6 = styled.h6`
    margin: 0px;
    font-size: 32px;
    line-height: 1.625;
    font-family: Roboto, Helvetica, Arial, sans-serif !important;
    letter-spacing: 0.2px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    opacity: 1;
    text-transform: capitalize;
    vertical-align: unset;
    text-decoration: none;
    color: #334967;
    font-weight: 700;
`;

const RightContainer = styled.div`
    justify-content: stretch !important;
    width: max-content !important;
`;
export default function HeaderContainer(props) {
    return (
        <MainContainer>
            <InsideContainer>
                <LeftContainer>
                    <H6>
                        {props.title}
                    </H6>
                </LeftContainer>
                <RightContainer />
            </InsideContainer>
        </MainContainer>
    )
}
