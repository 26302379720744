import {
  TIME_ARRAY_CHANGED,
  TOOLS_HOLIDAY_TIME_CHANGED,
  TIME_ELEMENT_ADD,
  TIME_ELEMENT_DELETE,
  TOOLS_CALENDAR_TITLE,
  TOOLS_CALENDAR_DESCRIPTION,
  TOOLS_IS_LOADING,
  TOOLS_ALL_TIME_LIST,
  TIME_ELEMENT_AUTHOR,
  TOOLS_HOLIDAYS,
  TOOLS_HOLIDAY_ADD,
  TOOLS_HOLIDAY_ADD_TIME,
  TOOLS_HOLIDAY_NEW_HOLIDAY_TO_SAVE_REPORT_HEADER,
  TOOLS_HOLIDAY_NEW_HOLIDAY_TO_SAVE_HEADER,
  TOOLS_HOLIDAY_NEW_HOLIDAY_TO_SAVE
} from '../actions/toolsActions';

const initialState = {
  allTimeList: [],
  isLoading: false,
  calendarTitle: "",
  calendarDescription: "",
  author: {},
  holidays: [],
  timeArray: [],
  newHolidaysToSave: []
}

export default function tools(state = initialState, action) {
  switch (action.type) {
    case TIME_ARRAY_CHANGED:
      return {
        ...state,
        timeArray: action.payload
      };
    case TOOLS_HOLIDAY_TIME_CHANGED:
      return {
        ...state,
        holidays: state.holidays.map((holiday, i) => {
          if (action.holidayIndex !== i) return holiday;
          return {
            ...holiday,
            dates: holiday.dates.map((date, i) => {
              if(i !== action.timeIndex) return date;
              return action.payload;
            })
          };
        })
      }
    case TIME_ELEMENT_ADD:
      return {
        ...state,
        timeArray: [...state.timeArray, ""]
      }
    case TIME_ELEMENT_DELETE:
      return {
        ...state,
        holidays: state.holidays.map((holiday, i) => {
          if (action.holidayIndex !== i) return holiday;
          return {
            ...holiday,
            dates: holiday.dates.filter((_, i) => i !== action.timeIndex)
          };
        })
      }

    case TOOLS_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading
      }
    case TOOLS_CALENDAR_TITLE:
      return {
        ...state,
        calendarTitle: action.payload
      }
    case TOOLS_CALENDAR_DESCRIPTION:
      return {
        ...state,
        calendarDescription: action.payload
      }
    case TOOLS_ALL_TIME_LIST:
      return {
        ...state,
        allTimeList: action.payload
      }
    case TIME_ELEMENT_AUTHOR:
      return {
        ...state,
        author: action.payload
      }
    case TOOLS_HOLIDAYS:
      return {
        ...state,
        holidays: action.payload
      }
    case TOOLS_HOLIDAY_ADD:
      return {
        ...state,
        holidays: [...state.holidays, {
          name: 'default',
          reportHeaderName: 'Default',
          dates: [],
          color: action.color
        }],
        newHolidaysToSave: [...state.newHolidaysToSave, {
          color: action.color,
          dates: []
        }]
      }
    case TOOLS_HOLIDAY_ADD_TIME:
      return {
        ...state,
        holidays: state.holidays.map((holiday, i) => {
          if (action.holidayIndex !== i) return holiday;

          return {
            ...holiday,
            dates: [...holiday.dates, ""]
          };
        })
      }
    case TOOLS_HOLIDAY_NEW_HOLIDAY_TO_SAVE_REPORT_HEADER:
      return {
        ...state,
        holidays: state.holidays.map((holiday) => {
          if (action.holidayColor !== holiday.color) return holiday;
          return {
            ...holiday,
            reportHeaderName: action.payload
          };
        })
      }
    case TOOLS_HOLIDAY_NEW_HOLIDAY_TO_SAVE_HEADER:
      return {
        ...state,
        holidays: state.holidays.map((holiday) => {
          if (action.holidayColor !== holiday.color) return holiday;
          return {
            ...holiday,
            name: action.payload
          };
        })
      }
    case TOOLS_HOLIDAY_NEW_HOLIDAY_TO_SAVE:
      return {
        ...state,
        newHolidaysToSave: action.payload
      }
    default:
      return state;
  }
}
