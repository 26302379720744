import React from 'react';
import {PartContainer, TitleContainer} from "../common";
import {connect} from "react-redux";
import styled from "styled-components";

export const Container = styled.div`
    font-size: 14px;
    font-weight: 400;
    color: rgb(123, 128, 154);
    max-height: 40px;
    overflow-y: auto;
`;
function Order({orderNumber, orderName, orderProblemDescription}) {
    return (
        <>
            <TitleContainer>{orderNumber}<span style={{width: 25, display: "block"}}/>{orderName}</TitleContainer>
            <Container>{orderProblemDescription}</Container>
        </>
    )
}

const mapStateToProps = ({clientPanel}) => {
    return {
        ...clientPanel.detailsPageReducer.data
    }
}

export default connect(mapStateToProps, null)(Order);
