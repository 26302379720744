import React, {useEffect} from 'react';
import {Col, ControlLabel, FormControl, FormGroup, InputGroup} from "react-bootstrap";
import MainCalendar from "./MainCalendar";
import {
    setCalendarTitle,
    setCalendarDescription,
    getCalendar
} from "../actions/toolsActions";
import {connect} from "react-redux";
import MatInput from "../../components/common/Mat/MatInput";
import MatGrid from "../../components/common/Mat/MatGrid";
import MatBox from "../../components/common/Mat/MatBox";

function ToolsCalendarModal({ calendarTitle, setCalendarTitleData, calendarDescription, setCalendarDescriptionData,
  holidays, match, getCalendarData}) {
    useEffect(() => {
        if (match.params.id) {
            getCalendarData(match.params.id);
        }
    }, [match]);

    return (
        <div style={{justifyContent: 'center'}}>
            <MatBox style={{maxWidth: 990}}>
                <MatGrid.Row>
                    <MatGrid.Col width="50%" style={{"marginBottom": -15, "marginTop": 15}}>
                        <MatInput
                            label="Calendar's name"
                            type="text"
                            value={calendarTitle}
                            onChange={(event) => setCalendarTitleData(event.target.value)}
                        />
                    </MatGrid.Col>
                </MatGrid.Row>
                <MatGrid.Row>
                    <FormGroup style={{marginBottom: 25, width: "100%"}}>
                        <ControlLabel>Description</ControlLabel>
                        <FormControl
                            componentClass="textarea"
                            value={calendarDescription}
                            onChange={(event) => {
                                setCalendarDescriptionData(event.target.value)
                            }}/>
                    </FormGroup>
                </MatGrid.Row>
            </MatBox>
            <MainCalendar key={`calendar-id-${match.params.id}`} holidays={holidays} match={match}/>
        </div>
    )
}

const mapStateToProps = ({toolsReducer}) => {
    return {
        ...toolsReducer
    }
}

function mapDispatchToProps() {
    return dispatch => ({
        setCalendarTitleData: (value) => dispatch(setCalendarTitle(value)),
        setCalendarDescriptionData: (value) => dispatch(setCalendarDescription(value)),
        getCalendarData: (id) => dispatch(getCalendar(id))
    });
}

export default connect(mapStateToProps, mapDispatchToProps)(ToolsCalendarModal);
