import React from "react";
import { connect } from "react-redux";
import { groupsSearched } from "../actions/editUserActions";
import { Async as AsyncSelect } from "react-select";

@connect(
    state => ({
        user: state.auth.user
    }),
    dispatch => ({
        groupsSearched: q => dispatch(groupsSearched(q))
    })
)
export default class GroupSelect extends React.Component {
    loadGroupsOptions = async query => {
        let groups = await this.props.groupsSearched(query);

        if (this.props.limitToEditableGroups && this.props.user) {
            groups = groups.filter(g => this.props.user.group.editableGroups.indexOf(g._id) !== -1);
        }
        return {
            options: groups.map(g => ({ label: g.name, value: g._id }))
        };
    };
    render() {
        return (
            <AsyncSelect
                ignoreAccents={false}
                placeholder="Search groups"
                loadOptions={this.loadGroupsOptions}
                {...this.props}
            />
        );
    }
}
