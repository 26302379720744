import React from 'react'
import OrdersTable from "./OrdersTable";
import {Description, Header} from "../common";
import StyledIcon from "../../../../components/common/StyledIcon";
export default function OrdersTableComponent(props) {

    return (<>
        <Header>
            Orders
        </Header>
        <Description>
            <StyledIcon
                glyph="ok"
                title="Active this month"
                style={{fontSize: 12, color: "blue", marginRight: 6}}
            />Active this month: {props.data.length}
        </Description>
        <OrdersTable data={props.data} setOrderName={props.setOrderName} />
    </>)
}
