import React, {useEffect, useState} from 'react';
import PrimaryContainer from "./PrimaryContainer/PrimaryContainer";
import {Col, Row} from "react-bootstrap";
import ChartContainer from "./ChartContainerComponent";
import HeaderContainer from "./HeaderContainer";
import {loadActiveOrders, setOrderName} from "./actions/dashboardActions";
import {connect} from "react-redux";
import {Container, ContainerInside, ContentContainer, MainBottomContainer} from "./common";
import {OrdersOverview} from "./OrdersOverview";
import OrdersTableComponent from "./OrdersTableComponent/OrdersTableComponent";
import {MainSpinnerContainer} from "../MainSpinnerContainer";

function BetaPage (props) {
    const [nokReworked, setNokReworked] = useState(0);
    const [nokReworkedRange, setNokReworkedRange] = useState("week");
    const [nokReworkedGraph, setNokReworkedGraph] = useState(0);

    const [nokReworkedRangeGraph, setNokReworkedRangeGraph] = useState("year");
    const [partsCheckedRangeGraph, setPartsCheckedRangeGraph] = useState("year");
    const [workingHoursRangeGraph, setWorkingHoursRangeGraph] = useState("year");

    const [orderRange, setOrderRange] = useState("year");
    const [partCheckedRange, setPartCheckedRange] = useState("week");
    const [workingHoursRange, setWorkingHoursRange] = useState("week");
    const [isLoading, setIsLoading] = useState(false);

    let rangeArray = ["week", "month", "year"];
    let setNokReworkedFunction = () => {
        if(rangeArray.findIndex(el => el === nokReworkedRange) === 2) {
            setNokReworked((nokReworked + 1) % 2);
        }
        setNokReworkedRange(rangeArray[(rangeArray.findIndex(el => el === nokReworkedRange) + 1) % 3])
    }

    let setOrderRangeFunction = () => {
        let ranges = ["year", "total"];
        setOrderRange(ranges[(ranges.findIndex(range => range === orderRange) + 1) % 2]);
    }

    let setPartCheckedFunction = () => {
        setPartCheckedRange(rangeArray[(rangeArray.findIndex(range => range === partCheckedRange) + 1) % 3]);
    }

    let setWorkingHoursRangeFunction = () => {
        setWorkingHoursRange(rangeArray[(rangeArray.findIndex(range => range === workingHoursRange) + 1) % 3]);
    }

    let setNokReworkedGraphFunction = () => {
        if(rangeArray.findIndex(el => el === nokReworkedRangeGraph) === 2) {
            setNokReworkedGraph((nokReworkedGraph + 1) % 2);
        }
        setNokReworkedRangeGraph(rangeArray[(rangeArray.findIndex(el => el === nokReworkedRangeGraph) + 1) % 3])
    }

    let setPartsCheckedRangeGraphFunction = () => {
        setPartsCheckedRangeGraph(rangeArray[(rangeArray.findIndex(el => el === partsCheckedRangeGraph) + 1) % 3]);
    }
    let setWorkingHoursRangeGraphFunction = () => {
        setWorkingHoursRangeGraph(rangeArray[(rangeArray.findIndex(el => el === workingHoursRangeGraph) + 1) % 3]);
    }

    useEffect(() => {
        props.loadActiveOrdersData();
        setIsLoading(true);
        setTimeout(()=> setIsLoading(false),1800);
    }, [])

    return (<div>
        {isLoading && (<MainSpinnerContainer />)}
        <div style={{fontSize: 16, backgroundColor: "rgb(240, 242, 245)"}}>
            <Row style={{marginLeft: "auto", marginRight: "auto"}}>
                <Col xs={12} style={{paddingLeft: 32}}>
                    <HeaderContainer title="Global results" />
                </Col>
            </Row>
            <Row style={{marginLeft: "auto", marginRight: "12px"}}>
                <Col xs={12} sm={6} md={3} style={{padding: 0}}>
                    <PrimaryContainer title="Orders" values={props.data.orders} mode="1" defaultRange={orderRange} clickFunction={setOrderRangeFunction} icon="inbox"/>
                </Col>
                <Col xs={12} sm={6} md={3} style={{padding: 0}}>
                    <PrimaryContainer title="Parts checked" values={props.data.partChecked} mode="2" defaultRange={partCheckedRange} clickFunction={setPartCheckedFunction} icon="check"/>
                </Col>
                <Col xs={12} sm={6} md={3} style={{padding: 0}}>
                    <PrimaryContainer title="Working hours" values={props.data.workingHours} mode="3" defaultRange={workingHoursRange} clickFunction={setWorkingHoursRangeFunction} icon="time" />
                </Col>
                <Col xs={12} sm={6} md={3} style={{padding: 0}}>
                    {nokReworked === 0 && (<PrimaryContainer title="NOK" values={props.data.NOK} mode="4" defaultRange={nokReworkedRange} clickFunction={setNokReworkedFunction} newValue={1} icon="remove" />)}
                    {nokReworked === 1 && (<PrimaryContainer title="Reworked" values={props.data.reworked} mode="5" defaultRange={nokReworkedRange} clickFunction={setNokReworkedFunction} newValue={0} icon="wrench" />)}
                </Col>
            </Row>
            <Row style={{marginLeft: "auto", marginRight: "auto"}}>
                <Col xs={12} md={4} style={{paddingLeft: 10, paddingTop: 0}}>
                    <ChartContainer title="Parts checked" mode="1" data={props.data.partCheckedGraph} clickFunction={setPartsCheckedRangeGraphFunction} range={partsCheckedRangeGraph} />
                </Col>
                <Col xs={12} md={4} style={{padding: 0, paddingLeft: 10}}>
                    <ChartContainer title="Working hours" mode="2" data={props.data.workingHoursGraph} clickFunction={setWorkingHoursRangeGraphFunction} range={workingHoursRangeGraph} />
                </Col>
                <Col xs={12} md={4} style={{paddingRight: 10, paddingTop: 0}}>
                    {nokReworkedGraph === 0 && (<ChartContainer title="NOK" textColor="red" mode="3" data={props.data.NOKGraph} clickFunction={setNokReworkedGraphFunction} newValue={1} range={nokReworkedRangeGraph}/>)}
                    {nokReworkedGraph === 1 && (<ChartContainer title="Reworked" textColor="darkorange" mode="3" data={props.data.reworkedGraph} clickFunction={setNokReworkedGraphFunction} newValue={0} range={nokReworkedRangeGraph} />)}
                </Col>

            </Row>
            <Row style={{marginLeft: "auto", marginRight: "auto"}}>
                <Col xs={12} md={8} style={{paddingLeft: 10, paddingTop: 0, paddingRight: 0}}>
                    <MainBottomContainer>
                        <Container>
                            <ContainerInside>
                                <ContentContainer>
                                    <OrdersTableComponent data={props.data.ordersTableRows} setOrderName={props.setOrderNameData} />
                                </ContentContainer>
                            </ContainerInside>
                        </Container>
                    </MainBottomContainer>
                </Col>
                <Col xs={12} md={4} style={{paddingRight: 10, paddingTop: 0}}>
                    <OrdersOverview events={props.data.events} style={{height: 504}}/>
                </Col>
            </Row>
        </div>
    </div>
    )
}

const mapStateToProps = (state) => {
    return {
        data: state.clientPanel.clientPanelDashboard.data
    };
};

function mapDispatchToProps () {
    return dispatch => ({
        loadActiveOrdersData: () => dispatch(loadActiveOrders()),
        setOrderNameData: (value, rowId) => dispatch(setOrderName(value, rowId))
    });
}
export default connect(mapStateToProps, mapDispatchToProps)(BetaPage);
