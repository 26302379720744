import React, {useState} from 'react';
import StyledIcon from "../../../../components/common/StyledIcon";
import styled from "styled-components";

const Input = styled.input`
    &:-moz-ui-invalid {
        box-shadow: none;
    }
    &:focus {
        outline: none;
    }
    &:disabled {
        background-color: rgba(0,0,0,0);
        cursor: auto;
    }
    background-color: rgba(200,200,205,0.3);
    cursor: pointer;
    border: 0 solid;
`;
export default function EditableInput({el, setOrderName}) {
    const [canEdit, setCanEdit] = useState(false);

    return (<>
        <Input type="text" value={el.orderName} disabled={!canEdit} onChange={(ev) => setOrderName(ev.target.value, el._id)}/>
        <StyledIcon
            glyph="pencil"
            title="Edit order name"
            style={{fontSize: 14, color: "black", marginLeft: 12, cursor: "pointer"}}
            onClick={()=>setCanEdit(!canEdit)}
        />
    </>);
}
