import React from "react";
// import { groupAllStyles, groupSingleStyles } from "./AnimationStyles";
import StyledIcon from "../../../../../../components/common/StyledIcon";
import ReportContentContainer from "./ReportContentContainer";
import {
    FirstShadowOfGroupedContainer,
    GroupedContainer,
    SecondShadowOfGroupedContainer,
    ShowAllButtonContainer,
    SingleReportContainer,
    CountOfReportContainer,
    InformationContainer
} from "./Styles";
// import { CSSTransition } from "react-transition-group";
import Balloon from "../Balloon";
import { editSingleGroupedReport } from "../../../../../actions/desktopsActions";
import { connect } from "react-redux";
import styled from "styled-components";
import { userHasPermissionSelector } from "../../../../../../selectors/authSelectors";
import {
    PERMISSION_VIEW_AND_EDIT_OPEN_REPORTS,
    PERMISSION_VIEW_REPORTS
} from "../../../../../../../../common/src/permissions";

const SingleOpenReport = styled.div`
    border: 1px dashed #99d9ea;
    color: #000;
    height: 25px;
    border-radius: 3px;
    margin-right:-3px;
`;
@connect(state => ({
    desktops: state.desktops.desktop,
    canShowAndEditOpenReport: userHasPermissionSelector(state, PERMISSION_VIEW_AND_EDIT_OPEN_REPORTS),
    canOpenReportWithStatusOpen: userHasPermissionSelector(state, PERMISSION_VIEW_REPORTS)
}), dispatch => ({
    editSingleGroupedReport: (companyIndex, orderIndex, reportIndex, status) => dispatch(editSingleGroupedReport(companyIndex, orderIndex, reportIndex, status))
}))

export default class OpenReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = { show: false, marginLeft: 0 };
    }
    showBalloon(value) {
        this.setState({ show: true, marginLeft: value+"px" });
    }

    hideBalloon() {
        this.setState({ show: false });
    }

    getMargin(divBalloonParent) {
        if ((0.5 * window.innerWidth) > divBalloonParent.getBoundingClientRect().x) {
            return divBalloonParent.getBoundingClientRect().width;
        }
        return 0;
    }
    render() {
        if (!this.props.canShowAndEditOpenReport) {
            return null;
        }
        let report = this.props.report;
        let actualCountOfOpen = this.props.actualCountOfOpen;
        let countOfOpen = this.props.countOfOpen;
        let companyIndex = this.props.companyIndex;
        let orderIndex = this.props.orderIndex;
        let reportIndex = this.props.reportIndex;

        this.divBalloonParent = null;

        const manyReports = {
            // transition: 'opacity 0.7s ease, height 0.7s ease',
            // opacity: (report.grouped && actualCountOfOpen === 1 && countOfOpen !== 1) ? 1 : 0,

        };
        const oneReports = {
            // transition: 'opacity 0.7s ease, height 0.7s ease',
            // opacity: (!(report.grouped && countOfOpen !== 1)) ? 1 : 0,
        };

        return (
            <div>
                {(report.grouped && actualCountOfOpen === 1 && countOfOpen !== 1) &&
                    (<div>
                        <GroupedContainer
                            style={report.grouped && actualCountOfOpen === 1 && countOfOpen !== 1 ? { marginRight: "-1px" } : {}}>
                            <ShowAllButtonContainer
                                onClick={_ => this.props.editSingleGroupedReport(companyIndex, orderIndex, reportIndex, "open")}>
                                <StyledIcon glyph="glyphicon-menu-down" title="Show all"
                                    bsClass="glyphicon glyphicon-menu-down"></StyledIcon>
                            </ShowAllButtonContainer>
                            <SingleReportContainer key={report._id} style={{ boxShadow: "none" }}>
                                <SingleOpenReport>
                                    <InformationContainer
                                        onClick={() => window.open("reports/" + report._id, "_blank")}>
                                        <ReportContentContainer report={report}></ReportContentContainer>
                                    </InformationContainer>
                                    <CountOfReportContainer>
                                        {countOfOpen}
                                    </CountOfReportContainer>
                                </SingleOpenReport>
                            </SingleReportContainer>
                            <FirstShadowOfGroupedContainer
                                style={countOfOpen < 2 ? { display: "none" } : {}}></FirstShadowOfGroupedContainer>
                            <SecondShadowOfGroupedContainer
                                style={countOfOpen < 3 ? { display: "none" } : {}}></SecondShadowOfGroupedContainer>
                        </GroupedContainer>
                    </div>)
                }
                <ShowAllButtonContainer
                    style={(!report.grouped && countOfOpen !== 1 && actualCountOfOpen === 1) ? {} : { display: "none" }}
                    onClick={_ => this.props.editSingleGroupedReport(companyIndex, orderIndex, reportIndex, "open")}>
                    <StyledIcon glyph="glyphicon-menu-up" title="Hide all"
                        bsClass="glyphicon glyphicon-menu-up"></StyledIcon>
                </ShowAllButtonContainer>
                {!(report.grouped && countOfOpen !== 1) &&
                    (<div onMouseOver={() => this.showBalloon(this.getMargin(this.divBalloonParent))}
                        onMouseLeave={() => this.hideBalloon()}
                        ref={(divBalloonParent) => {
                            this.divBalloonParent = divBalloonParent;
                        }}>
                        {this.state.show && <Balloon data={report} marginLeft={this.state.marginLeft} companyIndex={companyIndex} orderIndex={orderIndex} />}
                        <SingleReportContainer
                            key={report._id}
                            onMouseOver={() => this.showBalloon(this.getMargin(this.divBalloonParent))}>
                            <SingleOpenReport>
                                <InformationContainer
                                    onClick={() => {
                                        this.props.canOpenReportWithStatusOpen
                                            ? window.open("reports/" + report._id, "_blank")
                                            : "";
                                    }}
                                >
                                    <ReportContentContainer report={report}></ReportContentContainer>
                                </InformationContainer>
                            </SingleOpenReport>
                        </SingleReportContainer>
                    </div>)
                }
            </div>
        );
    }
}