import {parseIntWithZeroFallback} from "../../utils";

/**
 * Computes subtotals for numeric columns of the productQA table.
 */
export default function subtotalsSelector(rows) {
    let init = {
        totalChecked: 0,
        ok: 0,
        nok: 0,
        reworked: 0,
        totalOK: 0,
        letterColumnsContent: [],
        smallLetterColumnsContent: []
    };
    return rows.reduce((prev, row) => {
        return {
            ok: prev.ok + parseIntWithZeroFallback(row.ok),
            nok: prev.nok + parseIntWithZeroFallback(row.nok),
            reworked: prev.reworked + parseIntWithZeroFallback(row.reworked),
            totalOK: prev.totalOK + parseIntWithZeroFallback(row.totalOK),
            totalChecked: prev.totalChecked + parseIntWithZeroFallback(row.totalChecked),
            letterColumnsContent: row.letterColumnsContent.map((c, i) => {
                if (!prev.letterColumnsContent[i]) {
                    return parseIntWithZeroFallback(c);
                } else {
                    return prev.letterColumnsContent[i] + parseIntWithZeroFallback(c);
                }
            }),
            smallLetterColumnsContent: row.smallLetterColumnsContent.map((c, i) => {
                if (!prev.smallLetterColumnsContent[i]) {
                    return parseIntWithZeroFallback(c);
                } else {
                    return prev.smallLetterColumnsContent[i] + parseIntWithZeroFallback(c);
                }
            })
        };
    }, init);
}
