import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { STATE_LOADED } from "../../actions/customerPanelExternalsActions";
import { TableBody } from "./Styles";

const Td = styled.td`
    vertical-align: middle !important;
`;

@connect(({ clientPanel }) => clientPanel.clientPanelExternals)
export default class DetailedPartReport extends React.Component {
    render() {
        let header = "";
        let content = "";
        let summaryHeader = "";
        let summary = "";
        if (this.props.state === STATE_LOADED) {
            let detailedPartReport = this.props.externals.detailedPartReport;
            header = (
                <tr>{detailedPartReport.header && detailedPartReport.header.map(i => <th key={i.key} style={{width: i.width}}>{i.val}</th>)}</tr>
            );
            if (detailedPartReport.rows) {
                content = detailedPartReport.rows.map((r, index) => {
                    let row = (
                        <tr key={r.partNumber.val + index}>
                            {r.partNumber.rowSpan !== 0 && <Td rowSpan={r.partNumber.rowSpan}>{r.partNumber.val}</Td>}
                            {r.ec.map(r => {
                                let res = r.rowSpan !== 0 ? <Td rowSpan={r.rowSpan}>{r.val}</Td> : null;
                                return res;
                            })}
                            <Td>{r.totalChecked}</Td>
                            <Td>{r.fromThisOk}</Td>
                            <Td>{r.reworked}</Td>
                            {r.reworkDetails.map(d => {
                                return <td>{d.val}</td>;
                            })}
                            <Td>{r.nok}</Td>
                            {r.nokDetails.map(d => {
                                return <td>{d.val}</td>;
                            })}
                            <Td>{r.totalOk}</Td>
                        </tr>
                    );
                    return row;
                });
            }
            summaryHeader = (
                <tr>
                    {detailedPartReport.summaryHeader &&
                        detailedPartReport.summaryHeader.map(i => <td key={i.key}>{i.val}</td>)}
                </tr>
            );
            summary = (
                <tr>
                    {detailedPartReport.summary && detailedPartReport.summary.map((i, idx) => <td key={idx}>{i}</td>)}
                </tr>
            );
        }
        return (
            <TableBody responsive={true}>
                <tbody>
                    {header}
                    {content}
                    {summaryHeader}
                    {summary}
                </tbody>
            </TableBody>
        );
    }
}
