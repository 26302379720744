import React, {useEffect} from 'react';
import MatSelect from "../../../../components/common/Mat/MatSelect";
import {periodSelectOptions} from "../filtersModel";
import styled from "styled-components";
import MatRadio from "../../../../components/common/Mat/MatRadio";
import MatDateRangeSelector from "../../../../components/common/Mat/MatDateField/MatDateRangeSelector";
import {connect} from "react-redux";
import {getOrder, setPeriod, setDateRange, setPeriodName, generateCustomerReport} from "../actions/detailsPageActions";
import moment from "moment/moment";

const PeriodRadio = styled(MatRadio)`
    width: 75px; 
    display: inline-block;
`;

function Range({match, selectedFilter, period, periodChanged, range, dateRangeChanged, periodName, periodNameChanged} ) {
    const periodChangedRange = ev => {
        periodNameChanged(ev.value);
        dateRangeChanged(ev ? { start: new Date(ev.dateStart), end: new Date(ev.dateEnd) } : null);
        periodChanged(true);
    };
    const dateRangeChangedFun = ev => {
        let endDate = moment(ev.end)
            .endOf("day")
            .toString();
        dateRangeChanged(ev ? { start: ev.start, end: new Date(endDate) } : null);
    };

    const periodChangedAndClearRange = () => {
        periodChanged(false);
        dateRangeChanged({ start: null, end: null });
        periodNameChanged(null);
    }

    return (
        <div>
            <div style={{textAlign: "center"}}>
                <PeriodRadio
                    checked={period}
                    label="period"
                    name="filterradio"
                    onChange={()=>periodChanged(true)}
                    value={period}
                />
                <div style={{display: "inline-block", marginRight: 2}}>
                    <MatSelect
                        value={periodName}
                        onChange={periodChangedRange}
                        options={periodSelectOptions}
                        // onClick={() => selectedFilterChanged(SELECTED_FILTER_PERIOD)}
                    />
                </div>
            </div>
            <MatDateRangeSelector
                style={{display: "block", textAlign: "center"}}
                expandWhenDisabled
                disabled={period}
                value={range}
                onChange={dateRangeChangedFun}
                onClick={() => periodChangedAndClearRange(false)}
            />
        </div>
    )
}

const mapStateToProps = ({clientPanel}) => {
    return {
        period: clientPanel.detailsPageReducer.period,
        range: clientPanel.detailsPageReducer.range,
        periodName: clientPanel.detailsPageReducer.periodName
    }
}

function mapDispatchToProps() {
    return dispatch => ({
        periodChanged: val => dispatch(setPeriod(val)),
        dateRangeChanged: val => dispatch(setDateRange(val)),
        periodNameChanged: val => dispatch(setPeriodName(val))
    })
}
export default connect(mapStateToProps, mapDispatchToProps)(Range);

