import {
    PROBLEM_DESCRIPTION_CONTENT_CHANGED,
    EXTRA_FIELD_ADDED,
    EXTRA_FIELD_CHANGED,
    EXTRA_FIELD_REMOVED,
    PROBLEM_DESCRIPTION_CHANGED,
    IS_LOADING_CHANGED,
    SMALL_PROBLEM_DESCRIPTION_CHANGED,
    SMALL_EXTRA_FIELD_ADDED,
    SMALL_EXTRA_FIELD_CHANGED,
    SMALL_EXTRA_FIELD_REMOVED,
    REWORK_DESCRIPTION_CONTENT_CHANGED
} from "../actions/problemDescriptionActions";
import { REPORT_RESET, REPORT_DATA_CHANGED } from "../actions/reportActions";
import problemDescriptionHOR from "../higherOrder/reducers/problemDescriptionHOR";

export default problemDescriptionHOR({
    PROBLEM_DESCRIPTION_CONTENT_CHANGED,
    REWORK_DESCRIPTION_CONTENT_CHANGED,
    SMALL_PROBLEM_DESCRIPTION_CHANGED,
    SMALL_EXTRA_FIELD_ADDED,
    SMALL_EXTRA_FIELD_CHANGED,
    SMALL_EXTRA_FIELD_REMOVED,
    EXTRA_FIELD_ADDED,
    EXTRA_FIELD_CHANGED,
    EXTRA_FIELD_REMOVED,
    RESET: REPORT_RESET,
    DATA_CHANGED: REPORT_DATA_CHANGED,
    PROBLEM_DESCRIPTION_CHANGED,
    IS_LOADING_CHANGED
});
