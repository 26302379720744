import { toast } from 'react-toastify';
import { apiFetch } from '../../actions/apiActions';
import {
  clockTimeToMinutes,
  makeActionCreator,
  minutesToTimeString,
  transformGroupedScenarioToWeekStandard
} from '../../utils';
import { push } from 'react-router-redux';
import {
  // changeHolidayHoursAsSunday,
  timelineHoursCategories,
  timelineHoursCategories2,
  timelineHoursRangeUpdate
} from "./hoursRangeActions";
export const SCENARIO_IS_LOADING = "SCENARIO_IS_LOADING";
export const SCENARIO_DATA = "SCENARIO_DATA";
export const SCENARIO_LIST = "SCENARIO_LIST";
export const SCENARIO_START_OF_WEEK_HOUR = "SCENARIO_START_OF_WEEK_HOUR";
export const SCENARIO_TITLE = "SCENARIO_TITLE";
export const  SCENARIO_CHANGE_SPLIT_REPORT = "SCENARIO_CHANGE_SPLIT_REPORT";

export const SCENARIO_WITHOUT_RANGE_UPDATE="SCENARIO_WITHOUT_RANGE_UPDATE";
export const SCENARIO_WITHOUT_RANGE_UPDATE_DATA="SCENARIO_WITHOUT_RANGE_UPDATE_DATA";
export const SCENARIO_WITHOUT_RANGE_ADD="SCENARIO_WITHOUT_RANGE_ADD";
export const SCENARIO_WITHOUT_RANGE_DEL="SCENARIO_WITHOUT_RANGE_DEL";
export const SCENARIO_SHORT_DESCRIPTION="SCENARIO_SHORT_DESCRIPTION";
export const SCENARIO_DESCRIPTION="SCENARIO_DESCRIPTION";
export const changeSplitReportValue = makeActionCreator(SCENARIO_CHANGE_SPLIT_REPORT, "payload");
export const changeScenarioWithoutRange = makeActionCreator(SCENARIO_WITHOUT_RANGE_UPDATE, "payload", "index");
export const changeScenarioWithoutRangeData = makeActionCreator(SCENARIO_WITHOUT_RANGE_UPDATE_DATA, "payload");
export const changeScenarioWithoutRangeAdd = makeActionCreator(SCENARIO_WITHOUT_RANGE_ADD, "payload");
export const changeScenarioWithoutRangeDel = makeActionCreator(SCENARIO_WITHOUT_RANGE_DEL, "index");

const setScenarioLoading = makeActionCreator(SCENARIO_IS_LOADING, "payload");
const setScenariosList = makeActionCreator(SCENARIO_LIST, "payload");
export const setStartOfWeek = makeActionCreator(SCENARIO_START_OF_WEEK_HOUR, "payload");
export const setTitle = makeActionCreator(SCENARIO_TITLE, "payload");
export const setShortScenarioDescription = makeActionCreator(SCENARIO_SHORT_DESCRIPTION, "payload");
export const setScenarioDescription = makeActionCreator(SCENARIO_DESCRIPTION, "payload");
export function addScenarioTools (modalName) {
  return async (dispatch, getState) => {
    try {
      let resp = await dispatch(
        apiFetch("/api/tools/scenario/new", {
          method: "POST",
          body: JSON.stringify({
            scenarioTitle: modalName,
          })
        })
      );
      let data = await resp.json();
      if (resp.status !== 200) {
        throw new Error(data.message);
      }
      const tmp = getState().toolsReducer.allTimeList
      toast("Created new scenario template!", { type: "success" });
      dispatch(getAllScenarios());
      dispatch(push(`/dashboard/scenario/${data._id}`));
    } catch (e) {
      toast(e.message, { type: "error" });
    }
  }
}

export function getAllScenarios() {
  return async (dispatch) => {
    try {
      let resp = await dispatch(
        apiFetch("/api/tools/scenario/all", {
          method: "GET"
        })
      );
      let data = await resp.json();
      if (resp.status !== 200) {
        throw new Error(data.message);
      }
      dispatch(setScenariosList(data.scenarioData));
    } catch (e) {
      toast(e.message, {type: 'error'});
    }
  }
}

export function getScenarioData(id) {
  return async (dispatch) => {
    dispatch(setScenarioLoading(true));
    dispatch(setStartOfWeek(null));
    dispatch(setTitle(""));
    dispatch(setShortScenarioDescription(""));
    dispatch(setScenarioDescription(""));
    dispatch(timelineHoursRangeUpdate([]));
    dispatch(timelineHoursCategories([]));
    dispatch(timelineHoursCategories2([]));
    dispatch(setStartOfWeek("--:--"));
    dispatch(changeSplitReportValue(null))
    dispatch(changeScenarioWithoutRangeData([]));
    try {
      let resp = await dispatch(
        apiFetch(`/api/tools/scenario/${id}`, {
          method: "GET"
        })
      );
      let data = await resp.json();
      let names = [];
      let names2 = [];
      data.data.week.forEach((el, index) => {
          el.ranges.map(range => {
            names.push(range.name)
          })
      })

      let preparedData = [];
      let preparedData2 = [];
      [...new Set(names)].forEach(name => {
        preparedData.push({category: name, ranges: []})
      });

      [...new Set(names2)].forEach(name => {
        preparedData2.push({category: name, ranges: []})
      });

      data.data.week.forEach((el, index) => {
          el.ranges.forEach(range => {
            preparedData[preparedData.findIndex(i => i.category === range.name)].ranges
              .push({
                index: index,
                initialIntervalFrom: range.initialIntervalFrom,
                initialIntervalTo: range.initialIntervalTo
              })
          })
      })

      let edit = (arr) => {
        let newArray = new Array(null, null, null, null, null, null, null, null);
        arr.forEach(el => newArray[el] = true);
        return newArray
      }
      preparedData.forEach(cat => {
        cat.grouped = [];
        cat.ranges.forEach(range => {
          if(cat.grouped.length === 0) {
            cat.grouped.push({initialIntervalFrom: range.initialIntervalFrom, initialIntervalTo: range.initialIntervalTo, checkedDays: [range.index]})
          } else {
            let indexByProperty = cat.grouped.findIndex(i => i.initialIntervalFrom === range.initialIntervalFrom && i.initialIntervalTo === range.initialIntervalTo)
            if(indexByProperty !== -1) {
              cat.grouped[indexByProperty].checkedDays.push(range.index);
            } else {
              cat.grouped.push({initialIntervalFrom: range.initialIntervalFrom, initialIntervalTo: range.initialIntervalTo, checkedDays: [range.index]})
            }
          }
        })
        cat.grouped.forEach(group => group.checkedDays = edit(group.checkedDays))
      })

      dispatch(timelineHoursCategories(preparedData));
      dispatch(timelineHoursCategories2(preparedData2));

      dispatch(setStartOfWeek(minutesToTimeString(data.data.startOfWeekInMinutes)));
      dispatch(setTitle(data.data.title));
      dispatch(setShortScenarioDescription(data.data.shortDescription))
      dispatch(setScenarioDescription(data.data.description))
      dispatch(timelineHoursRangeUpdate(data.data.week));
      // dispatch(changeHolidayHoursAsSunday(data.data.holidayHoursAsSunday));
      dispatch(changeSplitReportValue(data.data.splitReport))
      dispatch(changeScenarioWithoutRangeData(data.data.scenariosWithoutRange));
      if (resp.status !== 200) {
        throw new Error(data.message);
      }
      dispatch(setScenarioLoading(false));
    }
    catch (e) {
      toast(e.message, {type: 'error'});
    }
  }
}
export function saveScenario(id, modalVersionMessage) {
  return async (dispatch, getState) => {
    try {
      dispatch(setScenarioLoading(true));
      const scenariosData = getState().scenariosReducer;
      const hoursRangeData = getState().hoursRangeReducer;
      let resp = await dispatch(
        apiFetch(`/api/tools/scenario/${id}`, {
          method: "PATCH",
          body: JSON.stringify({
            startOfWeekHour: clockTimeToMinutes(scenariosData.startOfWeekHour),
            title: scenariosData.title,
            shortDescription: scenariosData.shortDescription,
            description: scenariosData.description,
            week: transformGroupedScenarioToWeekStandard(hoursRangeData.categories, hoursRangeData.categories2), // hoursRangeData.week,
            // holidayHoursAsSunday: hoursRangeData.holidayHoursAsSunday,
            splitReport: scenariosData.splitReport,
            scenariosWithoutRange: scenariosData.scenariosWithoutRange,
            reasonOfChange: modalVersionMessage
          })
        })
      );
      let data = await resp.json();
      if (resp.status !== 200) {
        throw new Error(data.message);
      }
      dispatch(getAllScenarios());
      dispatch(setScenarioLoading(false));
    } catch (e) {
      toast(e.message, {type: 'error'});
    }
  }
}

export function changeSplitReport(value) {
  return async (dispatch) => {
    dispatch(changeSplitReportValue(value));
  }
}
