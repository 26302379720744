import {
    ROW_ADDED,
    ROW_REMOVED,
    NAME_CHANGED,
    FROM_TIME_CHANGED,
    TO_TIME_CHANGED,
    BRAKE_CHANGED,
    DATE_OF_BIRTH_CHANGED,
    ARRIVAL_TIME_CHANGED,
    EMPLOYEE_ID_CHANGED,
    EMPLOYEE_DATA_CHANGED,
    EMPLOYEE_SEARCH_CACHE_KEY_CHANGED,
    EMPLOYEE_CONFLICT_STATUS_CHANGED,
    CS_NOT_AVAILABLE,
    EMPLOYEE_CONFLICT_REPORT_ID_CHANGED,
    EMPLOYEE_CONFLICT_MESSAGE_CHANGED
} from "../actions/employeeTimeTableActions";
import { REPORT_RESET, REPORT_DATA_CHANGED } from "../actions/reportActions";
import { getCurrentStartShift, updateRow } from "../../utils";

const makeRow = ({ fromTime, toTime } = {}) => ({
    employeeId: null,
    employeeData: null,
    arrivalTime: 0,
    fromTime: fromTime || getCurrentStartShift(),
    toTime: toTime || "--:00",
    brake: 0,
    conflictStatus: CS_NOT_AVAILABLE,
    conflictReportId: null,
    conflictMessage: null
});

export const makeInitialState = _ => ({
    rows: [makeRow()],
    employeeSearchCache: {}
});
export default function employeeTimeTable(state = makeInitialState(), action) {
    switch (action.type) {
        case REPORT_RESET:
            return makeInitialState();
        case REPORT_DATA_CHANGED:
            return {
                ...state,
                ...action.payload.employeeTimeTable
            };
        case ROW_ADDED: {
            let fromTime = null,
                toTime = null;
            if (state.rows.length > 0) {
                fromTime = state.rows[state.rows.length - 1].fromTime;
                toTime = state.rows[state.rows.length - 1].toTime;
            }
            return {
                ...state,
                rows: [
                    ...state.rows,
                    makeRow({
                        fromTime,
                        toTime
                    })
                ]
            };
        }
        case ROW_REMOVED:
            return {
                ...state,
                rows: state.rows.filter((_, i) => i !== action.rowIndex)
            };
        case NAME_CHANGED:
            if (!/^[A-Za-z ąćęłńóśźżĄĆĘŁŃÓŚŻŹäöüÄÖÜẞß]+$/.test(action.payload) && action.payload.length !== 0) {
                return state;
            }
            return {
                ...state,
                rows: state.rows.map((r, i) => {
                    if (i !== action.rowIndex) return r;
                    return {
                        ...r,
                        employeeData: {
                            ...r.employeeData,
                            name: action.payload
                        }
                    };
                })
            };
        case DATE_OF_BIRTH_CHANGED:
            if (action.payload.length > 6) {
                return state;
            }
            return {
                ...state,
                rows: state.rows.map((r, i) => {
                    if (i !== action.rowIndex) return r;
                    return {
                        ...r,
                        employeeData: {
                            ...r.employeeData,
                            dateOfBirth: action.payload
                        }
                    };
                })
            };
        case EMPLOYEE_DATA_CHANGED:
            return {
                ...state,
                rows: updateRow(state.rows, "employeeData", action)
            };
        case EMPLOYEE_ID_CHANGED:
            return {
                ...state,
                rows: updateRow(state.rows, "employeeId", action)
            };
        case FROM_TIME_CHANGED:
            return {
                ...state,
                rows: updateRow(state.rows, "fromTime", action)
            };
        case TO_TIME_CHANGED:
            return {
                ...state,
                rows: updateRow(state.rows, "toTime", action)
            };
        case BRAKE_CHANGED:
            return {
                ...state,
                rows: updateRow(state.rows, "brake", action)
            };
        case ARRIVAL_TIME_CHANGED:
            return {
                ...state,
                rows: updateRow(state.rows, "arrivalTime", action)
            };
        case EMPLOYEE_SEARCH_CACHE_KEY_CHANGED:
            return {
                ...state,
                employeeSearchCache: {
                    ...state.employeeSearchCache,
                    [action.key]: action.payload
                }
            };
        case EMPLOYEE_CONFLICT_STATUS_CHANGED:
            return {
                ...state,
                rows: updateRow(state.rows, "conflictStatus", action)
            };
        case EMPLOYEE_CONFLICT_REPORT_ID_CHANGED:
            return {
                ...state,
                rows: updateRow(state.rows, "conflictReportId", action)
            };
        case EMPLOYEE_CONFLICT_MESSAGE_CHANGED:
            return {
                ...state,
                rows: updateRow(state.rows, "conflictMessage", action)
            };
        default:
            return state;
    }
}
