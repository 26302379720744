import { combineReducers } from "redux";
import clientPanels from "./customerPanels";
import clientPanelExternals from "../CustomerPanelPage/reducers/customerPanelExternals";
import accounts from "../AccountsPage/reducers/accounts";
import customerOrdersList from "../CustomerOrdersListPage/reducers/customerOrdersList";
import clientPanelDashboard from "../Dashboard/BetaPage/reducers/dashboardReducer";
import detailsPageReducer from "../Dashboard/DetailsPage/reducers/detailsPageReducer";
export default combineReducers({
    clientPanels,
    clientPanelExternals,
    accounts,
    customerOrdersList,
    clientPanelDashboard,
    detailsPageReducer
});
