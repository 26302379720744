import React from "react";
import { connect } from "react-redux";
import { FormGroup, ControlLabel, FormControl, InputGroup } from "react-bootstrap";
import totalsSelector from "../selectors/totalsSelector";
import styled from "styled-components";
import { parseFloatWithZeroFallback } from "../../utils";

export const ShortInputGroup = styled(InputGroup)`
    width: 180px;
`;
@connect(({ externals }) => ({
    ...totalsSelector({ externals }),
    ...externals.external
}))
export default class CurrentReportSortRate extends React.Component {
    render() {
        let p = this.props;
        let calculateWorkingHours = (p) => {
            let sum = 0;
            p.hoursTypeKeys.forEach(key => sum += parseFloatWithZeroFallback(p[key]));
            return sum;
        }
        let workingHours = p.flatRate
            ? p.workingHours
            : calculateWorkingHours(p);

        return (
            <FormGroup className="form-inline">
                <ControlLabel>Sort rate</ControlLabel>
                <ShortInputGroup>
                    <FormControl disabled value={(p.totalChecked / workingHours).toFixed(2)} />
                    <InputGroup.Addon>pcs / h</InputGroup.Addon>
                </ShortInputGroup>
            </FormGroup>
        );
    }
}
