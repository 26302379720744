import {
    PROBLEM_DESCRIPTION_CONTENT_CHANGED,
    REWORK_DESCRIPTION_CONTENT_CHANGED,
    EXTRA_FIELD_ADDED,
    EXTRA_FIELD_CHANGED,
    EXTRA_FIELD_REMOVED,
    SMALL_EXTRA_FIELD_ADDED,
    SMALL_PROBLEM_DESCRIPTION_CONTENT_CHANGED,
    SMALL_EXTRA_FIELD_CHANGED,
    SMALL_EXTRA_FIELD_REMOVED
} from "../actions/problemDescriptionActions";
import { ORDER_RESET, ORDER_DATA_CHANGED } from "../actions/orderActions";
import problemDescriptionHOR from "../../ReportsPage/higherOrder/reducers/problemDescriptionHOR";

export default problemDescriptionHOR({
    PROBLEM_DESCRIPTION_CONTENT_CHANGED,
    REWORK_DESCRIPTION_CONTENT_CHANGED,
    EXTRA_FIELD_ADDED,
    EXTRA_FIELD_CHANGED,
    EXTRA_FIELD_REMOVED,
    SMALL_PROBLEM_DESCRIPTION_CONTENT_CHANGED,
    SMALL_EXTRA_FIELD_ADDED,
    SMALL_EXTRA_FIELD_CHANGED,
    SMALL_EXTRA_FIELD_REMOVED,
    RESET: ORDER_RESET,
    DATA_CHANGED: ORDER_DATA_CHANGED
});
