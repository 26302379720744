import styled, {css} from "styled-components";

export const MainContainer = styled.div`
    width: 100%;
    height: 172px; 
    padding-top: 24px; 
    padding-left: 24px;
    ${props =>
            props.height &&
            css`
                height: ${props.height} !important;
            `
    }
    ${props =>
            props.paddingLeft &&
            css`
                padding-left: ${props.paddingLeft} !important;
            `
    }
`;

export const Container = styled.div`
    margin-bottom: 12px;
    opacity: 1;
    background: transparent;
    color: rgb(52, 71, 103);
    box-shadow: none;
`;

export const ContainerInside = styled.div`
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    display: flex;
    flex-direction: column;
    position: relative;
    min-width: 0;
    overflow-wrap: break-word;
    background-color: rgb(255, 255, 255);
    background-clip: border-box;
    border: 0 solid rgba(0, 0, 0, 0.125);
    border-radius: 12px;
    box-shadow: rgb(0 0 0 / 10%) 0rem 0.25rem 0.375rem -0.0625rem, rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem;
    overflow: visible;
    cursor: pointer;
    ${props =>
            props.height &&
            css`
                 min-height: ${props.height} !important;
            `
    }
`;

export const Container2 = styled.div`
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding-top: 8px;
    padding-left: 16px;
    padding-right: 16px;
    opacity: 1;
    background: transparent;
    color: rgb(52, 71, 103);
    box-shadow: none;
    ${p => p.clickFunction && css`cursor: pointer;`}
`;

export const IconContainer = styled.div`
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 64px;
    height: 64px;
    margin-top: -24px;
    opacity: 1;
    ${p =>
    p.mode === "1" &&
    css`
        background: linear-gradient(
        195deg
        , rgb(66, 66, 74), rgb(25, 25, 25));
    `}
    ${p =>
    p.mode === "2" &&
    css`
        background: linear-gradient(
        195deg
        , rgb(102, 187, 106), rgb(67, 160, 71));
    `}
    ${p =>
    p.mode === "3" &&
    css`
        background: linear-gradient(
                195deg
                , rgb(73, 163, 241), rgb(26, 115, 232));
    `}
    ${p =>
    p.mode === "4" &&
    css`
        background: linear-gradient(
                195deg
                , rgb(236, 64, 122), rgb(216, 27, 96));
    `}
    ${p =>
    p.mode === "5" &&
    css`
            background: linear-gradient(195deg, orange 0px, darkorange);
    `}
    color: rgb(255, 255, 255);
    border-radius: 12px;
    ${p =>
    p.mode === "1" &&
    css`
                box-shadow: rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(64 64 64 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem;
    `}
    ${p =>
    p.mode === "2" &&
    css`
                box-shadow: rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(0 187 212 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem;
    `}
    ${p =>
    p.mode === "3" &&
    css`
                box-shadow: rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(76 175 79 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem;
    `}
    ${p =>
    p.mode === "4" &&
    css`
                box-shadow: rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(233 30 98 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem;
    `}
    ${p =>
    p.mode === "5" &&
    css`
                box-shadow: rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(233 30 98 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem;
    `}
    

`;

export const IconSpan = styled.span`
    user-select: none;
    width: 20px;
    height: 22px;
    overflow: hidden;
    display: inline-block;
    text-align: center;
    flex-shrink: 0;
    font-size: 20px;
`;

export const MainValueContainer = styled.div`
    text-align: right;
    line-height: 1.25;
    opacity: 1;
    background: transparent;
    color: rgb(121, 129, 153);//rgb(190, 190, 190);
    box-shadow: none;
`;


export const MainValueContainerSeparator = styled.span`
    margin: 0px;
    font-family: Roboto, Helvetica, Arial, sans-serif !important;
    font-size: 1.25rem;
    line-height: 1.5;
    letter-spacing: 0.02857em;
    opacity: 1;
    text-transform: none;
    vertical-align: unset;
    text-decoration: none;
    color: rgb(121, 129, 153);//rgb(190, 190, 190);
    font-weight: 200;
`;

export const MainValueContainerValue = styled.span`
    display: block;
    margin: 0px;
    font-size: 24px;
    line-height: 1.375;
    font-family: Roboto, Helvetica, Arial, sans-serif !important;
    font-weight: 700;
    letter-spacing: 0.6px;
    opacity: 1;
    text-transform: none;
    vertical-align: unset;
    text-decoration: none;
    color: rgb(52, 71, 103);
`;
