import React from "react";
import {Button, ControlLabel, FormControl, FormGroup, Modal} from "react-bootstrap";
import MatGrid from "../components/common/Mat/MatGrid";
import MatInput from "../components/common/Mat/MatInput";

export default function CreateModalContainer({showNameModal, closeModal, onSave, name, setName, title, icon}) {

    let saveAndClose = () => {
        onSave();
        setName("");
        closeModal(true);
    }

    return (
        <>
            <Modal show={showNameModal} onHide={()=> closeModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Insert name of new {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <br />
                    <MatGrid.Row>
                        <MatGrid.Col width="100%">
                            <MatInput
                                type="text"
                                icon={icon}
                                label={`${title} name`}
                                value={name}
                                onChange={(ev) => setName(ev.target.value)}
                            />
                        </MatGrid.Col>
                    </MatGrid.Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="pull-left" bsStyle="danger" onClick={()=> closeModal(false)}>Close</Button>
                    <Button className="pull-right"
                            bsStyle="primary"
                            onClick={()=> saveAndClose()}>Save</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
