import React from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router";
import styled, { css } from "styled-components";
import {
    PERMISSION_CLIENT_PANEL,
    PERMISSION_CREATE_TEMPLATES,
    PERMISSION_EDIT_EMPLOYEES,
    PERMISSION_REPORTER,
    PERMISSION_VIEW_EMPLOYEES,
    PERMISSION_VIEW_USERS
} from "../../../../common/src/permissions";
import { authCheckRequested, STATE_LOGGED_IN } from "../../actions/authActions";
import { loadNotifications } from "../../actions/notificationsActions";
import CompaniesListPage from "../../CompaniesListPage/components/CompaniesListPage";
import LoadableCompaniesPage from "../../CompaniesPage/components/LoadableCompaniesPage";
import ContactPersonModal from "../../ContactPersonModal/components/ContactPersonModal";
import CustomerPage from "../../CustomerPanel/components/CustomerPage";
import LoadableMainsPage from "../../DesktopsPage/components/LoadableMainsPage";
import ExternalsModal from "../../ExternalsModal/components/ExternalsModal";
import EditEmployeePage from "../../HRPage/components/EditEmployeePage";
import LoadableEmployeesListPage from "../../HRPage/components/LoadableEmployeesListPage";
import EditTemplatePage from "../../HRPage/components/templates/EditTemplatePage";
import TemplatesListPage from "../../HRPage/components/templates/TemplatesListPage";
import InvitationModal from "../../InvitationModal/components/InvitationModal";
import LoadableOfficePage from "../../OfficePage/components/LoadableOfficePage";
import LoadableOrdersListPage from "../../OrdersListPage/components/LoadableOrdersListPage";
import LoadableOrdersPage from "../../OrdersPage/components/LoadableOrdersPage";
import ReporterDesktopPage from "../../ReporterDesktopPage/ReporterDesktopPage";
import LoadableReportsListPage from "../../ReportsListPage/components/LoadableReportsListPage";
import LoadableReportsPage from "../../ReportsPage/components/LoadableReportsPage";
import { userHasPermissionSelector } from "../../selectors/authSelectors";
import LoadableUsersPage from "../../UsersPage/components/LoadableUsersPage";
import DashboardFooter from "./DashboardFooter";
import DashboardNavBar from "./DashboardNavBar";
import DashboardSidebar from "./DashboardSidebar";
import ChangePassword from './ChangePassword';
import LoadableToolsPage from '../../Tools/Calendar/LoadableToolsPage';
import ToolsCalendar from "../../Tools/Calendar/ToolsCalendar";
import ToolsScenarioPage from "../../Tools/Scenario/ToolsScenarioPage";
import ToolsCalendarPage from "../../Tools/Calendar/ToolsCalendarPage";
import DetailsPage from "../../CustomerPanel/Dashboard/DetailsPage/DetailsPage";
import BetaPage from "../../CustomerPanel/Dashboard/BetaPage/BetaPage";

const DashboardBody = styled.div`
    min-height: 80vh;
`;

const SidebarAndContentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    .container {
        overflow-x: visible;
    }
    ${props =>
        props.newBackground &&
        css`
            background: #f1f2f7;
        `}
`;

const newDesignUrls = [
    "/dashboard/orders/list",
    "/dashboard/reports/list",
    "/dashboard/companies/list",
    "/dashboard/companies"
]


@connect(
    state => ({
        isReporter: userHasPermissionSelector(state, PERMISSION_REPORTER),
        userTabEnabled: userHasPermissionSelector(state, PERMISSION_VIEW_USERS),
        enableClientPanelPage: userHasPermissionSelector(state, PERMISSION_CLIENT_PANEL),
        hrTabEnabled: userHasPermissionSelector(state, PERMISSION_VIEW_EMPLOYEES),
        hideDashboard:
            state.auth.authState !== STATE_LOGGED_IN || userHasPermissionSelector(state, PERMISSION_CLIENT_PANEL),
        editEmployeeEnabled: userHasPermissionSelector(state, PERMISSION_EDIT_EMPLOYEES),
        editTemplateEnabled: userHasPermissionSelector(state, PERMISSION_CREATE_TEMPLATES),
        pathName: state.router.location.pathname
    }),
    dispatch => ({
        authCheckRequested: _ => dispatch(authCheckRequested()),
        loadNotifications: _ => dispatch(loadNotifications())
    })
)
export default class DashboardPage extends React.Component {
    componentDidMount() {
        this.props.authCheckRequested();
        this.props.loadNotifications();
    }
    render() {
        let showDashboard =
            this.props.pathName.indexOf("/dashboard/simulations/customer/") === -1 && !this.props.hideDashboard;
        let isNewDesign = newDesignUrls.some(d => this.props.pathName.indexOf(d) !== -1)
        return (
            <div>
                {showDashboard && (
                    <div>
                        <DashboardNavBar />

                        <SidebarAndContentWrapper newBackground={isNewDesign}>
                            {!this.props.isReporter && <DashboardSidebar />}

                            <div className={isNewDesign ? "container-full-width" : "container"}>
                                <DashboardBody>
                                    <Switch>
                                        <Route
                                            component={LoadableReportsPage}
                                            path={`${this.props.match.url}/reports`}
                                            exact
                                        />
                                        <Route
                                            component={LoadableReportsListPage}
                                            path={`${this.props.match.url}/reports/list`}
                                            exact
                                        />
                                        <Route
                                            component={LoadableReportsPage}
                                            path={`${this.props.match.url}/reports/:id`}
                                            exact
                                        />
                                        <Route
                                            component={LoadableReportsPage}
                                            path={`${this.props.match.url}/reports/:id/version/:versionId`}
                                            exact
                                        />
                                          <Route
                                            component={CompaniesListPage}
                                            path={`${this.props.match.url}/companies/list`}
                                        />
                                        <Route
                                            component={LoadableCompaniesPage}
                                            path={`${this.props.match.url}/companies`}
                                        />

                                        <Route
                                            component={LoadableOrdersListPage}
                                            path={`${this.props.match.url}/orders/list`}
                                            exact
                                        />
                                        <Route
                                            component={LoadableOrdersPage}
                                            path={`${this.props.match.url}/orders`}
                                            exact
                                        />
                                        <Route
                                            component={LoadableOrdersPage}
                                            path={`${this.props.match.url}/orders/:id`}
                                            exact
                                        />
                                        <Route
                                            component={LoadableOrdersPage}
                                            path={`${this.props.match.url}/orders/:id/version/:versionId`}
                                            exact
                                        />
                                        <Route
                                            component={ToolsScenarioPage}
                                            path={`${this.props.match.url}/scenario`}
                                        />
                                        <Route
                                            component={ToolsCalendarPage}
                                            path={`${this.props.match.url}/calendar`}
                                        />
                                        <Route component={LoadableOfficePage} path={`${this.props.match.url}/office`} />
                                    </Switch>
                                    {this.props.userTabEnabled && (
                                        <Route component={LoadableUsersPage} path={`${this.props.match.url}/users`} />
                                    )}
                                    {this.props.hrTabEnabled && [
                                        <Route
                                            component={LoadableEmployeesListPage}
                                            path={`${this.props.match.url}/hr`}
                                            key={1}
                                            exact
                                        />,
                                        this.props.editEmployeeEnabled && (
                                            <Route
                                                component={EditEmployeePage}
                                                path={`${this.props.match.url}/hr/employee/:id`}
                                                key={2}
                                            />
                                        ),
                                        this.props.editTemplateEnabled && (
                                            <Route
                                                component={EditTemplatePage}
                                                path={`${this.props.match.url}/hr/templates/:id`}
                                                key={3}
                                            />
                                        ),
                                        this.props.editTemplateEnabled && (
                                            <Route
                                                component={TemplatesListPage}
                                                path={`${this.props.match.url}/hr/templates`}
                                                key={4}
                                                exact
                                            />
                                        )
                                    ]}
                                    <Route component={LoadableMainsPage} path={`${this.props.match.url}/desktops`} />
                                    <Route
                                        component={ReporterDesktopPage}
                                        path={`${this.props.match.url}/reporter-desktops`}
                                    />
                                  <Route component={ChangePassword} path={`${this.props.match.url}/change-password`} />
                                </DashboardBody>
                                <DashboardFooter />
                                <ExternalsModal />
                                <InvitationModal />
                                <ContactPersonModal />
                            </div>
                        </SidebarAndContentWrapper>
                    </div>
                )}
                <Route path={`${this.props.match.url}/simulations/customer`} component={CustomerPage} />
                <Route path={`${this.props.match.url}/dashboard/order/:id`} component={DetailsPage} />
            </div>
        );
    }
}
