import React from 'react';
import MatInput from "../../../../components/common/Mat/MatInput";
import styled from "styled-components";

const InputContainer = styled.div`
    height: 70px;
`;

const LeftColumn = styled.div`
    width: 50%;
    display: inline-block;
`;

const RightColumn = styled.div`
    width: 50%;
    display: inline-block;
`;

const Container = styled.div`
    border-bottom: solid 1px rgb(121,129,153); 
    width: 90%;
`;

const Label = styled.span`
    font-size: 10px; 
    display: block; 
    color: rgb(121,129,153);
    font-family: Roboto, Helvetica, Arial, sans-serif !important;
`

const Value = styled.span`
    color: rgb(121,129,153);
    font-size: 14px;
    display: block;
    font-family: Roboto, Helvetica, Arial, sans-serif !important;
    line-height: 2;
    letter-spacing: 0.1px;
    padding-top: 5px;
    padding-bottom: 3px;
`;
export default function Company ({company}) {
    return (<>
        <LeftColumn>
            <InputContainer>
                <Container>
                    <Label>Short name</Label>
                    <Value>{company.name}</Value>
                </Container>
            </InputContainer>
            <InputContainer>
                <Container>
                    <Label>Street</Label>
                    <Value>{company.address1}</Value>
                </Container>
            </InputContainer>
        </LeftColumn>
        <RightColumn>
            <InputContainer>
                <Container>
                    <Label>Location</Label>
                    <Value>{company.country}</Value>
                </Container>
            </InputContainer>
            <InputContainer>
                <Container>
                    <Label>Post code, city</Label>
                    <Value>{company.address2}</Value>
                </Container>
            </InputContainer>
        </RightColumn>
    </>)
}
