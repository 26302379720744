import { makeActionCreator } from '../../utils';

export const TIMELINE_MONDAY_UPDATE = "TIMELINE_MONDAY_UPDATE";
export const TIMELINE_HOURS_RANGE_UPDATE = "TIMELINE_HOURS_UPDATE";
export const TIMELINE_HOURS_RANGE_UPDATE_RANGE = "TIMELINE_HOURS_RANGE_UPDATE_RANGE";
export const TIMELINE_HOURS_RANGE_ADD = "TIMELINE_HOURS_RANGE_ADD";
export const TIMELINE_HOURS_RANGE_DELETE = "TIMELINE_HOURS_RANGE_DELETE";
export const TIMELINE_HORUS_RANGE_CHANGE_CHECKBOX = "TIMELINE_HORUS_RANGE_CHANGE_CHECKBOX";
export const TIMELINE_HORUS_CATEGORY_RANGE_CHANGE_CHECKBOX = "TIMELINE_HORUS_CATEGORY_RANGE_CHANGE_CHECKBOX";

export const TIMELINE_HOURS_CATEGORIES = "TIMELINE_HOURS_CATEGORIES";
export const TIMELINE_HOURS_CATEGORIES2 = "TIMELINE_HOURS_CATEGORIES2";
export const TIMELINE_HOURS_CATEGORIES_DELETE = "TIMELINE_HOURS_CATEGORIES_DELETE";
export const TIMELINE_HOURS_CATEGORIES_ADD = "TIMELINE_HOURS_CATEGORIES_ADD";
export const TIMELINE_HOURS_CATEGORIES2_ADD = "TIMELINE_HOURS_CATEGORIES2_ADD";
export const TIMELINE_HOURS_RANGE_UPDATE_BY_CATEGORY_AND_GROUP_INDEX = "TIMELINE_HOURS_RANGE_UPDATE_BY_CATEGORY_AND_GROUP_INDEX";
export const TIMELINE_HOURS_RANGE_UPDATE_BY_CATEGORY2_AND_GROUP_INDEX = "TIMELINE_HOURS_RANGE_UPDATE_BY_CATEGORY2_AND_GROUP_INDEX";
export const TIMELINE_HOURS_NAME_UPDATE = "TIMELINE_HOURS_NAME_UPDATE";
// export const TIMELINE_HOURS_RANGE_TITLE_UPDATE = "TIMELINE_HOURS_RANGE_TITLE_UPDATE";
export const TIMELINE_HOURS_SUBCATEGORIES_ADD = "TIMELINE_HOURS_SUBCATEGORIES_ADD";
export const TIMELINE_HOURS_SUBCATEGORIES2_ADD = "TIMELINE_HOURS_SUBCATEGORIES2_ADD";
export const TIMELINE_HOURS_SUBCATEGORIES_DEL = "TIMELINE_HOURS_SUBCATEGORIES_DEL";
export const TIMELINE_HOURS_SUBCATEGORIES2_DEL = "TIMELINE_HOURS_SUBCATEGORIES2_DEL";
export const TIMELINE_HOURS_CHANGE_SPLIT_REPORT = "TIMELINE_HOURS_CHANGE_SPLIT_REPORT";

export const timelineHoursRangeAddRange = makeActionCreator(TIMELINE_HOURS_RANGE_ADD, "weekIndex", "payload");
export const timelineHoursRangeChangeCheckbox = makeActionCreator(TIMELINE_HORUS_RANGE_CHANGE_CHECKBOX, "weekIndex", "payload");
export const timelineHoursCategoryChangeCheckbox = makeActionCreator(TIMELINE_HORUS_CATEGORY_RANGE_CHANGE_CHECKBOX, "categoryIndex", "groupIndex", "checkIndex", "payload");
export const timelineHoursRangeDeleteRangeByIndex = makeActionCreator(TIMELINE_HOURS_RANGE_DELETE, "rangeIndex", "weekIndex");
export const timelineHoursRangeUpdateRange = makeActionCreator(TIMELINE_HOURS_RANGE_UPDATE_RANGE, "weekIndex", "rangeIndex", "payload");
export const timelineHoursRangeUpdate = makeActionCreator(TIMELINE_HOURS_RANGE_UPDATE, "payload");


export const timelineHoursCategories = makeActionCreator(TIMELINE_HOURS_CATEGORIES, "payload");
export const timelineHoursCategories2 = makeActionCreator(TIMELINE_HOURS_CATEGORIES2, "payload");
export const timelineHoursCategoriesDelete = makeActionCreator(TIMELINE_HOURS_CATEGORIES_DELETE, "payload");
export const timelineHoursCategoriesAdd = makeActionCreator(TIMELINE_HOURS_CATEGORIES_ADD, "payload");
export const timelineHoursCategories2Add = makeActionCreator(TIMELINE_HOURS_CATEGORIES2_ADD, "payload");
export const timelineHoursSubcategoriesAdd = makeActionCreator(TIMELINE_HOURS_SUBCATEGORIES_ADD, "categoryIndex", "payload");
export const timelineHoursSubcategories2Add = makeActionCreator(TIMELINE_HOURS_SUBCATEGORIES2_ADD, "categoryIndex", "payload");
export const timelineHoursSubcategoriesDelete = makeActionCreator(TIMELINE_HOURS_SUBCATEGORIES_DEL, "categoryIndex", "groupIndex");
export const timelineHoursSubcategories2Delete = makeActionCreator(TIMELINE_HOURS_SUBCATEGORIES2_DEL, "categoryIndex", "rangeIndex");
export const timelineHoursRangeUpdateByCategoryAndGroupIndex = makeActionCreator(TIMELINE_HOURS_RANGE_UPDATE_BY_CATEGORY_AND_GROUP_INDEX, "categoryIndex", "groupIndex", "field", "payload");
export const timelineHoursRangeUpdateByCategory2AndGroupIndex = makeActionCreator(TIMELINE_HOURS_RANGE_UPDATE_BY_CATEGORY2_AND_GROUP_INDEX, "categoryIndex", "rangeIndex", "field", "payload");
export const timelineHoursNameUpdate = makeActionCreator(TIMELINE_HOURS_NAME_UPDATE, "categoryIndex", "payload");
export function deleteCategory(index) {
  return async (dispatch) => {
    dispatch(timelineHoursCategoriesDelete(index));
  }
}
export function addNewCategory() {
  return async (dispatch) => {
    dispatch(timelineHoursCategoriesAdd({category: 'new', grouped: [{initialIntervalFrom: null, initialIntervalTo: null, checkedDays: [null, null, null, null, null, null, null, null]}]}));
  }
}
export function addNewCategory2() {
  return async (dispatch) => {
    dispatch(timelineHoursCategories2Add({category: 'new', grouped: [{initialIntervalFrom: null, initialIntervalTo: null, checkedDays: [null, null, null, null, null, null, null, null]}]}));
  }
}

export function addNewSubcategory(categoryIndex) {
  return async (dispatch) => {
    dispatch(timelineHoursSubcategoriesAdd(categoryIndex, {initialIntervalFrom: null, initialIntervalTo: null, checkedDays: [null, null, null, null, null, null, null, null]}))
    // dispatch(timelineHoursCategoriesAdd({category: 'new', grouped: [{initialIntervalFrom: null, initialIntervalTo: null, checkedDays: [null, null, null, null, null, null, null]}]}));
  }
}
export function addNewSubcategory2(categoryIndex) {
  return async (dispatch) => {
    dispatch(timelineHoursSubcategories2Add(categoryIndex, {initialIntervalFrom: null, initialIntervalTo: null, name: null}))
    // dispatch(timelineHoursCategoriesAdd({category: 'new', grouped: [{initialIntervalFrom: null, initialIntervalTo: null, checkedDays: [null, null, null, null, null, null, null]}]}));
  }
}

export function deleteSubcategory(categoryIndex, groupIndex) {
  return async (dispatch) => {
    dispatch(timelineHoursSubcategoriesDelete(categoryIndex, groupIndex))
  }
}
export function deleteSubcategory2(categoryIndex, rangeIndex) {
  return async (dispatch) => {
    dispatch(timelineHoursSubcategories2Delete(categoryIndex, rangeIndex))
  }
}

export function addRange(data, weekIndex) {
  return async (dispatch) => {
    dispatch(timelineHoursRangeAddRange(weekIndex, data))
  }
}

export function deleteRangeByWeekIndexAndRangeIndex(rangeIndex, weekIndex) {
  return async (dispatch) => {
    dispatch(timelineHoursRangeDeleteRangeByIndex(rangeIndex, weekIndex));
  }
}

export function changeName(categoryIndex, value) {
  return async (dispatch) => {
    dispatch(timelineHoursNameUpdate(categoryIndex, value))
  }
}
export function changeRangeName(categoryIndex, rangeIndex, value) {
  return async (dispatch) => {
    dispatch(timelineHoursRangeUpdateByCategory2AndGroupIndex(categoryIndex, rangeIndex, "name", value))
  }
}

export function changeRangeFrom(categoryIndex, groupIndex, value) {
  return async (dispatch) => {
    dispatch(timelineHoursRangeUpdateByCategoryAndGroupIndex(categoryIndex, groupIndex, "initialIntervalFrom", value))//, {...getState().hoursRangeReducer.week[categoryIndex].ranges[groupIndex], initialIntervalFrom: value}))
  }
}
export function changeRange2From(categoryIndex, rangeIndex, value) {
  return async (dispatch) => {
    dispatch(timelineHoursRangeUpdateByCategory2AndGroupIndex(categoryIndex, rangeIndex, "initialIntervalFrom", value))//, {...getState().hoursRangeReducer.week[categoryIndex].ranges[groupIndex], initialIntervalFrom: value}))
  }
}
export function changeRangeTo(categoryIndex, groupIndex, value) {
  return async (dispatch) => {
    dispatch(timelineHoursRangeUpdateByCategoryAndGroupIndex(categoryIndex, groupIndex, "initialIntervalTo", value))
  }
}
export function changeRange2To(categoryIndex, groupIndex, value) {
  return async (dispatch) => {
    dispatch(timelineHoursRangeUpdateByCategory2AndGroupIndex(categoryIndex, groupIndex, "initialIntervalTo", value))
  }
}
