const EXTRA_FIELD_LABELS = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "R",
    "S",
    "T",
    "U",
    "W",
    "X",
    "Y",
    "Z"
];

const SMALL_EXTRA_FIELD_LABELS = [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "r",
    "s",
    "t",
    "u",
    "w",
    "x",
    "y",
    "z"
];

export const makeInitialState = _ => ({
    problemDescriptionContent: "",
    reworkDescriptionContent: "",
    extraFields: [],
    smallExtraFields: [],
    isLoading: false,
    modified: false
});

export default function problemDescriptionHOR({
    PROBLEM_DESCRIPTION_CONTENT_CHANGED,
    REWORK_DESCRIPTION_CONTENT_CHANGED,
    EXTRA_FIELD_ADDED,
    EXTRA_FIELD_CHANGED,
    EXTRA_FIELD_REMOVED,
    SMALL_PROBLEM_DESCRIPTION_CONTENT_CHANGED,
    SMALL_EXTRA_FIELD_ADDED,
    SMALL_EXTRA_FIELD_CHANGED,
    SMALL_EXTRA_FIELD_REMOVED,
    SMALL_PROBLEM_DESCRIPTION_CHANGED = Symbol(),
    RESET,
    DATA_CHANGED,
    PROBLEM_DESCRIPTION_CHANGED = Symbol(),
    IS_LOADING_CHANGED = Symbol()
}) {
    return (state = makeInitialState(), action) => {
        switch (action.type) {
            case RESET:
                return makeInitialState();
            case DATA_CHANGED:
                return {
                    modified: false,
                    ...action.payload.problemDescription
                };
            case PROBLEM_DESCRIPTION_CONTENT_CHANGED:
                return {
                    ...state,
                    modified: true,
                    problemDescriptionContent: action.payload
                };
            case REWORK_DESCRIPTION_CONTENT_CHANGED:
                return {
                    ...state,
                    modified: true,
                    reworkDescriptionContent: action.payload
                };
            case SMALL_PROBLEM_DESCRIPTION_CONTENT_CHANGED:
                return {
                    ...state,
                    modified: true,
                    smallProblemDescriptionContent: action.payload
                };
            case EXTRA_FIELD_ADDED: {
                if (state.extraFields.length >= EXTRA_FIELD_LABELS.length) {
                    return state;
                }
                // when there were no fields we want to add a and b
                let amountOfFieldsToAdd = state.extraFields.length === 0 ? 2 : 1;

                let fieldsToAdd = [];
                EXTRA_FIELD_LABELS.some(label => {
                    // skip this label when it was already added
                    if (state.extraFields.some(f => f.label === label)) return false;
                    fieldsToAdd.push({
                        label,
                        value: ""
                    });
                    amountOfFieldsToAdd--;
                    // finished adding
                    if (amountOfFieldsToAdd === 0) {
                        return true;
                    }
                    return false;
                });
                let extraFields = [...state.extraFields, ...fieldsToAdd];
                extraFields.sort((a, b) => {
                    return EXTRA_FIELD_LABELS.indexOf(a.label) - EXTRA_FIELD_LABELS.indexOf(b.label);
                });
                return {
                    ...state,
                    modified: true,
                    extraFields
                };
            }
            case SMALL_EXTRA_FIELD_ADDED: {
                if (state.smallExtraFields.length >= SMALL_EXTRA_FIELD_LABELS.length) {
                    return state;
                }
                // when there were no fields we want to add a and b
                let amountOfSmallFieldsToAdd = state.smallExtraFields.length === 0 ? 2 : 1;

                let fieldsToAdd = [];
                SMALL_EXTRA_FIELD_LABELS.some(label => {
                    // skip this label when it was already added
                    if (state.smallExtraFields.some(f => f.label === label)) return false;
                    fieldsToAdd.push({
                        label,
                        value: ""
                    });
                    amountOfSmallFieldsToAdd--;
                    // finished adding
                    if (amountOfSmallFieldsToAdd === 0) {
                        return true;
                    }
                    return false;
                });
                let smallExtraFields = [...state.smallExtraFields, ...fieldsToAdd];
                smallExtraFields.sort((a, b) => {
                    return SMALL_EXTRA_FIELD_LABELS.indexOf(a.label) - SMALL_EXTRA_FIELD_LABELS.indexOf(b.label);
                });
                return {
                    ...state,
                    modified: true,
                    smallExtraFields
                };
            }
            case EXTRA_FIELD_REMOVED:
                return {
                    ...state,
                    modified: true,
                    extraFields: state.extraFields.filter((f, i) => i !== action.fieldIndex)
                };
            case SMALL_EXTRA_FIELD_REMOVED:
                return {
                    ...state,
                    modified: true,
                    smallExtraFields: state.smallExtraFields.filter((f, i) => i !== action.fieldIndex)
                };
            case EXTRA_FIELD_CHANGED:
                return {
                    ...state,
                    modified: true,
                    extraFields: state.extraFields.map((f, i) => {
                        if (i !== action.fieldIndex) return f;
                        return {
                            label: f.label,
                            value: action.payload
                        };
                    })
                };
            case SMALL_EXTRA_FIELD_CHANGED:
                return {
                    ...state,
                    modified: true,
                    smallExtraFields: state.smallExtraFields.map((f, i) => {
                        if (i !== action.fieldIndex) return f;
                        return {
                            label: f.label,
                            value: action.payload
                        };
                    })
                };
            case PROBLEM_DESCRIPTION_CHANGED:
                return {
                    ...state,
                    modified: false,
                    ...action.payload
                };
            case SMALL_PROBLEM_DESCRIPTION_CHANGED:
                return {
                    ...state,
                    modified: false,
                    ...action.payload
                };
            case IS_LOADING_CHANGED:
                return {
                    ...state,
                    isLoading: action.payload
                };
            default:
                return state;
        }
    };
}
