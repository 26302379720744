import styled from "styled-components";

export const Table = styled.table`
    border: 0px !important;
`;

export const Container = styled.div`
    @media (max-width: 800px) {
        overflow-x: scroll;
    }
`;

export const Td = styled.td`
    height: 64px;
    font-size: 12px;
    font-weight: bold;
    border-top: 0.0625rem solid rgb(240, 242, 245);
    color: rgb(123, 128, 154);
    padding-left: 16px;
`;

export const Th = styled.th`
    width: 250px; 
    text-transform: uppercase;
    height: 64px;
    font-size: 10px;
    color: rgb(123, 128, 154);
    opacity: 0.7;
    padding-left: 24px;
    letter-spacing: 0.5px;
`;

export const Tr = styled.tr`
    &:hover {
        background-color: #e1e1e1;
    }
`;
