import {makeActionCreator} from "../../utils";
import {apiFetch} from "../../actions/apiActions";

export const SIMULATION_STATE_CHANGED = "DESKTOPS.SIMULATIONS.SIMULATION_STATE_CHANGED";
export const SIMULATION_ACCOUNTS_CHANGED = "DESKTOPS.SIMULATIONS.SIMULATION_ACCOUNTS_CHANGED";

export const simulationsStateChanged = makeActionCreator(SIMULATION_STATE_CHANGED, "orderId");
export const accountsStateChanged = makeActionCreator(SIMULATION_ACCOUNTS_CHANGED, "accounts");

export function getSimulationsList (orderId) {
    return async dispatch => {
        try {
            let simulationResp = await dispatch(apiFetch(`/api/desktops/simulations/company/${encodeURIComponent(orderId)}`));
            let simulationData = await simulationResp.json();
            if (simulationResp.status !== 200) {
                throw new Error("An error has occured: " + (simulationData.messages || "Unknown error"));
            }
            if (!simulationData || typeof simulationData !== "object") {
                throw new Error("Data returned by API is not an object!");
            }
            dispatch(accountsStateChanged(simulationData));
        } catch (e) {
        }
    };
}
