export const VERROR_INTERNAL_ORDER_NUMBER_TAKEN = "INTERNAL_ORDER_NUMBER_TAKEN";
export const VERROR_NO_OFFICIAL_ORDER_NAME = "VERROR_NO_OFFICIAL_ORDER_NAME";
export const VERROR_NO_UNOFFICIAL_ORDER_NAME = "VERROR_NO_UNOFFICIAL_ORDER_NAME";
export const VERROR_NO_CALENDAR_SELECT = "VERROR_NO_CALENDAR_SELECT";
export const VERROR_NO_SCENARIO_SELECT = "VERROR_NO_SCENARIO_SELECT";

export const validationErrors = {
    [VERROR_INTERNAL_ORDER_NUMBER_TAKEN]: () => ({
        type: VERROR_INTERNAL_ORDER_NUMBER_TAKEN,
        message: "The internal order number is already taken."
    }),
    [VERROR_NO_OFFICIAL_ORDER_NAME]: () => ({
        type: VERROR_NO_OFFICIAL_ORDER_NAME,
        message: "No official order name."
    }),
    [VERROR_NO_UNOFFICIAL_ORDER_NAME]: () => ({
        type: VERROR_NO_UNOFFICIAL_ORDER_NAME,
        message: "No unofficial order name."
    }),
    [VERROR_NO_CALENDAR_SELECT]: () => ({
        type: VERROR_NO_CALENDAR_SELECT,
        message: "No select calendar "
    }),
    [VERROR_NO_SCENARIO_SELECT]: () => ({
        type: VERROR_NO_SCENARIO_SELECT,
        message: "No select scenario "
    })
};
