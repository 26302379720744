import {
    STATE_NEW,
    STATE_CHANGED,
    ERROR_CHANGED,
    ORDER_VERSIONS_CHANGED,
    VIEWED_VERSION_CHANGED,
    SHOW_VERSION_MESSAGE_MODAL_CHANGED,
    MODAL_VERSION_MESSAGE_CHANGED,
    ORDER_RESET,
    ORDER_DATA_CHANGED,
    FINISHED_CHANGED,
} from "../actions/orderActions";

const makeInitialState = _ => ({
    state: STATE_NEW,
    error: null,
    orderId: null,
    versions: [],
    viewedVersion: null,
    showVersionMessageModal: false,
    modalVersionMessage: "",
    finished: false,
});

export default function order(state = makeInitialState(), action) {
    switch (action.type) {
        case ORDER_RESET:
            return makeInitialState();
        case ORDER_DATA_CHANGED:
            return {
                ...state,
                orderId: action.payload._id,
                finished: action.payload.finished
            };
        case STATE_CHANGED:
            return {
                ...state,
                state: action.payload
            };
        case ERROR_CHANGED:
            return {
                ...state,
                error: action.payload
            };
        case ORDER_VERSIONS_CHANGED:
            return {
                ...state,
                versions: action.payload
            };
        case VIEWED_VERSION_CHANGED:
            return {
                ...state,
                viewedVersion: action.payload
            };
        case SHOW_VERSION_MESSAGE_MODAL_CHANGED:
            return {
                ...state,
                showVersionMessageModal: action.payload
            };
        case MODAL_VERSION_MESSAGE_CHANGED:
            return {
                ...state,
                modalVersionMessage: action.payload
            };
        case FINISHED_CHANGED:
            return {
                ...state,
                finished: action.payload
            };
        default:
            return state;
    }
}
