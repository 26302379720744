import {SIMULATION_STATE_CHANGED, SIMULATION_ACCOUNTS_CHANGED} from "../actions/simulationsActions";

const initialState = {
    show: false,
    orderId: null,
    accounts: []
}
export default function simulation(state = initialState, action) {
    switch (action.type) {
        case SIMULATION_STATE_CHANGED:
            return {
                ...state,
                show: !state.show,
                orderId: action.orderId
            };
        case SIMULATION_ACCOUNTS_CHANGED:
            return {
                ...state,
                accounts: action.accounts
            }
        default:
            return state;
    }
}
