import React from "react";
import { Route } from 'react-router';
import ToolsScenario from './ToolsScenario';
import styled from 'styled-components';
import MainScenario from "./MainScenario";

export default function ToolsScenarioPage(props) {
    const Margin20 = styled.div`
    margin-top: 20px;
`;
    return (
        <div>
            <style>
                {`body {
                        background: #f1f2f7;
                    }`}
            </style>
            <Margin20 />
            <Route exact path={`${props.match.url}`} component={ToolsScenario} />
            <Route exact path={`${props.match.url}/:id`} component={MainScenario} />
        </div>
    )
}
