import styled, {css} from "styled-components";
import {Table} from "react-bootstrap";

export const TitleContainer = styled.div`
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 10px
`;

export const PartContainer = styled.div`
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 400;
    color: rgb(123, 128, 154);
    max-height: 72px;
    overflow-y: auto;
`;

export const TableBody = styled(Table)`
    width: auto;
    border: none !important;
    padding: 10px;
`;
