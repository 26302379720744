import { DATA_CHANGED, DATA_CHANGED_ORDER_NAME } from "../actions/dashboardActions";

export const initialState = {
    data: {
        orders: {
            active: {
                year: 0,
                total: 0
            },
            closed: {
                year: 0,
                total: 0
            }
        },
        partChecked: {
            week: 0,
            month: 0,
            year: 0
        },
        workingHours: {
            week: 0,
            month: 0,
            year: 0
        },
        NOK: {
            week: 0,
            month: 0,
            year: 0
        },
        ordersTableRows:[]
    }}

function updateOrderTableRowOrderName(rows, field, action, key) {
    rows = rows.map(el => {
        if(el[key] === action[key]) {
            el[field] = action.payload;
        }
        return el;
    })
    return rows;
}
export default function clientPanelDashboard(state = initialState, action) {
    switch (action.type) {
        case DATA_CHANGED:
            return {
                ...state,
                data: action.payload
            };
        case DATA_CHANGED_ORDER_NAME:
            return {
                ...state,
                data: {
                    ...state.data,
                    ordersTableRows: updateOrderTableRowOrderName(state.data.ordersTableRows, "orderName", action, "_id")
                }
            }
        default:
            return state;
    }
}
