import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { STATE_LOADED } from "../../actions/customerPanelExternalsActions";
import { setTitle } from "./External";
import { TableBody } from "./Styles";

const Td = styled.td`
    vertical-align: middle !important;
`;

const TdMiddle = styled.td`
    vertical-align: middle !important;
`;

@connect(({ clientPanel }) => clientPanel.clientPanelExternals)
export default class DailyPartReport extends React.Component {
    render() {
        let header = "";
        let content = "";
        if (this.props.state === STATE_LOADED) {
            let dailyPartReport = this.props.externals.dailyPartReport;
            header = (
                <tr>
                    {dailyPartReport.header &&
                        dailyPartReport.header.map(i => (
                            <th title={setTitle(i.val, this.props.externals.letterColumnsDescription)} key={i.key} style={{width: i.width}}>{i.val}</th>
                        ))}
                </tr>
            );
            if (dailyPartReport.rows) {
                content = dailyPartReport.rows.map((r, index) => {
                    let result = "";

                    result = (
                        <tr key={r.partNumber.val + index}>
                            {r.date && <Td rowSpan={r.date.rowSpan}>{r.date.val.substring(0, 10)}</Td>}
                            {r.partNumber.rowSpan !== 0 && <Td rowSpan={r.partNumber.rowSpan}>{r.partNumber.val}</Td>}
                            {r.ec.map(r => {
                                let res = r.rowSpan !== 0 ? <Td rowSpan={r.rowSpan}>{r.val}</Td> : null;
                                return res;
                            })}
                            <Td>{r.totalChecked}</Td>
                            <Td>{r.fromThisOk}</Td>
                            <Td>{r.reworked}</Td>
                            {r.reworkDetails.map(d => {
                                return <TdMiddle>{d.val}</TdMiddle>;
                            })}
                            <Td>{r.nok}</Td>
                            {r.nokDetails.map(d => {
                                return <TdMiddle>{d.val}</TdMiddle>;
                            })}
                            <Td>{r.totalOk}</Td>
                            {(r.remarks || r.remarks === "") &&
                                dailyPartReport.header.some(h => h.key === "remarks") && (
                                    <Td>{r.remarks.map(i => i + " ")}</Td>
                                )}
                            {r.workingHours && <Td rowSpan={r.workingHours.rowSpan}>{r.workingHours.val + " h"}</Td>}
                            {(r.generalRemarks && r.generalRemarks.val && r.generalRemarks.val.length > 0 && (
                                <Td rowSpan={r.generalRemarks.rowSpan}>
                                    {r.generalRemarks.val.map(v => (
                                        <p style={{ margin: "5px 0" }}>{v}</p>
                                    ))}
                                </Td>
                            )) ||
                                (dailyPartReport.header.some(h => h.key === "generalRemarks") && r.workingHours && (
                                    <Td rowSpan={r.workingHours.rowSpan} />
                                ))}
                        </tr>
                    );
                    return result;
                });
            }
        }
        return (
            <TableBody responsive={true}>
                <tbody>
                    {header}
                    {content}
                </tbody>
            </TableBody>
        );
    }
}
