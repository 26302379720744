import StyledIcon from "../../../components/common/StyledIcon";
import React from "react";
import styled from "styled-components";

const BottomContainer = styled.div`
    padding-bottom: 16px;
    padding-right: 16px;
    opacity: 1;
    background: transparent;
    color: rgb(121, 129, 153);//rgb(190, 190, 190);
    box-shadow: none;
`;

const BottomContainerParagraph = styled.p`
    margin: 0px;
    font-family: Roboto, Helvetica, Arial, sans-serif !important;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.5;
    letter-spacing: 0.02857em;
    display: flex;
    opacity: 1;
    text-transform: none;
    vertical-align: unset;
    text-decoration: none;
    color: rgb(121, 129, 153);//rgb(190, 190, 190);
`;

const GreenValueSpan = styled.span`
    margin: 0px;
    font-family: Roboto, Helvetica, Arial, sans-serif !important;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.02857em;
    opacity: 1;
    text-transform: none;
    vertical-align: unset;
    text-decoration: none;
    color: rgb(76, 175, 80);
    font-weight: 700;
    padding-left: 3px;
    padding-right: 3px;
    margin-left: 5px;
    margin-right: 5px;
`;

const Span = styled.span`
    cursor: pointer;
    margin-left: 2px;
    margin-right: 2px;
`;

export default function Bottom ({mode, setRange, range, text = 'this', rangeCount = 3}) {
    return (
        <BottomContainer mode={mode}>
            {mode === "1" && (
                <BottomContainerParagraph>
                    <StyledIcon
                        clickable
                        glyph="time"
                        color="rgb(123, 128, 154)"
                        size="small"
                        style={{paddingRight: 5}}
                    /> {text}
                    {range === 'year' && (<><GreenValueSpan> year </GreenValueSpan> / <Span onClick={() => setRange("total")}> total </Span></>)}
                    {range === 'total' && (<><Span onClick={() => setRange("year")}> year </Span> / <GreenValueSpan> total </GreenValueSpan></>)}
                </BottomContainerParagraph>
            )}
            {mode !== "1" && mode !== "chart" &&(<BottomContainerParagraph>
                <StyledIcon
                    clickable
                    glyph="time"
                    color="rgb(123, 128, 154)"
                    size="small"
                    style={{paddingRight: 5}}
                /> {text}
                {range === 'week' && (<><GreenValueSpan> week </GreenValueSpan> / <Span onClick={() => setRange("month")}> month </Span> / <Span onClick={() => setRange("year")}> year </Span></>)}
                {range === 'month' && (<><Span onClick={() => setRange("week")}> week </Span> / <GreenValueSpan> month </GreenValueSpan> / <Span onClick={() => setRange("year")}> year </Span></>)}
                {range === 'year' && (<><Span onClick={() => setRange("week")}> week </Span> / <Span onClick={() => setRange("month")}> month </Span> / <GreenValueSpan> year </GreenValueSpan></>)}
            </BottomContainerParagraph>)}
            {mode === "chart" && (<BottomContainerParagraph>
                <StyledIcon
                    clickable
                    glyph="time"
                    color="rgb(123, 128, 154)"
                    size="small"
                    style={{paddingRight: 5}}
                /> {text}
                {range === 'week' && rangeCount === 3 && (<><GreenValueSpan> week </GreenValueSpan> / <Span onClick={() => setRange("month")}> months </Span> / <Span onClick={() => setRange("year")}> years </Span></>)}
                {range === 'month' && rangeCount === 3 && (<><Span onClick={() => setRange("week")}> week </Span> / <GreenValueSpan> months </GreenValueSpan> / <Span onClick={() => setRange("year")}> years </Span></>)}
                {range === 'year' && rangeCount === 3 && (<><Span onClick={() => setRange("week")}> week </Span> / <Span onClick={() => setRange("month")}> months </Span> / <GreenValueSpan> years </GreenValueSpan></>)}
                {range === 'week' && rangeCount === 2 && (<><GreenValueSpan> week </GreenValueSpan> / <Span onClick={() => setRange("month")}> months </Span></>)}
                {range === 'month' && rangeCount === 2 && (<><Span onClick={() => setRange("week")}> week </Span> / <GreenValueSpan> months </GreenValueSpan></>)}
                {range === 'week' && rangeCount === 1 && (<><GreenValueSpan> week </GreenValueSpan></>)}

            </BottomContainerParagraph>)}
        </BottomContainer>
    );
}
