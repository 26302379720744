import React, { useState } from "react";
import { push } from "react-router-redux";
import styled, { css } from "styled-components";
import MatSortableTableHeader from "../../components/common/AutoTable/MatSortableTableHeader";

const DataColumn = styled.div`
    display: inline-flex;
    flex-direction: column;
    min-width: 200px;
    ${p =>
    p.colored &&
    css`
            color: #41b9f1;
        `}
`;

const ClickableTr = styled.tr`
    cursor: pointer;
`;

const ContactsCellWrapper = styled.div`
    color: #41b9f1;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    /* justify-content: center; */
`;

const ContactsCellRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    white-space: nowrap;
    min-width: 400px;
    div {
        width: 50%;
        margin-right: 5px;
    }
`;

const ContactsCell = ({ value, row }) => {
    const [expanded, setExpanded] = useState(false);
    let rows = [];
    let contactsList = row.contacts;
    if (!expanded) {
        contactsList = contactsList.slice(0, 6);
    }
    contactsList.forEach((c, i) => {
        if (i % 2 === 0) {
            rows.push([]);
        }

        rows[rows.length - 1].push(<div key={c._id}>{c.name}</div>);
    });
    return (
        <ContactsCellWrapper onClick={ev => ev.stopPropagation()}>
            <div>
                {rows.map((r, i) => (
                    <ContactsCellRow key={i}>{r}</ContactsCellRow>
                ))}
            </div>
            {row.contacts.length > 6 && <DropdownArrow isOpen={expanded} onClick={() => setExpanded(!expanded)} />}
        </ContactsCellWrapper>
    );
};

const ModificationDateCell = ({value, row}) => {
    let lastData = row.reasonOfChanges[row.reasonOfChanges.length - 1];
    let date = null;
    if(lastData) {
        date = lastData.date
    } else {
        date = new Date(+row.createDate)
    }
    //format date
    let dateObj = new Date(date).toLocaleString(undefined, {year: 'numeric', month: '2-digit', day: '2-digit', weekday: undefined, hour: '2-digit', hour12: false, minute:'2-digit', second:'2-digit'});
    let dateToShow = dateObj.toString().replace(",", "")
    return (<ContactsCellWrapper onClick={ev => ev.stopPropagation()}>
        <div>
            {dateToShow}
        </div>
    </ContactsCellWrapper>)
}
const ModificationReasonCell = ({value, row}) => {
    let lastData = row.reasonOfChanges[row.reasonOfChanges.length - 1];
    let reason = null;
    if(lastData) {
        reason = lastData.reasonOfChange
    } else {
        reason = "Initial version"
    }
    return (<ContactsCellWrapper onClick={ev => ev.stopPropagation()}>
        <div>
            {reason}
        </div>
    </ContactsCellWrapper>)
}
const ModificationPersonCell = ({value, row}) => {
    let lastData = row.author[row.author.length - 1];
    let person = null;
    if(row.latestEditor[0]) {
        person = row.latestEditor[0].displayName
    } else {
        person = row.author[0].displayName
    }
    return (<ContactsCellWrapper onClick={ev => ev.stopPropagation()}>
        <div>
            {person}
        </div>
    </ContactsCellWrapper>)
}

const CreatedPersonCell = ({value, row}) => {
    return (<ContactsCellWrapper onClick={ev => ev.stopPropagation()}>
        <div>
            {(row.author[0] && row.author[0].displayName) || "Created by migration" }
        </div>
    </ContactsCellWrapper>)
}

const DropdownArrow = styled.div`
    position: relative;
    width: 14px;
    height: 12px;
    align-self: center;
    margin-right: 10px;
    transform: rotate(${props => (props.dropup ? "180deg" : "0deg")});
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    ${props =>
    props.isOpen &&
    css`
            transform: rotate(${props => (props.dropup ? "0deg" : "180deg")});
        `}
    &:before,
    &:after {
        position: absolute;
        top: 5px;
        left: 0;
        width: 10px;
        height: 0px;
        content: " ";

        border: 0.7px solid #a3a3a3;
    }
    &:before {
        transform: rotate(55deg);
    }
    &:after {
        transform: rotate(-55deg);
        left: 4.5px;
    }
`;

export default {
    rowsSelector: state => state.scenariosReducer.allScenarioList,
    header: MatSortableTableHeader,
    useMatTable: true,
    showNoResultsText: true,
    stickyHeader: true,
    sitckyTopMargin: "0",
    customRow: ({ row, children, dispatch }) => (
        <ClickableTr onClick={() => dispatch(push(`scenario/${row._id}`))}>{children}</ClickableTr>
    ),
    columns: [
        {
            key: "Name",
            title: "Name",
            valueSelector: (r, i) => r.title,
            cell: ({ value, row }) => (
                <DataColumn colored>
                    <div>{value}</div>
                </DataColumn>
            ),
        },
        {
            key: "lastModification",
            title: "Last modification",
            valueSelector: (r, i) => r.name,
            cell: ModificationDateCell,
        },
        {
            key: "modificationReason",
            title: "Modification reason",
            valueSelector: (r, i) => "",
            cell: ModificationReasonCell
        },
        {
            key: "modificationPerson",
            title: "Modification person",
            cell: ModificationPersonCell
        },
        {
            key: "createdPerson",
            title: "Created person",
            cell: CreatedPersonCell,
        },
    ]
};
