// import { ORDER_DATA_CHANGED, ORDER_RESET } from "../actions/orderActions";
import {ORDER_SCENARIO_CHANGED} from "../actions/scenarioActions";
import {ORDER_DATA_CHANGED, ORDER_RESET} from "../actions/orderActions";

const makeInitialState = _ => ({
    scenario: null
});

export default function scenario(state = makeInitialState(), action) {
    switch (action.type) {
        case ORDER_DATA_CHANGED:
            return action.payload.scenario;
        case ORDER_RESET:
            return makeInitialState();
        case ORDER_SCENARIO_CHANGED:
            return {
                ...state,
                scenario: action.payload
            };
        default:
            return state;
    }
}
