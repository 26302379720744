import styled from "styled-components";

export const Hr = styled.hr`
    flex-shrink: 0;
    border-top: 0px solid rgba(0, 0, 0, 0.12);
    border-right: 0px solid rgba(0, 0, 0, 0.12);
    border-left: 0px solid rgba(0, 0, 0, 0.12);
    background-color: transparent;
    height: 0.0625rem;
    margin: 16px 0px;
    border-bottom: none;
    opacity: 0.25;
    background-image: linear-gradient(to right, rgba(52, 71, 103, 0), rgba(52, 71, 103, 0.4), rgba(52, 71, 103, 0)) !important;
`;

export const MainContainer = styled.div`
    width: 100%; 
    height: 373px; 
    padding-top: 32px; 
    padding-left: 12px;
`;

export const MainBottomContainer = styled.div`
    width: 100%; 
    padding-top: 32px; 
    padding-left: 12px;
`;

export const Container = styled.div`
    margin-bottom: 24px;
    opacity: 1;
    background: transparent;
    color: rgb(52, 71, 103);
    box-shadow: none;
`;

export const ContainerInside = styled.div`
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    display: flex;
    flex-direction: column;
    position: relative;
    min-width: 0px;
    overflow-wrap: break-word;
    background-color: rgb(255, 255, 255);
    background-clip: border-box;
    border: 0px solid rgba(0, 0, 0, 0.125);
    border-radius: 12px;
    box-shadow: rgb(0 0 0 / 10%) 0px 4px 6px -1px, rgb(0 0 0 / 6%) 0px 2px 4px -1px;
    overflow: visible;
    height: 100%;
`;

export const ContentContainer = styled.div`
    padding: 16px;
    opacity: 1;
    background: transparent;
    color: rgb(52, 71, 103);
    box-shadow: none;
`;

export const Header = styled.div`
    margin: 0px;
    font-size: 16px;
    line-height: 1.625;
    font-family: Roboto, Helvetica, Arial, sans-serif !important;
    font-weight: 700;
    letter-spacing: 0.0075em;
    opacity: 1;
    text-transform: capitalize;
    vertical-align: unset;
    text-decoration: none;
    color: rgb(52, 71, 103);
`;

export const Description = styled.div`
    margin: 0;
    font-family: Roboto, Helvetica, Arial, sans-serif !important;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.02857em;
    opacity: 1;
    text-transform: none;
    vertical-align: unset;
    text-decoration: none;
    color: rgb(121, 129, 153);//rgb(190, 190, 190);
    font-weight: 300;
`;

export const MainValueContainerHeader = styled.span`
    margin: 0;
    font-family: Roboto, Helvetica, Arial, sans-serif !important;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.05rem;
    opacity: 1;
    text-transform: none;
    vertical-align: unset;
    text-decoration: none;
    color: rgb(121, 129, 153);//rgb(190, 190, 190);//rgb(207, 208, 212);
    font-weight: 200;
`;

export const NotificationContainer = styled.div`
    padding-top: 20px; 
    height: 330px;
    overflow: hidden;
    overflow-y: scroll;
`;
