import React from "react";
import ExternalsList from "./ExternalsList";
import Searchbar from "./Searchbar";
import styled from "styled-components";
import {
    ComposedChart,
    Line,
    Area,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';

const data = [
    {
        name: 'Problem A',
        uv: 100,
        pv: 31,
        amt: 1400,
    },
    {
        name: 'Problem B',
        uv: 80,
        pv: 56,
        amt: 1506,
    },
    {
        name: 'Problem C',
        uv: 70,
        pv: 78,
        amt: 989,
    },
    {
        name: 'Problem D',
        uv: 50,
        pv: 94,
        amt: 1228,
    },
    {
        name: 'Problem E',
        uv: 20,
        pv: 100,
        amt: 1100,
    }
]

const ExternalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Line2 = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.55);
  margin-left: 16px;
  margin-right: 16px;
`;

export function setTitle(d, dictionary) {
    let result = "";
    const foundRow = dictionary.find(l => l.label === d);
    if (foundRow) {
        result = foundRow.value;
    }
    return result || "n/a";
}

export default class External extends React.Component {
    render() {
        return (
            <ExternalContainer>
                <Searchbar/>
                <Line2/>
                <ExternalsList/>
            </ExternalContainer>
        );
    }
}
