import React, { useEffect, useState } from 'react';
import { Calendar, DateObject } from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import multiColors from 'react-multi-date-picker/plugins/colors';
import { connect } from 'react-redux';
import {
  saveCalendarTools,
  addHoliday,
  setNewHolidayToSave,
} from '../actions/toolsActions';
import {Button, ControlLabel, FormControl, FormGroup, InputGroup} from 'react-bootstrap';
import SaveModalContainer from "../utils/SaveModalContainer";
import MatButton from "../../components/common/Mat/MatButton";
import {LinkContainer} from "react-router-bootstrap";

function MainCalendar ({
  saveCalendarToolsData,
  isLoading,
  holidays,
  match,
  setNewHolidayToSaveData,
  addHolidayData,
}) {
  const dateObject = new DateObject();

  const toDateObject = (date, color) => {
    let data = new DateObject(dateObject).setDate(date);
    data.color = color;
    return data;
  };
  //
  // const colors = {
  //   red: [].map(toDateObject)
  // };
  //
  // Object.keys(colors).forEach(color => {
  //   colors[color].forEach((date, index) => {
  //     colors[color][index].color = color;
  //   });
  // });

  const initialProps = {
    numberOfMonths: 3,
    fullYear: true,
    displayWeekNumbers: true,
    weekStartDayIndex: 1
  };
  const [colorsArray, setColorsArray] = useState(['blue']);
  const [props, setProps] = useState(initialProps);
  const [showModal, setShowModal] = useState(false);
  const isRTL = ['fa', 'ar'].includes(props.locale?.name?.split?.('_')?.[1]);
  const [modalVersionMessage, setModalVersionMessage] = useState("");
  const updateCalendarState = (data) => {
    setProps(data);
    let holidays = [];
    data.value.forEach((value) => {
      if (holidays.length === 0) {
        holidays.push({ color: value.color, dates: [value.toDate()] });
      } else {
        const index = holidays.findIndex((el) => el.color === value.color);
        if (index !== -1) {
          holidays[index].dates.push(value.toDate());
        } else {
          holidays.push({ color: value.color, dates: [value.toDate()] });
        }
      }
    });
    setNewHolidayToSaveData(holidays);
  };

  // const allColorsArray = ['red', 'green', 'yellow', 'blue'];
  const addNewHoliday = (count = 0) => {
  //   if (count > 0) {
  //     setColorsArray(allColorsArray.slice(0, count));
  //   } else {
  //     if (colorsArray.length === 0) {
  //       setColorsArray([allColorsArray[0]]);
        addHoliday("blue");
  //     }
  //     if (colorsArray.length === 1) {
  //       setColorsArray([...colorsArray, allColorsArray[1]]);
  //       addHolidayData(allColorsArray[1]);
  //     }
  //     if (colorsArray.length === 2) {
  //       setColorsArray([...colorsArray, allColorsArray[2]]);
  //       addHolidayData(allColorsArray[2]);
  //     }
  //     if (colorsArray.length === 3) {
  //       setColorsArray([...colorsArray, allColorsArray[3]]);
  //       addHolidayData(allColorsArray[3]);
  //     }
  //   }
  };

  useEffect(() => {
    if(holidays && holidays.length){
      addNewHoliday(holidays.length);
      let colors = [];
      holidays.forEach((holiday, index) => {
        colors = [...colors, ...Array.from(holiday.dates).map(date => {return toDateObject(date, "blue");})];
      });
      updateCalendarState({
        ...props,
        value: colors
      });
    }
  }, [holidays, match.params.id])

  return (
    <div
      style={{
        justifyContent: 'center'
      }}
    >
      {!isLoading && (<div>
        <div style={{ display: 'block' }}>
          <Calendar
            {...props}
            onPropsChange={updateCalendarState}
            plugins={[
              <DatePanel
                position={isRTL ? 'left' : 'right'}
                sort="date"
                eachDaysInRange={!props.onlyMonthPicker && !props.onlyYearPicker}

              />,
              // multiColors({ position: 'bottom', colors: colorsArray }),
            ]}
          />
        </div>
        <div style={{ display: 'block', marginTop: 10, width: 990 }}>
          <LinkContainer to="/dashboard/calendar">
            <MatButton danger>Back</MatButton>
          </LinkContainer>
          <Button
              className="pull-right"
              bsStyle="primary"
              onClick={() => setShowModal(!showModal)}
          >Save</Button>
        </div>
        <SaveModalContainer
            showVersionMessageModal={showModal}
            closeModal={() => setShowModal(false)}
            onSave={() => saveCalendarToolsData(match.params.id, modalVersionMessage)}
            modalVersionMessage={modalVersionMessage}
            setModalVersionMessage={setModalVersionMessage}
            title="calendar"
        />
      </div>)}
      {isLoading && (<div>Loading...</div>)}
    </div>
  );
}

const mapStateToProps = ({ toolsReducer }) => {
  return {
    ...toolsReducer
  };
};

function mapDispatchToProps () {
  return dispatch => ({
    saveCalendarToolsData: (id, modalVersionMessage) => dispatch(saveCalendarTools(id, modalVersionMessage)),
    addHolidayData: (color) => dispatch(addHoliday(color)),
    setNewHolidayToSaveData: (holidays) => dispatch(setNewHolidayToSave(holidays))
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(MainCalendar);
