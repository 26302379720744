import React from "react"
import {
    Container,
    Container2,
    ContainerInside,
    IconContainer,
    IconSpan, MainContainer,
    MainValueContainer
} from "../../BetaPage/PrimaryContainer/common";
import styled, {css} from "styled-components";
import StyledIcon from "../../../../components/common/StyledIcon";
import {MainValueContainerHeader} from "../../BetaPage/common";

const ContentContainer = styled.div`
    padding: 18px;
    ${props => props.padding && css`
        @media (min-width: 1910px) { padding: ${props.padding} }
    `}
    ${props =>
    props.marginTop &&
    css`
                margin-top: ${props.marginTop};
            `
}
`;

const PaddingContainer = styled.div`
    @media (max-width: 1200px) {
        padding-top: 15px;
    }
`;

const CustomContainerInside = styled(ContainerInside)`
    @media (min-width: 1910px) {
        min-height: 145px;
    }
    @media (max-width: 1909px) {
        min-height: 170px !important;
    }
`;
export default function PrimaryRowContainer(props) {
    return (
        <>
            <PaddingContainer>
                <MainContainer height={props.height}>
                    <Container>
                        <CustomContainerInside height={props.height}>
                            <Container2>
                                <IconContainer mode={props.mode}>
                                    <IconSpan>
                                        <StyledIcon
                                            clickable
                                            glyph={props.icon}
                                            color="#fff"
                                            size="small"
                                        />
                                    </IconSpan>
                                </IconContainer>
                                <MainValueContainerHeader>
                                    {props.subtitle}
                                </MainValueContainerHeader>
                            </Container2>
                            <ContentContainer marginTop={props.marginTop} padding={props.padding}>
                                {props.children}
                            </ContentContainer>
                            </CustomContainerInside>
                    </Container>
                </MainContainer>
            </PaddingContainer>
        </>
    )
}
