import React from "react";
import { connect } from "react-redux";
import { TableBody } from "./common";

@connect(({ clientPanel }) => clientPanel.detailsPageReducer)
export default class HoursTable extends React.Component {
    render() {
        let hoursSummary = "";
        let generateTableWithDetails = (hoursReportData) => {
            let result = ""
            Object.keys(hoursReportData).forEach(el => {
                !!hoursReportData[el] && (
                    result += (<tr>
                        <td>${el}: </td>
                        <td> ${hoursReportData[el]} working hours.</td>
                    </tr>)
                )
            })
            return result;
        }
        if (this.props.tableData && this.props.tableData.workingHours) {
            let hoursReportData = this.props.tableData.workingHours;

            hoursSummary = (
                <tbody>
                {
                    hoursReportData.sumFlatRate ? (
                        <tr>
                            <td>Flat rate:</td>
                            <td> {hoursReportData.sumFlatRate} working hours.</td>
                        </tr>
                    ): ""
                }
                {!!hoursReportData.sumWorkingHours && (
                    <tr>
                        <td style={{border: 0}}>Detailed working hour(s): </td>
                        <td style={{border: 0}}> {hoursReportData.sumWorkingHours} includes: </td>
                    </tr>
                )}
                {Object.keys(hoursReportData).map(el => {
                    let result;
                    if (el !== "sumWorkingHours" && el !== "sumFlatRate" && hoursReportData.sumWorkingHours){
                        result = (
                            <tr>
                                <td>{el.slice(3, -5)}: </td>
                                <td> {hoursReportData[el]}</td>
                            </tr>
                        )
                    }

                    return result;
                })}
                {hoursReportData.sumWorkingHours && hoursReportData.sumFlatRate ? (
                    <tr>
                        <td>Summary flat rate + detailed working hour(s): </td>
                        <td>{hoursReportData.sumWorkingHours + hoursReportData.sumFlatRate}</td>
                    </tr>
                ): ""}
                {hoursReportData.message && (<div style={{fontWeight: 600, paddingTop: 20}}>No data to show in this period of time.</div>)}
                {hoursReportData.sumWorkingHours === 0 && (<div style={{fontWeight: 600, paddingTop: 20}}>No working hours.</div>)}
                </tbody>
            );
        }
        return (<div style={{marginTop: "-15px"}}>
            <TableBody responsive={true}>{hoursSummary}</TableBody>
        </div>);
    }
}
