import {
    DASHBOARD_CHART_DATA_UPDATE,
    DASHBOARD_CHART_IS_LOADING,
    DASHBOARD_DATA_UPDATE,
    DASHBOARD_IS_LOADING, DASHBOARD_PERIOD_NAME_UPDATE,
    DASHBOARD_PERIOD_UPDATE,
    DASHBOARD_RANGE_UPDATE, DASHBOARD_TABLE_DATA_LOADING, DASHBOARD_TABLE_DATA_UPDATE
} from "../actions/detailsPageActions";
import moment from "moment/moment";

export const initialState = {
    isLoading: false,
    data: {
        customerCompany: {},
        locationCompany: {}
    },
    range: {
        end: moment().endOf('day'),
        start: moment().startOf('month')
    },
    period: false,
    periodName: null,
    chartIsLoading: false,
    chartData: {},
    tableDataLoading: false,
    tableData: {}
}
export default function detailsPageReducer(state = initialState, action) {
    switch (action.type) {
        case DASHBOARD_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload
            }
        case DASHBOARD_DATA_UPDATE:
            return {
                ...state,
                data: action.payload
            }
        case DASHBOARD_PERIOD_UPDATE:
            return {
                ...state,
                period: action.payload
            }
        case DASHBOARD_RANGE_UPDATE:
            return {
                ...state,
                range: action.payload
            }
        case DASHBOARD_PERIOD_NAME_UPDATE:
            return {
                ...state,
                periodName: action.payload
            }
        case DASHBOARD_CHART_IS_LOADING:
            return {
                ...state,
                chartIsLoading: action.payload
            }
        case DASHBOARD_CHART_DATA_UPDATE:
            return {
                ...state,
                chartData: action.payload
            }
        case DASHBOARD_TABLE_DATA_LOADING:
            return {
                ...state,
                tableDataLoading: action.payload
            }
        case DASHBOARD_TABLE_DATA_UPDATE:
            return {
                ...state,
                tableData: action.payload
            }
        default:
            return state;
    }
}
