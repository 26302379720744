import React from "react"
import {
    Container,
    Container2,
    ContainerInside,
    IconContainer,
    IconSpan, MainContainer,
    MainValueContainer
} from "../BetaPage/PrimaryContainer/common";
import styled, {css} from "styled-components";
import StyledIcon from "../../../components/common/StyledIcon";
import {MainValueContainerHeader} from "../BetaPage/common";

const ContentContainer = styled.div`
    padding: 18px;
    ${props =>
    props.marginTop &&
    css`
                margin-top: ${props.marginTop};
            `
}
`;

const PaddingContainer = styled.div`
    @media (max-width: 768px) {
        padding-top: 40px;
    }
`;

export default function PrimaryContainerWithHeight(props) {
    return (
        <>
            <PaddingContainer>
                <MainContainer height={props.height} paddingLeft={props.paddingLeft}>
                    <Container>
                       <span>
                            <ContainerInside style={{minHeight: 180}} height={props.height}>
                                <Container2>
                                    <IconContainer mode={props.mode}>
                                        <IconSpan>
                                            <StyledIcon
                                                clickable
                                                glyph={props.icon}
                                                color="#fff"
                                                size="small"
                                            />
                                        </IconSpan>
                                    </IconContainer>
                                    <MainValueContainerHeader>
                                        {props.subtitle}
                                    </MainValueContainerHeader>
                                </Container2>
                                <ContentContainer marginTop={props.marginTop}>
                                    {props.children}
                                </ContentContainer>
                            </ContainerInside>
                        </span>
                    </Container>
                </MainContainer>
            </PaddingContainer>
        </>
    )
}
