import React, {useEffect, useState} from 'react'
import {Col, Row} from "react-bootstrap";
import HeaderContainer from "../BetaPage/HeaderContainer";
import PrimaryContainer from "./PrimaryContainer";
import PrimaryContainerWithHeight from "./PrimaryContainerWithHeight";
import Order from './PrimaryRow/Order';
import Range from './PrimaryRow/Range';
import Export from "./PrimaryRow/Export";
import Company from "./AddressRow/Company";
import WorkInstruction from "./WorkInstruction";
import {getOrder, getOrderChart, getOrderTable} from "./actions/detailsPageActions";
import {connect} from "react-redux";
import ChartContainer from "../BetaPage/ChartContainerComponent";
import styled from "styled-components";
import TotalTable from "./TotalTable";
import HoursTable from "./HoursTable";
import Details from "./PrimaryRow/Details";
import DetailedPartTable from "./DetailedPartTable";
import DailyPartTable from "./DailyPartTable";
import PrimaryRowContainer from "./PrimaryRow/PrimaryRowContainer";
import AddressRowContainer from "./AddressRow/AddressRowContainer";
import {MainSpinnerContainer} from "../MainSpinnerContainer";

const LeftChartCol = styled(Col)`
    @media (min-width: 992px) {
        padding-left: 10px;
        padding-top: 20px;
    }
    padding-right: 5px;
`;

const RightChartCol = styled(Col)`
    @media (min-width: 992px) {
        padding-right: 10px;
        padding-top: 20px;
        padding-left: 6px;
    }
    @media (max-width: 991px) {
        padding-top: 40px;
    }
`;

const CustomRow = styled(Row)`
    margin-left: auto; 
    margin-right: 12px;
    padding-top: 15px;
`;
function DetailsPage ({match, getOrderData, getOrderChartData, getOrderTableData, orderNumber, customerCompany, locationCompany, chartData, range, tableData}) {
    const [nokRangeGraph, setNokRangeGraph] = useState("week");
    const [reworkedRangeGraph, setReworkedRangeGraph] = useState("week");
    const [partsCheckedRangeGraph, setPartsCheckedRangeGraph] = useState("week");
    const [height, setHeight] = useState(440);
    const [rangeArray, setRangeArray] = useState(["week", "month", "year"]);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0)
        if (match.params.id) {
            getOrderData(match.params.id);
            getOrderChartData(match.params.id);
            getOrderTableData(match.params.id);
        }
    }, []);
    useEffect(() => {
        if(new Date(range.start).getTime() && new Date(range.end).getTime()) {
            getOrderChartData(match.params.id);
            getOrderTableData(match.params.id);
        }
        let selectedDaysRange = (new Date(range.end).getTime()-new Date(range.start).getTime())/(24*60*60*1000)
        if(selectedDaysRange > 365) {
            setRangeArray(["week", "month", "year"]);
        } else if (selectedDaysRange > 31) {
            setRangeArray(["week", "month"]);
        } else {
            setRangeArray(["week"]);
        }
    }, [range]);

    useEffect(() => {
        document.getElementById("container").scrollIntoView({ block: 'start', behavior: 'smooth' });
        setIsLoading(true);
        setTimeout(()=> setIsLoading(false),1800);
    }, []);

    useEffect(() => {
        if(tableData.generalSummaryPerPart && tableData.generalSummaryPerPart.rows) {
            let result = 220 + tableData.generalSummaryPerPart.rows.length * 40
            setHeight(result < 440 ? 440 : result)
        }
    }, [tableData])

    let setPartsCheckedRangeGraphFunction = () => {
        setPartsCheckedRangeGraph(rangeArray[(rangeArray.findIndex(el => el === partsCheckedRangeGraph) + 1) % rangeArray.length]);
    }

    let setNokGraphFunction = () => {
        setNokRangeGraph(rangeArray[(rangeArray.findIndex(el => el === nokRangeGraph) + 1) % rangeArray.length])
    }
    let setReworkedGraphFunction = () => {
        setReworkedRangeGraph(rangeArray[(rangeArray.findIndex(el => el === reworkedRangeGraph) + 1) % rangeArray.length])
    }

    return (
        <div id="container">
            {isLoading && (<MainSpinnerContainer />)}
            <div style={{fontSize: 16, backgroundColor: "rgb(240, 242, 245)"}}>
                <Row style={{marginLeft: "auto", marginRight: "auto"}}>
                    <Col xs={12} style={{paddingLeft: 32}}>
                        <HeaderContainer title="Reports"/>
                    </Col>
                </Row>
                <Row style={{marginLeft: "auto", marginRight: 12}}>
                    <Col xs={12} sm={6} lg={3} style={{padding: "0 0 15px"}}>
                        <PrimaryRowContainer icon="inbox" subtitle="Order" mode="1" height={"175px"}>
                            <Order />
                        </PrimaryRowContainer>
                    </Col>
                    <Col xs={12} sm={6} lg={3} style={{padding: "0 0 15px"}}>
                        <PrimaryRowContainer icon="check" subtitle="Details" mode="2" height={"175px"}>
                            <Details />
                        </PrimaryRowContainer>
                    </Col>
                    <Col xs={12} sm={6} lg={3} style={{padding: "0 0 15px"}}>
                        <PrimaryRowContainer icon="time" subtitle="Range" mode="3" padding="3px" height={"175px"}>
                            <Range />
                        </PrimaryRowContainer>
                    </Col>
                    <Col xs={12} sm={6} lg={3} style={{padding: "0 0 15px"}}>
                        <PrimaryRowContainer icon="download-alt" subtitle="Export" mode="4" height={"175px"}>
                            <Export orderId={match.params.id}/>
                        </PrimaryRowContainer>
                    </Col>
                </Row>
                {/*<WorkInstruction />*/}
                <CustomRow>
                    <Col xs={12} sm={6} md={6} style={{padding: "0 0 40px", height: 255}}>
                        <AddressRowContainer subtitle="Company" mode="2" icon="envelope">
                            <Company company={customerCompany}/>
                        </AddressRowContainer>
                    </Col>
                    <Col xs={12} sm={6} md={6} style={{padding: "0 0 40px", height: 255}}>
                        <AddressRowContainer subtitle="Location" mode="2" icon="envelope">
                            <Company company={locationCompany} />
                        </AddressRowContainer>
                    </Col>
                </CustomRow>
                <Row style={{marginLeft: "auto", paddingTop: 10, marginRight: 8}}>
                    <Col xs={12} md={4}>
                        <ChartContainer title="Parts checked" mode="1" data={chartData.partCheckedGraph} clickFunction={setPartsCheckedRangeGraphFunction} range={partsCheckedRangeGraph} rangeCount={rangeArray.length} />
                    </Col>
                    <Col xs={12} md={4}>
                        <ChartContainer title="NOK" textColor="red" mode="3" data={chartData.NOKGraph} clickFunction={setNokGraphFunction} newValue={1} range={nokRangeGraph} rangeCount={rangeArray.length} />
                    </Col>
                    <Col xs={12} md={4}>
                        <ChartContainer title="Reworked" textColor="darkorange" mode="3" data={chartData.reworkedGraph} clickFunction={setReworkedGraphFunction} newValue={0} range={reworkedRangeGraph}  rangeCount={rangeArray.length} />
                    </Col>
                </Row>
                <Row style={{marginLeft: "auto", marginRight: 12, paddingTop: 20}}>
                    <Col xs={12} sm={8} md={8} style={{padding: 0}}>
                        <PrimaryContainerWithHeight subtitle="Total" mode="2" height={`${height}px`} icon="th-list">
                                <TotalTable />
                        </PrimaryContainerWithHeight>
                    </Col>
                    <Col xs={12} sm={4} md={4} style={{padding: 0}}>
                        <PrimaryContainerWithHeight subtitle="Hours" mode="2" height={`${height}px`} icon="hourglass" paddingLeft={"30px"}>
                            <div style={{display: "inline-flex", paddingLeft: 2}}><HoursTable /></div>
                        </PrimaryContainerWithHeight>
                    </Col>
                </Row>
                <Row style={{ marginRight: 0, paddingTop: 35}}>
                    <Col xs={12}>
                        <PrimaryContainer subtitle="Divided" mode="2" height={`auto`} icon="th">
                            <DetailedPartTable />
                        </PrimaryContainer>
                    </Col>
                </Row>
                <Row style={{marginRight: 0, paddingTop: 0}}>
                    <Col xs={12}>
                        <PrimaryContainer subtitle="Detailed" mode="2" height={`auto`} icon="calendar">
                            <DailyPartTable />
                        </PrimaryContainer>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
function mapDispatchToProps() {
    return dispatch => ({
        getOrderData: (id) => dispatch(getOrder(id)),
        getOrderChartData: (id) => dispatch(getOrderChart(id)),
        getOrderTableData: (id) => dispatch(getOrderTable(id))
    })
}

const mapStateToProps = ({clientPanel}) => {
    return {
        ...clientPanel.detailsPageReducer.data,
        range: clientPanel.detailsPageReducer.range,
        chartData: clientPanel.detailsPageReducer.chartData,
        tableData: clientPanel.detailsPageReducer.tableData,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DetailsPage);
