import React from "react";
import { Col, FormControl, FormGroup, Glyphicon, InputGroup, Panel, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import styled from "styled-components";
import { PERMISSION_CREATE_USERS } from "../../../../common/src/permissions";
import AutoTable from "../../components/common/AutoTable";
import LinkCell from "../../components/common/AutoTable/cells/LinkCell";
import SimpleText from "../../components/common/AutoTable/cells/SimpleText";
import IconButton from "../../components/common/IconButton";
import { userHasPermissionSelector } from "../../selectors/authSelectors";
import { parseRawQueryString } from "../../urlUtils";
import { debounce } from "../../utils";
import { groupFilterChanged, loadUsersList, STATE_ERROR, STATE_LOADING, textSearchChanged } from "../actions/usersListActions";
import usersListTableModel from "../usersListTableModel";
import GroupSelect from "./GroupSelect";

const StyledGroupSelect = styled(GroupSelect)`
    margin-right: 12px;
`;




@connect(
    state => ({
        ...state.users.usersList,
        canCreateUsers: userHasPermissionSelector(state, PERMISSION_CREATE_USERS)
    }),
    dispatch => ({
        loadUsersList: q => dispatch(loadUsersList(q)),
        textSearchChanged: ev => dispatch(textSearchChanged(ev.target.value)),
        groupFilterChanged: ev => dispatch(groupFilterChanged(ev ? ev.value : null))
    })
)
export default class UsersListPage extends React.Component {
    componentDidMount() {
        this.loadData();
    }
    componentDidUpdate(oldProps) {
        if (oldProps.location.search !== this.props.location.search) {
            this.loadData();
        }
    }
    loadData() {
        const query = parseRawQueryString(this.props.location.search.replace(/^\?/g, ""));
        this.props.loadUsersList(query);
    }
    loadUsersWithDebounce = debounce(this.props.loadUsersList, 200);
    textSearchChanged = ev => {
        this.props.textSearchChanged(ev);
        this.loadUsersWithDebounce();
    };
    groupFilterChanged = ev => {
        this.props.groupFilterChanged(ev);
        this.loadUsersWithDebounce();
    };
    render() {
        const query = parseRawQueryString(this.props.location.search.replace(/^\?/g, ""));
        if (this.props.state === STATE_ERROR) {
            return (
                <div>
                    <Panel header="Error" bsStyle="danger">
                        {this.props.error}
                    </Panel>
                </div>
            );
        }
        return (
            <div>
                <h3>Users</h3>
                <Row>
                    <Col md={2}>
                        {this.props.canCreateUsers && (
                            <LinkContainer to={"/dashboard/users/list/new"}>
                                <IconButton wide marginBottom glyph="plus" bsStyle="success" noMarginTop />
                            </LinkContainer>
                        )}
                    </Col>
                    <Col md={5}>
                        <FormGroup>
                            <InputGroup>
                                <FormControl
                                    type="text"
                                    placeholder="Search by email or name"
                                    onChange={this.textSearchChanged}
                                    value={this.props.textSearch}
                                />
                                <InputGroup.Addon>
                                    <Glyphicon glyph="search" />
                                </InputGroup.Addon>
                            </InputGroup>
                        </FormGroup>
                    </Col>
                    <Col md={5}>
                        <StyledGroupSelect onChange={this.groupFilterChanged} value={this.props.groupFilter} />
                    </Col>
                </Row>

                <AutoTable
                    loading={this.props.state === STATE_LOADING}
                    model={usersListTableModel}
                    runtimeProps={{
                        query
                    }}
                />
            </div>
        );
    }
}
