import {apiFetch} from "../../../../actions/apiActions";
import {toast} from "react-toastify";
import {encodeParams, makeActionCreator} from "../../../../utils";
import moment from "moment/moment";
import download from "downloadjs";
import {customerViewFiltersSelector} from "../../../CustomerPanelPage/actions/customerPanelExternalsActions";
export const DASHBOARD_IS_LOADING = "DASHBOARD_IS_LOADING";
export const DASHBOARD_DATA_UPDATE = "DASHBOARD_DATA_UPDATE";
export const DASHBOARD_PERIOD_UPDATE = "DASHBOARD_PERIOD_UPDATE";
export const DASHBOARD_RANGE_UPDATE = "DASHBOARD_RANGE_UPDATE";
export const DASHBOARD_PERIOD_NAME_UPDATE = "DASHBOARD_PERIOD_NAME_UPDATE";
export const DASHBOARD_CHART_IS_LOADING = "DASHBOARD_CHART_IS_LOADING";
export const DASHBOARD_CHART_DATA_UPDATE = "DASHBOARD_CHART_DATA_UPDATE";
export const DASHBOARD_TABLE_DATA_LOADING = "DASHBOARD_TABLE_DATA_LOADING";
export const DASHBOARD_TABLE_DATA_UPDATE = "DASHBOARD_TABLE_DATA_UPDATE";

const setDashboardLoading = makeActionCreator(DASHBOARD_IS_LOADING, "payload");
const setDashboardDataUpdate = makeActionCreator(DASHBOARD_DATA_UPDATE, "payload");
const setDashboardChartLoading = makeActionCreator(DASHBOARD_CHART_IS_LOADING, "payload");
const setDashboardChartDataUpdate = makeActionCreator(DASHBOARD_CHART_DATA_UPDATE, "payload");
const setDashboardTableDataLoading = makeActionCreator(DASHBOARD_TABLE_DATA_LOADING, "payload");
const setDashboardTableDataUpdate = makeActionCreator(DASHBOARD_TABLE_DATA_UPDATE, "payload");

export const setPeriod = makeActionCreator(DASHBOARD_PERIOD_UPDATE, "payload");
export const setDateRange = makeActionCreator(DASHBOARD_RANGE_UPDATE, "payload");
export const setPeriodName = makeActionCreator(DASHBOARD_PERIOD_NAME_UPDATE, "payload");
export function getOrder(order) {
    return async (dispatch) => {
        dispatch(setDashboardLoading(true));
        try {
            let orderInformation = await dispatch(apiFetch(encodeParams`/api/customer-panel/orders/${order}/dashboard`));
            let orderInformationData = await orderInformation.json();

            if (!orderInformation.ok) {
                throw new Error(orderInformationData.message);
            }

            dispatch(setDashboardDataUpdate(orderInformationData))
            dispatch(setDashboardLoading(false));
        }
        catch (e) {
            toast(e.message, {type: 'error'});
        }
    }
}

export function getOrderChart(orderId) {
    return async (dispatch, getState) => {
        const range = getState().clientPanel.detailsPageReducer.range;
        dispatch(setDashboardChartLoading(true));
        try {
            let orderChart = null;
            if(getState().router.location.pathname.indexOf('/customer/dashboard/') === -1) { //simulation view
                let customerId = getState().router.location.pathname.split('/')[2];
                orderChart = await dispatch(apiFetch(encodeParams`/api/customer-panel/orders/${orderId}/chart/${customerId}?start=${range.start}&end=${range.end}`));
            } else { //customer view
                orderChart = await dispatch(apiFetch(encodeParams`/api/customer-panel/orders/${orderId}/chart?start=${range.start}&end=${range.end}`));
            }
            let orderChartData = await orderChart.json();

            if (!orderChart.ok) {
                throw new Error(orderChartData.message);
            }
            dispatch(setDashboardChartDataUpdate(orderChartData.data))
            dispatch(setDashboardChartLoading(false));
        }
        catch (e) {
            toast(e.message, {type: 'error'});
        }
    }
}

export function getOrderTable(order) {
    return async (dispatch, getState) => {
        const range = getState().clientPanel.detailsPageReducer.range;
        dispatch(setDashboardTableDataLoading(true));
        try {
            //new general summary
            let generalSummaryPerPart = await dispatch(
                apiFetch(
                    encodeParams`/api/customer-panel/externals/part-report?order=${order}&dateStart=${range.start}&dateEnd=${range.end}`
                )
            );
            let generalSummaryPerPartData = await generalSummaryPerPart.json();

            if (!generalSummaryPerPart.ok) {
                throw new Error(generalSummaryPerPartData.message);
            }

            //new working hours
            let workingHours = await dispatch(
                apiFetch(
                    encodeParams`/api/customer-panel/externals/hour-report?order=${order}&dateStart=${range.start}&dateEnd=${range.end}`
                )
            );
            let workingHoursData = await workingHours.json();
            if (!workingHours.ok) {
                throw new Error(workingHoursData.message);
            }


            //new details part report
            let detailedPartReport = await dispatch(
                apiFetch(
                    encodeParams`/api/customer-panel/externals/detail-part-report?order=${order}&dateStart=${range.start}&dateEnd=${range.end}`
                )
            );
            let detailedPartReportData = await detailedPartReport.json();
            if (!detailedPartReport.ok) {
                throw new Error(detailedPartReportData.message);
            }

            //new daily part report
            let dailyPartReport = await dispatch(
                apiFetch(
                    encodeParams`/api/customer-panel/externals/daily-part-report?order=${order}&dateStart=${range.start}&dateEnd=${range.end}`
                )
            );
            let dailyPartReportData = await dailyPartReport.json();
            if (!detailedPartReport.ok) {
                throw new Error(dailyPartReportData.message);
            }


            let tableData = {
                generalSummaryPerPart: generalSummaryPerPartData,
                workingHours: workingHoursData,
                detailedPartReport: detailedPartReportData,
                dailyPartReport: dailyPartReportData
            };
            dispatch(setDashboardTableDataUpdate(tableData));
            dispatch(setDashboardTableDataLoading(false));

        } catch (e) {
            toast(e.message, {type: 'error'});
        }
    }
}

export function generateCustomerReport(orderId) {
    return async (dispatch, getState) => {
        try {
            const range = getState().clientPanel.detailsPageReducer.range;
            let resp = await dispatch(
                apiFetch("/api/customer-panel/externals/generate-excel", undefined, {
                    dateStart: moment(range.start).format("YYYY-MM-DD"),
                    dateEnd: moment(range.end).format("YYYY-MM-DD"),
                    order: orderId
                })
            );
            if (resp.status !== 200) {
                throw new Error((await resp.json()).message);
            }

            let data = await resp.blob();
            download(data);
        } catch (e) {
            toast(e.message, { type: "error" });
        }
    };
}
