import { makeActionCreator, encodeParams, selectObjectKeys } from "../../utils";
import { apiFetch } from "../../actions/apiActions";
import { totalsDataChanged } from "./totalsActions";
import { loadOldExternals } from "./oldExternalsActions";
import { toast } from "react-toastify";
import { showLinkListModal, showPromptModal } from "../../actions/modalsActions";
import { reportLoadRequested } from "../../ReportsPage/actions/reportActions";
import { desktopsChanged, loadDesktop } from "../../DesktopsPage/actions/desktopsActions";
import { refreshApprovedReports } from "../../OfficePage/actions/approvedReportsTableActions";
import { changeReportStatusInReportsList } from "../../ReportsListPage/actions/resultsActions";

export const STATE_DEFAULT = "STATE_DEFAULT";
export const STATE_LOADED = "STATE_LOADED";
export const STATE_OLD_VERSION_LOADED = "STATE_OLD_VERSION_LOADED";
export const STATE_LOADING = "STATE_LOADING";
export const STATE_ERROR = "STATE_ERROR";

export const STATE_CHANGED = "EXTERNALS.EXTERNAL.STATE_CHANGED";
export const ERROR_CHANGED = "EXTERNALS.EXTERNAL.ERROR_CHANGED";
export const EXTERNAL_DATA_CHANGED = "EXTERNALS.EXTERNAL.EXTERNAL_DATA_CHANGED";
export const REMARKS_CHANGED = "EXTERNALS.EXTERNAL.REMARKS_CHANGED";
export const FLAT_RATE_CHANGED = "EXTERNALS.EXTERNAL.FLAT_RATE_CHANGED";
export const WORKING_HOURS_CHANGED = "EXTERNALS.EXTERNAL.WORKING_HOURS_CHANGED";
export const NAME_HOURS_CHANGED = "EXTERNALS.EXTERNAL.NAME_HOURS_CHANGED";
export const FROM_TIME_CHANGED = "EXTERNALS.EXTERNAL.FROM_TIME_CHANGED";
export const TO_TIME_CHANGED = "EXTERNALS.EXTERNAL.TO_TIME_CHANGED";
export const EXTERNAL_MODAL_OPEN_CHANGED = "EXTERNALS.EXTERNAL.EXTERNAL_MODAL_OPEN_CHANGED";
export const HIDDEN_FROM_CLIENT_CHANGED = "EXTERNALS.EXTERNAL.HIDDEN_FROM_CLIENT_CHANGED";
export const QA_ROW_SHOW_REMARKS_CHANGED = "EXTERNALS.EXTERNAL.QA_ROW_SHOW_REMARKS_CHANGED";
export const QA_ROW_REMARKS_CHANGED = "EXTERNALS.EXTERNAL.QA_ROW_REMARKS_CHANGED";
export const SHOW_REMARKS_CHANGED = "EXTERNALS.EXTERNAL.SHOW_REMARKS_CHANGED";
export const HIDE_REASON_CHANGED = "EXTERNALS.EXTERNAL.HIDE_REASON_CHANGED";
export const PRODUCT_QA_CHANGED = "EXTERNALS.EXTERNAL.PRODUCT_QA_CHANGED";
export const FROM_TIME_TO_TIME_CHANGED = "EXTERNALS.EXTERNAL.FROM_TIME_TO_TIME_CHANGED";

export const stateChanged = makeActionCreator(STATE_CHANGED, "payload");
export const errorChanged = makeActionCreator(ERROR_CHANGED, "payload");
export const externalDataChanged = makeActionCreator(EXTERNAL_DATA_CHANGED, "payload");
export const remarksChanged = makeActionCreator(REMARKS_CHANGED, "payload");
export const flatRateChanged = makeActionCreator(FLAT_RATE_CHANGED, "payload");
export const workingHoursChanged = makeActionCreator(WORKING_HOURS_CHANGED, "payload")
export const nameHoursChanged = makeActionCreator(NAME_HOURS_CHANGED, "payload", "name");
export const fromTimeChanged = makeActionCreator(FROM_TIME_CHANGED, "payload");
export const toTimeChanged = makeActionCreator(TO_TIME_CHANGED, "payload");
export const externalModalOpenChanged = makeActionCreator(EXTERNAL_MODAL_OPEN_CHANGED, "payload");
export const hiddenFromClientChanged = makeActionCreator(HIDDEN_FROM_CLIENT_CHANGED, "payload");
export const QARowShowRemarksChanged = makeActionCreator(QA_ROW_SHOW_REMARKS_CHANGED, "rowIndex", "payload");
export const QARowRemarksChanged = makeActionCreator(QA_ROW_REMARKS_CHANGED, "rowIndex", "payload");
export const showRemarksChanged = makeActionCreator(SHOW_REMARKS_CHANGED, "payload");
export const hideReasonChanged = makeActionCreator(HIDE_REASON_CHANGED, "payload");
export const productQaChanged = makeActionCreator(PRODUCT_QA_CHANGED, "payload");
export const fromTimeToTimeChanged = makeActionCreator(FROM_TIME_TO_TIME_CHANGED, "fromTime", "toTime");

export function loadExternalFromReportId(id, overrideControlDate = null) {
    return async (dispatch, getState) => {
        try {
            dispatch(externalModalOpenChanged(true));
            dispatch(stateChanged(STATE_LOADING));
            let existingExternalId = await dispatch(getExternalIdForReport(id));
            if (!existingExternalId) {
                if (await dispatch(checkOverdueReports(id))) {
                    dispatch(externalModalOpenChanged(false));
                    dispatch(stateChanged(STATE_DEFAULT));
                }
            }

            let resp = await dispatch(apiFetch(encodeParams`/api/externals/report/${id}`));
            if (!resp.ok) {
                throw new Error((await resp.json()).message);
            }
            let dataFromInternal = await resp.json();
            if (existingExternalId) {
                await dispatch(loadExistingExternal(existingExternalId, overrideControlDate));

                // Copy the old remarks from the external
                // Idk why it is here
                dataFromInternal.productQA = dataFromInternal.productQA.map((internalProduct, index) => {
                    // check if we are out of bounds, since sometimes rows may be added to the product QA table and the ydon't exist yet in the external
                    if (index >= getState().externals.external.productQA.length) {
                        return internalProduct;
                    }
                    return {
                        ...internalProduct,
                        remarks: getState().externals.external.productQA[index].remarks,
                        showRemarks: getState().externals.external.productQA[index].showRemarks
                    };
                });
                dispatch(productQaChanged(dataFromInternal.productQA));
                dispatch(fromTimeToTimeChanged(dataFromInternal.fromTime, dataFromInternal.toTime));
                const remarks = getState().externals.external.remarks;
                if (remarks && remarks !== dataFromInternal.remarks) {
                    if (remarks.indexOf(dataFromInternal.remarks) === -1) {
                        dispatch(remarksChanged(`${remarks} ${dataFromInternal.remarks}`));
                    }
                } else {
                    dispatch(remarksChanged(dataFromInternal.remarks));
                }
            } else {
                let totalsResp = await dispatch(
                    apiFetch(encodeParams`/api/externals/order/${dataFromInternal.connectedOrder}/totals`)
                );
                if (!totalsResp.ok) {
                    throw new Error((await totalsResp.json()).message);
                }

                dispatch(externalDataChanged(dataFromInternal));
                dispatch(totalsDataChanged(await totalsResp.json()));
                await dispatch(loadOldExternals(dataFromInternal.connectedOrder, dataFromInternal.controlDate));
            }
            dispatch(stateChanged(STATE_LOADED));
        } catch (e) {
            dispatch(errorChanged(e.message));
            dispatch(stateChanged(STATE_ERROR));
        }
    };
}

/**
 * Do not use externally!
 * @param {*} existingExternalId
 * @param {*} overrideControlDate
 */
export function loadExistingExternal(existingExternalId, overrideControlDate = null) {
    return async dispatch => {
        try {
            dispatch(externalModalOpenChanged(true));
            dispatch(stateChanged(STATE_LOADING));
            let resp = await dispatch(apiFetch(encodeParams`/api/externals/${existingExternalId}`));
            if (!resp.ok) {
                throw new Error((await resp.json()).message);
            }
            let data = await resp.json();

            if (overrideControlDate) {
                data = {
                    ...data,
                    controlDate: overrideControlDate
                };
            }
            dispatch(externalDataChanged(data));
            let totalsResp = await dispatch(apiFetch(encodeParams`/api/externals/order/${data.connectedOrder}/totals`));
            if (!totalsResp.ok) {
                throw new Error((await totalsResp.json()).message);
            }
            dispatch(totalsDataChanged(await totalsResp.json()));
            await dispatch(loadOldExternals(data.connectedOrder, data.controlDate));
        } catch (e) {
            dispatch(errorChanged(e.message));
            dispatch(stateChanged(STATE_ERROR));
        }
    };
}

export function checkOverdueReports(reportId) {
    return async dispatch => {
        let resp = await dispatch(apiFetch(encodeParams`/api/externals/order/reports-without-externals/${reportId}`));
        const data = await resp.json();
        if (!resp.ok) {
            throw new Error(data.message);
        }
        if (data && data.length > 0) {
            dispatch(externalModalOpenChanged(false));
            await dispatch(
                showLinkListModal({
                    title: "Warning",
                    message: "You have unaccepted reports, list below.",

                    links: data.map(d => ({
                        text: d.formularNumber,
                        href: encodeParams`/dashboard/reports/${d._id}`
                    }))
                })
            );
            return true;
        }
        return false;
    };
}

export function saveExternal(didHide = false) {
    return async (dispatch, getState) => {
        try {
            if (didHide) {
                const hideReason = await dispatch(
                    showPromptModal({
                        message: "Please explain why this external should be hidden",
                        title: "Are you sure to want hide this report for client?",
                        fieldLabel: "Reason",
                        okButtonText: "Hide Preexternal",
                        cancelButtonText: "Back to Preexternal",
                        // requireContent: true,
                        validateContent: true
                    })
                );
                if (!hideReason) {
                    dispatch(hiddenFromClientChanged(false));
                    return;
                }
                dispatch(hideReasonChanged(hideReason));
            }
            dispatch(stateChanged(STATE_LOADING));
            let resp;
            const externalId = getState().externals.external.id;
            if (externalId) {
                const message = await dispatch(
                    showPromptModal({
                        title: "Update message",
                        message:
                            "Would you like to leave a message for the client? If not you can leave the field empty.",
                        fieldLabel: "Message"
                    })
                );
                if (message == null) {
                    dispatch(stateChanged(STATE_LOADED));
                    return;
                }
                resp = await dispatch(
                    apiFetch(encodeParams`/api/externals/${externalId}`, {
                        method: "PATCH",
                        body: JSON.stringify({
                            ...getState().externals.external,
                            versionMetadata: {
                                message
                            }
                        })
                    })
                );
            } else {
                resp = await dispatch(
                    apiFetch("/api/externals", {
                        method: "POST",
                        body: JSON.stringify(getState().externals.external)
                    })
                );
            }

            if (!resp.ok) {
                throw new Error((await resp.json()).message);
            }
            let data = await resp.json();
            await dispatch(
                loadOldExternals(
                    getState().externals.external.connectedOrder,
                    getState().externals.external.controlDate
                )
            );
            dispatch(stateChanged(STATE_LOADED));
            toast("External successfully saved!", {
                type: "success"
            });
            dispatch(externalModalOpenChanged(false));
            //reload data on desktop after save external
            let currentPathname = getState().router.location.pathname;
            if (currentPathname === "/dashboard/desktops") {
                let activeDesktop = getState().desktops.desktop.activeDesktop;
                dispatch(loadDesktop(activeDesktop, false));
                //add loading effect in changing order
                let companies = getState().desktops.desktop.companies;
                let modalInfo = getState().desktops.desktop.modalInformation;
                if (modalInfo && "companyIndex" in modalInfo && "orderIndex" in modalInfo) {
                    companies[modalInfo.companyIndex].orders[modalInfo.orderIndex] = {
                        ...companies[modalInfo.companyIndex].orders[modalInfo.orderIndex],
                        status: "Updating"
                    };
                    dispatch(desktopsChanged(companies));
                }
                dispatch(modalInfo({ companyIndex: null, orderIndex: null }));
            }

            if (currentPathname === "/dashboard/office/approved") {
                dispatch(refreshApprovedReports());
            }

            if (getState().reports.report.reportId) {
                await dispatch(reportLoadRequested(getState().reports.report.reportId));
            }
            dispatch(
                changeReportStatusInReportsList(
                    getState().externals.external.connectedReport,
                    getState().externals.external.hiddenFromClient ? "hidden" : "visibleToCustomer"
                )
            );
        } catch (e) {
            dispatch(errorChanged(e.message));
            dispatch(stateChanged(STATE_ERROR));
        }
    };
}

/**
 * Returns external id for report id. Returns null if not found.
 * @param {String} reportId
 */
export function getExternalIdForReport(reportId) {
    return async dispatch => {
        let resp = await dispatch(apiFetch(encodeParams`/api/externals/id-for-report/${reportId}`));
        if (resp.status === 404) {
            return null;
        }
        let data = await resp.json();
        if (!resp.ok) {
            throw new Error(data.message);
        }
        return data;
    };
}
