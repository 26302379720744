import React from 'react';
import styled, {keyframes} from "styled-components";

const spin = keyframes`
  100% { transform: rotate(360deg) }
`;

const inchworm = keyframes`
  4.167%  { clip-path: polygon(50% 150%, -50% 50%, -50% 50%, 50% -50%, 50% 50%) }
  8.333%  { clip-path: polygon(-50% 50%, -50% 50%, -50% 50%, 50% -50%, 50% 50%) }
  12.5%   { clip-path: polygon(0% -50%, 0% -50%, 0% -50%, 50% -50%, 50% 50%) }
  16.667% { clip-path: polygon(50% -50%, 50% -50%, 50% -50%, 150% 50%, 50% 50%) }
  20.833% { clip-path: polygon(50% -50%, 50% -50%, 150% 50%, 50% 150%, 50% 50%) }
  25%     { clip-path: polygon(50% -50%, 150% 50%, 50% 150%, -50% 50%, 50% 50%) }
  29.167% { clip-path: polygon(150% 50%, 150% 50%, 50% 150%, -50% 50%, 50% 50%) }
  33.333% { clip-path: polygon(50% 150%, 50% 150%, 50% 150%, -50% 50%, 50% 50%) }
  37.5%   { clip-path: polygon(-50% 100%, -50% 100%, -50% 100%, -50% 50%, 50% 50%) }
  41.667% { clip-path: polygon(-50% 50%, -50% 50%, -50% 50%, 50% -50%, 50% 50%) }
  45.833% { clip-path: polygon(-50% 50%, -50% 50%, 50% -50%, 150% 50%, 50% 50%) }
  50%     { clip-path: polygon(-50% 50%, 50% -50%, 150% 50%, 50% 150%, 50% 50%) }
  54.167% { clip-path: polygon(50% -50%, 150% 50%, 150% 50%, 50% 150%, 50% 50%) }
  58.333% { clip-path: polygon(150% 50%, 150% 50%, 150% 50%, 50% 150%, 50% 50%) }
  62.5%   { clip-path: polygon(100% 150%, 100% 150%, 100% 150%, 50% 150%, 50% 50%) }
  66.667% { clip-path: polygon(50% 150%, 50% 150%, 50% 150%, -50% 50%, 50% 50%) }
  70.833% { clip-path: polygon(50% 150%, 50% 150%, -50% 50%, 50% -50%, 50% 50%) }
  75%     { clip-path: polygon(50% 150%, -50% 50%, 50% -50%, 150% 50%, 50% 50%) }
  79.167% { clip-path: polygon(-50% 50%, -50% 50%, 50% -50%, 150% 50%, 50% 50%) }
  83.333% { clip-path: polygon(50% -50%, 50% -50%, 50% -50%, 150% 50%, 50% 50%) }
  87.5% { clip-path: polygon(150% 0%, 150% 0%, 150% 0%, 150% 50%, 50% 50%) }
  91.667% { clip-path: polygon(150% 50%, 150% 50%, 150% 50%, 50% 150%, 50% 50%) }
  95.833% { clip-path: polygon(150% 50%, 150% 50%, 50% 150%, -50% 50%, 50% 50%) }
  0%,100% { clip-path: polygon(150% 50%, 50% 150%, -50% 50%, 50% -50%, 50% 50%) }
`;
const MainContainer = styled.div`
    position: fixed;
    top: 59px;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
`;
const SpinnerContainer = styled.div`
  *, ::before, ::after {
    box-sizing: inherit;
  }
  html {
    box-sizing: border-box;
  }

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //background: rgba(255, 255, 255, 0.7);
  //backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;

  label {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;
    color: rgb(9, 143, 233); /* change this to whatever you like */
  }

  /* Remove browser styles */
  progress {
    appearance: none;
    background: none;
  }
  progress::-moz-progress-bar {
    background: none;
  }
  progress::-webkit-progress-bar {
    display: none;
  }
  progress::-ms-fill {
    animation-name: none;
  }
  @media (-ms-high-contrast) {
    /* Show native spinner in Windows High-Contrast Mode */
    progress::-ms-fill {
      animation-name: -ms-ring;
    }
  }

  progress,
  label::before {
    margin: -5.65rem auto;
    width: 2em;
    height: 2em;
    font-size: 5em;
    border: 0.2em solid;
    border-radius: 99em; /* set to a large value to avoid becoming an oval if aspect ratio is off */
  }

  /* Vertically align second ring with the first */
  label::before {
    margin-bottom: -1.2em;
  }

  @supports (clip-path: polygon(0 0)) {
    label::before {
      content: "";
      opacity: 0.25;
    }

    progress {
      animation: ${inchworm} 5s linear infinite, ${spin} 1.5s linear infinite;
    }
  }
`;
export function MainSpinnerContainer() {
    return (
        <div>
            <MainContainer>
                <SpinnerContainer>
                    <label>
                        <progress />
                    </label>
                </SpinnerContainer>
            </MainContainer>
        </div>
    );
}
