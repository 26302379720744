import React from "react";
import {Button, ControlLabel, FormControl, FormGroup, Modal} from "react-bootstrap";

export default function SaveModalContainer({showVersionMessageModal, closeModal, onSave, modalVersionMessage, setModalVersionMessage, title}) {

    let saveAndClose = () => {
        onSave();
        setModalVersionMessage("");
        closeModal(true);
    }

    return (
        <>
            <Modal show={showVersionMessageModal} onHide={()=> closeModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Why are you changing the {title}?
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Describe the changes made and the reason for them</p>
                    <FormGroup>
                        <ControlLabel>Version message</ControlLabel>
                        <FormControl
                            value={modalVersionMessage}
                            onChange={(ev) => setModalVersionMessage(ev.target.value)}
                        />
                    </FormGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={()=> closeModal(false)}>Close</Button>
                    <Button onClick={()=> saveAndClose()}>Save</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
