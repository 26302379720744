import React from "react"
import {
    Container,
    ContainerInside,
    ContentContainer,
    Description,
    Header,
    MainBottomContainer,
    NotificationContainer
} from "./common";
import {NotificationRow} from "./NotificationRow";

export function OrdersOverview(props) {
    return (<>
        <MainBottomContainer>
            <Container>
                <ContainerInside>
                    <ContentContainer>
                        <Header style={{textTransform: "none"}}>
                            Orders overview
                        </Header>
                        <Description>
                            {props.events && props.events.length} used this month
                        </Description>
                        <NotificationContainer>
                            {props.events && props.events.map((el, index) => {
                                return (<NotificationRow key={index+el.date} header={el.title} description={el.date} variant={1}
                                                         color={el.color} iconName={el.iconName}
                                                         isLast={index === props.events.length - 1}/>)
                            })}
                        </NotificationContainer>
                    </ContentContainer>
                </ContainerInside>
            </Container>
        </MainBottomContainer>
    </>)
}
