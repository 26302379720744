import React from "react";
import { connect } from "react-redux";
import { TableBody } from "./common";

@connect(({ clientPanel }) => clientPanel.detailsPageReducer)
export default class TotalTable extends React.Component {
    render() {
        function isLowerCase(string) {
            return string.toLowerCase() === string;
        }
        function isUpperCase(string) {
            return string.toUpperCase() === string;
        }
        let header = "";
        let content = "";
        if (this.props.tableData && this.props.tableData.generalSummaryPerPart) {
            let generalSummaryPerPart = this.props.tableData.generalSummaryPerPart;
            header = (
                <tr>
                    {generalSummaryPerPart.header.map(i => (// title={setTitle(i.val, this.props.externals.letterColumnsDescription)}
                        <th key={i.key} style={{width: i.width, border: 0}}>
                            {i.val}
                        </th>
                    ))}
                </tr>
            );
            content = generalSummaryPerPart.rows.map((r, index) => {
                let row = (
                    <tr key={r.partNumber + index}>
                        <td>{r.partNumber}</td>
                        <td>{r.totalChecked}</td>
                        <td>{r.fromThisOk}</td>
                        <td>{r.reworked}</td>
                        {generalSummaryPerPart.header
                            .map((h, i) => {
                                if (i < generalSummaryPerPart.header.length - 1 && h.key.length === 1 && isLowerCase(h.key)) {
                                    return <td key={h.key}>{r[h.val]}</td>;
                                }
                                return false;
                            })
                            .filter(v => !!v)}
                        <td>{r.nok}</td>
                        {generalSummaryPerPart.header
                            .map((h, i) => {
                                if (i < generalSummaryPerPart.header.length - 1 && h.key.length === 1 && isUpperCase(h.key)) {
                                    return <td key={h.key}>{r[h.val]}</td>;
                                }
                                return false;
                            })
                            .filter(v => !!v)}
                        <td>{r.totalOk}</td>
                    </tr>
                );
                return row;
            });
            if(generalSummaryPerPart.rows.length === 0) {
                content = (<tr>
                    <td></td>
                    <td></td>
                    <td><b>No rows to show!</b></td>
                    <td></td>
                    {generalSummaryPerPart.header
                        .map((h, i) => {
                            if (i < generalSummaryPerPart.header.length - 1 && h.key.length === 1 && isLowerCase(h.key)) {
                                return <td key={h.key}>{r[h.val]}</td>;
                            }
                            return false;
                        })
                        .filter(v => !!v)}
                    <td></td>
                    {generalSummaryPerPart.header
                        .map((h, i) => {
                            if (i < generalSummaryPerPart.header.length - 1 && h.key.length === 1 && isUpperCase(h.key)) {
                                return <td key={h.key}></td>;
                            }
                            return false;
                        })
                        .filter(v => !!v)}
                    <td></td>
                </tr>);
            }
        }
        return (
            <TableBody responsive={true}>
                <tbody>
                    {header}
                    {content}
                </tbody>
            </TableBody>
        );
    }
}
