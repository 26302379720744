import React from "react";
import { Glyphicon } from "react-bootstrap";
import OutsideClickHandler from "react-outside-click-handler";
import { connect } from "react-redux";
import { PERMISSION_CLIENT_PANEL, PERMISSION_EDIT_USERS } from "../../../../common/src/permissions";
import { logoutRequested, STATE_LOGGED_IN } from "../../../../frontend/src/actions/authActions";
import AnimatedHider from "../../components/common/AnimatedHider";
import LogoSmall from "../../components/common/LogoSmall";
import {
    ButtonIcon,
    CustomLink,
    LeftMenuContainer,
    LineSeperator,
    LogoContainer,
    LogoTextContainer,
    MenuContainer,
    NavbarButton,
    NavbarContainer,
    NavbarDropdownContent,
    NavbarItemsRight,
    UserMenuListItem,
    UserMenuListItemIcon,
    UserNameContainer
} from "../../components/navbarStyles";
import { editMainMenu } from "../actions/customerPanelActions";

@connect(
    ({ auth, clientPanel }) => ({
        user: auth.user,
        authState: auth.authState,
        userTabEnabled: auth.user && auth.user.group.permissions.indexOf(PERMISSION_EDIT_USERS) !== -1,
        shouldShowMenuDropdown: auth.user && auth.user.group.permissions.indexOf(PERMISSION_CLIENT_PANEL) === -1,
        showMainMenu: clientPanel.clientPanels.showMainMenu
    }),
    dispatch => ({
        logoutRequested: _ => dispatch(logoutRequested()),
        editMainMenu: _ => dispatch(editMainMenu())
    })
)
export default class CustomerNavBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = { showUserMenu: false };
    }
    showUserMenu = () => {
        this.setState({ showUserMenu: true });
    };

    hideUserMenu = () => {
        this.setState({ showUserMenu: false });
    };

    render() {
        return (
            <NavbarContainer style={{backgroundColor: "#098fe9"}}>
                <LogoContainer showMainMenu={this.props.showMainMenu} expanded={this.props.showMainMenu}>
                    <LogoSmall />
                    <LogoTextContainer expanded={this.props.showMainMenu}>Panel</LogoTextContainer>
                </LogoContainer>

                <MenuContainer>
                    <LeftMenuContainer>
                        <CustomLink to="/customer/orders/list" white>
                            <NavbarButton>
                                <ButtonIcon>
                                    <Glyphicon glyph="home" />
                                </ButtonIcon>
                            </NavbarButton>
                        </CustomLink>
                    </LeftMenuContainer>
                </MenuContainer>
                <NavbarItemsRight>
                    <div>
                        <NavbarButton onClick={this.showUserMenu}>
                            <ButtonIcon>
                                <Glyphicon glyph="user" />
                                <UserNameContainer>
                                    {this.props.authState === STATE_LOGGED_IN ? this.props.user.displayName : "unnamed"}
                                </UserNameContainer>
                            </ButtonIcon>
                        </NavbarButton>

                        {!this.props.shouldShowMenuDropdown && (
                            <OutsideClickHandler onOutsideClick={this.hideUserMenu}>
                                <AnimatedHider show={this.state.showUserMenu}>
                                    <NavbarDropdownContent>
                                        <LineSeperator />
                                        <UserMenuListItem>
                                            <CustomLink to="/customer/account">
                                                <UserMenuListItemIcon glyph="user" />
                                                Account
                                            </CustomLink>
                                        </UserMenuListItem>
                                        <UserMenuListItem>
                                            <CustomLink to="/customer/regulations">
                                                <UserMenuListItemIcon glyph="info-sign" />
                                                Regulations
                                            </CustomLink>
                                        </UserMenuListItem>
                                        <LineSeperator black />
                                        <UserMenuListItem onClick={this.props.logoutRequested}>
                                            <UserMenuListItemIcon glyph="log-out" />
                                            Logout
                                        </UserMenuListItem>
                                        <LineSeperator />
                                    </NavbarDropdownContent>
                                </AnimatedHider>
                            </OutsideClickHandler>
                        )}
                    </div>
                </NavbarItemsRight>
            </NavbarContainer>
        );
    }
}
