import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {
    addScenarioTools,
    getAllScenarios
} from '../actions/scenariosActions';
import AutoTable from "../../components/common/AutoTable";
import scenarioTableModel from "./scenarioTableModel";
import MatButton from "../../components/common/Mat/MatButton";
import StyledIcon from "../../components/common/StyledIcon";
import styled from "styled-components";
import MatBox from "../../components/common/Mat/MatBox";
import CreateModalContainer from "../CreateModalContainer";

function ToolsScenario({getAllScenariosData, addScenarioToolsData}) {
    const [counterNumber, setCounterNumber] = useState(0);
    const [showNameModal, setShowNameModal] = useState(false);
    const [modalName, setModalName] = useState("");
    useEffect(() => {
        getAllScenariosData();
    }, [])
    const ResultsBox = styled(MatBox)`
      padding: 0;
      padding-top: 25px;

      strong {
        margin: 15px;
        font-size: 17px;
      }
    `;

    return (
        <div>
            <ResultsBox>
                <strong>Scenarios</strong>
                <MatButton
                    success
                    withIcon
                    style={{float: "right", marginRight: 20}}
                    onClick={() => setShowNameModal(true)}>
                    Add new scenario <StyledIcon glyph="plus"/>
                </MatButton>
                <AutoTable
                    model={scenarioTableModel}
                    runtimeProps={{
                        sortBy: "id",
                        sortByChanged: ev => {
                        }//this.props.dispatch(updateFilters({ sortBy: ev }))
                    }}
                    loading={false}
                />
            </ResultsBox>
            <CreateModalContainer
                showNameModal={showNameModal}
                closeModal={() => setShowNameModal(false)}
                onSave={() => addScenarioToolsData(modalName)}
                name={modalName}
                setName={setModalName}
                title="scenario"
                icon="wrench"
            />
        </div>
    );
}

const mapStateToProps = ({scenariosReducer, hoursRangeReducer}) => {
    return {
        ...scenariosReducer,
        ...hoursRangeReducer
    }
}

function mapDispatchToProps() {
    return dispatch => ({
        addScenarioToolsData: (modalName) => dispatch(addScenarioTools(modalName)),
        getAllScenariosData: () => dispatch(getAllScenarios())
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(ToolsScenario)
