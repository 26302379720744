import {
  TIMELINE_MONDAY_UPDATE,
  TIMELINE_HOURS_RANGE_UPDATE_RANGE,
  TIMELINE_HOURS_RANGE_ADD,
  TIMELINE_HOURS_RANGE_UPDATE,
  TIMELINE_HOURS_RANGE_DELETE,
  TIMELINE_HORUS_RANGE_CHANGE_CHECKBOX,
  TIMELINE_HOURS_CATEGORIES,
  TIMELINE_HOURS_CATEGORIES2,
  TIMELINE_HORUS_CATEGORY_RANGE_CHANGE_CHECKBOX,
  TIMELINE_HOURS_CATEGORIES_DELETE,
  TIMELINE_HOURS_CATEGORIES_ADD,
  TIMELINE_HOURS_CATEGORIES2_ADD,
  TIMELINE_HOURS_RANGE_UPDATE_BY_CATEGORY_AND_GROUP_INDEX,
  TIMELINE_HOURS_RANGE_UPDATE_BY_CATEGORY2_AND_GROUP_INDEX,
  TIMELINE_HOURS_NAME_UPDATE,
  TIMELINE_HOURS_SUBCATEGORIES_ADD,
  TIMELINE_HOURS_SUBCATEGORIES_DEL,
  // TIMELINE_HOURS_CHANGE_HOLIDAY_HOURS_AS_SUNDAY,
  TIMELINE_HOURS_SUBCATEGORIES2_ADD,
  TIMELINE_HOURS_SUBCATEGORIES2_DEL
} from "../actions/hoursRangeActions";

const initialState = {
  week: [
    {
      title: "Monday",
      ranges: []
    },
    {
      title: "Tuesday",
      ranges: []
    },
    {
      title: "Wednesday",
      ranges: []
    },
    {
      title: "Thursday",
      ranges: []
    },
    {
      title: "Friday",
      ranges: []
    },
    {
      title: "Saturday",
      ranges: []
    },
    {
      title: "Sunday",
      ranges: []
    },
    {
      title: "", ranges: [] // holiday 1
    },
    {
      title: "", ranges: [] // holiday 2
    },
    {
      title: "", ranges: [] // holiday 3
    },
    {
      title: "", ranges: [] // holiday 4
    }
  ],
  categories: [
    {category: 'normal', ranges: []}
  ],
  categories2: [
    {category: 'holiday', ranges: []}
  ],
  // holidayHoursAsSunday: null
}

export default function scenario(state = initialState, action) {
  switch (action.type) {
    case TIMELINE_HOURS_CATEGORIES:
      return {
        ...state,
        categories: action.payload
      }
    case TIMELINE_HOURS_CATEGORIES2:
      return {
        ...state,
        categories2: action.payload
      }
    case TIMELINE_HOURS_CATEGORIES_ADD: {
      return {
        ...state,
        categories: [...state.categories, action.payload]
      }
    }
    case TIMELINE_HOURS_CATEGORIES2_ADD: {
      return {
        ...state,
        categories2: [...state.categories2, action.payload]
      }
    }
    case TIMELINE_HOURS_SUBCATEGORIES_ADD: {
      return {
        ...state,
        categories: state.categories.map((category, categoryIndex) => {
          if(action.categoryIndex !== categoryIndex) return category;
          return {
            ...category,
            grouped: [...category.grouped, action.payload]
          }
        })
      }
    }
    case TIMELINE_HOURS_SUBCATEGORIES2_ADD: {
      return {
        ...state,
        categories2: state.categories2.map((category, categoryIndex) => {
          if(action.categoryIndex !== categoryIndex) return category;
          return {
            ranges: [...category.ranges, action.payload]
          }
        })
      }
    }
    case TIMELINE_HOURS_SUBCATEGORIES_DEL: {
      return {
        ...state,
        categories: state.categories.map((category, categoryIndex) => {
          if(action.categoryIndex !== categoryIndex) return category;
          let tmpGrouped = [...category.grouped]
          tmpGrouped.splice(action.groupIndex, 1)
          return {
            ...category,
            grouped: tmpGrouped
          }
        })
      }
    }
    case TIMELINE_HOURS_SUBCATEGORIES2_DEL: {
      return {
        ...state,
        categories2: state.categories2.map((category, categoryIndex) => {
          if(action.categoryIndex !== categoryIndex) return category;
          let tmpRanges = [...category.ranges]
          tmpRanges.splice(action.rangeIndex, 1)
          return {
            ...category,
            ranges: tmpRanges
          }
        })
      }
    }
    case TIMELINE_HOURS_CATEGORIES_DELETE:
      let tmpCategories = [...state.categories]
      tmpCategories.splice(action.payload, 1);
      return {
        ...state,
        categories: tmpCategories
      }
    case TIMELINE_HOURS_RANGE_UPDATE_BY_CATEGORY_AND_GROUP_INDEX:
      return {
        ...state,
        categories: state.categories.map((category, categoryIndex ) => {
          if(action.categoryIndex !== categoryIndex) return category;
          category.grouped.map((group, groupIndex) => {
            if(action.groupIndex !== groupIndex) {
              return group
            }
            group[action.field] = action.payload
            return group
          })
          return {
            ...category
          }
        })
      }
    case TIMELINE_HOURS_RANGE_UPDATE_BY_CATEGORY2_AND_GROUP_INDEX:
      return {
        ...state,
        categories2: state.categories2.map((category, categoryIndex ) => {
          if(action.categoryIndex !== categoryIndex) return category;
          category.ranges.map((range, rangeIndex) => {
            if(action.rangeIndex !== rangeIndex) {
              return range
            }
            range[action.field] = action.payload
            return range
          })
          return {
            ...category
          }
        })
      }
    case TIMELINE_HOURS_NAME_UPDATE:
      return {
        ...state,
        categories: state.categories.map((category, categoryIndex ) => {
          if(action.categoryIndex !== categoryIndex) return category;
          category.category = action.payload
          return {
            ...category
          }
        })
      }
    case TIMELINE_HOURS_RANGE_UPDATE:
      return {
        ...state,
        week: action.payload
      }
    case TIMELINE_HOURS_RANGE_ADD:
      return {
        ...state,
        week: state.week.map((day, i) => {
          if(action.weekIndex !== i) return day;
          return {
            ...day,
            ranges: [...day.ranges, action.payload]
          }
        })
      }
    case TIMELINE_HOURS_RANGE_DELETE:
      return {
        ...state,
        week: state.week.map((day, i) => {
          if(action.weekIndex !== i) return day;
          let tmp =day.ranges
          tmp.splice(action.rangeIndex, 1)
          return {
            ...day,
            ranges: tmp
          }
        })
      }
    case TIMELINE_HOURS_RANGE_UPDATE_RANGE:
      return {
        ...state,
        week: state.week.map((day, i) => {
          if (action.weekIndex !== i) return day;
          return {
            ...day,
            ranges: day.ranges.map((date, i) => {
              if(i !== action.rangeIndex) return date;
              return action.payload;
            })
          };
        })
      }
    case TIMELINE_HORUS_RANGE_CHANGE_CHECKBOX:
      return {
        ...state,
        week: state.week.map((day, i) => {
          if (action.weekIndex !== i) return day;
          return {
            ...day,
            checked: action.payload
          }
        })
      }
    case TIMELINE_HORUS_CATEGORY_RANGE_CHANGE_CHECKBOX:
      return {
        ...state,
        categories: state.categories.map((category, categoryIndex) => {
          if(action.categoryIndex !== categoryIndex) return category;
          category.grouped.map((group, groupIndex) => {
            if(action.groupIndex !== groupIndex) {
              return group
            }
            group.checkedDays[action.checkIndex] = action.payload;
            return {
              ...group
            }
          })
          return {
            ...category
          }
        })
      }
    // case TIMELINE_HOURS_CHANGE_HOLIDAY_HOURS_AS_SUNDAY:
    //   return {
    //     ...state,
    //     holidayHoursAsSunday: action.payload
    //   }
    default:
      return state;
  }
}
