import React from "react";
import { connect } from "react-redux";
import { userHasPermissionSelector } from "../../selectors/authSelectors";
import { PERMISSION_CHANGE_USER } from "../../../../common/src/permissions";
import IconButton from "../../components/common/IconButton";
import { showConfirmModal } from "../../actions/modalsActions";
import { apiFetch } from "../../actions/apiActions";
import { toast } from "react-toastify";

@connect(state => ({
    shouldShow: userHasPermissionSelector(state, PERMISSION_CHANGE_USER)
}))
export default class ChangeUserButton extends React.Component {
    changeUser = async () => {
        if (
            !(await this.props.dispatch(
                showConfirmModal({
                    title: "Are you sure?",
                    message: `Do you really want to log in as ${this.props.user.displayName} (${
                        this.props.user.email
                    })?`
                })
            ))
        ) {
            return;
        }
        try {
            const resp = await this.props.dispatch(
                apiFetch("/api/auth/sudo", {
                    method: "POST",
                    body: JSON.stringify({
                        userId: this.props.user._id
                    })
                })
            );
            const data = await resp.json();
            if (!resp.ok) {
                throw new Error(data.message);
            }
            localStorage.setItem("token", data.token);
            document.location = "/detect-user-home";
        } catch (e) {
            console.error(e);
            toast("Failed to change user: " + e.message, { type: "error" });
        }
    };
    render() {
        if (!this.props.shouldShow) {
            return null;
        }
        return (
            <IconButton
                glyph="user"
                bsStyle="warning"
                marginLeft
                onClick={this.changeUser}
                title="Log in as this user"
            />
        );
    }
}
