import {
  SCENARIO_IS_LOADING,
  SCENARIO_START_OF_WEEK_HOUR,
  SCENARIO_LIST,
  SCENARIO_TITLE,
  SCENARIO_CHANGE_SPLIT_REPORT,
  SCENARIO_WITHOUT_RANGE_UPDATE,
  SCENARIO_WITHOUT_RANGE_ADD,
  SCENARIO_WITHOUT_RANGE_UPDATE_DATA,
  SCENARIO_WITHOUT_RANGE_DEL,
  SCENARIO_SHORT_DESCRIPTION, SCENARIO_DESCRIPTION
} from "../actions/scenariosActions";

const initialState = {
  isLoading: false,
  startOfWeekHour: null,
  allScenarioList: [],
  title: null,
  scenariosWithoutRange: [],
  shortDescription: null,
  description: null
}

export default function scenario(state = initialState, action) {
  switch (action.type) {
    case SCENARIO_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload
      }
    case SCENARIO_START_OF_WEEK_HOUR:
      return {
        ...state,
        startOfWeekHour: action.payload
      }
    case SCENARIO_TITLE:
      return {
        ...state,
        title: action.payload
      }
    case SCENARIO_LIST:
      return {
        ...state,
        allScenarioList: action.payload
      }
    case SCENARIO_CHANGE_SPLIT_REPORT:
      return {
        ...state,
        splitReport: action.payload
      }
    case SCENARIO_WITHOUT_RANGE_UPDATE_DATA:
      return {
        ...state,
        scenariosWithoutRange: action.payload
      }
    case SCENARIO_WITHOUT_RANGE_UPDATE:
      return {
        ...state,
        scenariosWithoutRange: state.scenariosWithoutRange.map((el, i) => {
          if(action.index !== i) return el;
          el = action.payload;
          return el;
        })
      }
    case SCENARIO_WITHOUT_RANGE_ADD:
      return {
        ...state,
        scenariosWithoutRange: [...state.scenariosWithoutRange, action.payload]
      }
    case SCENARIO_WITHOUT_RANGE_DEL:
      return {
        ...state,
        scenariosWithoutRange: state.scenariosWithoutRange.slice(0, action.index).concat(state.scenariosWithoutRange.slice(action.index+1))
      }
    case SCENARIO_SHORT_DESCRIPTION:
      return {
        ...state,
        shortDescription: action.payload
      }
    case SCENARIO_DESCRIPTION:
      return {
        ...state,
        description: action.payload
      }
    default:
      return state;
  }
}
