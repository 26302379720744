import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  addCalendarTools,
  getAllTimeTools
} from '../actions/toolsActions';
import CreateModalContainer from "../CreateModalContainer";
import AutoTable from "../../components/common/AutoTable";
import styled from "styled-components";
import MatBox from "../../components/common/Mat/MatBox";
import calendarsTableModel from "../calendarsTableModel";
import StyledIcon from "../../components/common/StyledIcon";
import MatButton from "../../components/common/Mat/MatButton";

function ToolsCalendar({addCalendarToolsData, getAllTimeToolsData}) {
  const [showNameModal, setShowNameModal] = useState(false);
  const [modalName, setModalName] = useState("");

  useEffect(() => {
    getAllTimeToolsData();
  },[])

  const ResultsBox = styled(MatBox)`
    padding: 0;
    padding-top: 25px;
    strong {
        margin: 15px;
        font-size: 17px;
    }
`;

  return (
    <div>
      <ResultsBox>
        <strong>Calendars</strong>
          <MatButton success withIcon style={{float: "right", marginRight: 20}}
                     onClick={() => setShowNameModal(!showNameModal)}>
            Add new calendar<StyledIcon glyph="plus" />
          </MatButton>
          <AutoTable
            model={calendarsTableModel}
            runtimeProps={{
              sortBy: "id",
              sortByChanged: ev => {}//this.props.dispatch(updateFilters({ sortBy: ev }))
            }}
            loading={false}
          />
      </ResultsBox>
      <CreateModalContainer
          showNameModal={showNameModal}
          closeModal={() => setShowNameModal(false)}
          onSave={() => addCalendarToolsData(modalName)}
          calendarName={modalName}
          setName={setModalName}
          title="calendar"
          icon="calendar"
      />
    </div>
  );
}

const mapStateToProps = ({toolsReducer}) => {
  return {
    ...toolsReducer
  }
}

function mapDispatchToProps() {
  return dispatch => ({
    addCalendarToolsData: (calendarName) => dispatch(addCalendarTools(calendarName)),
    getAllTimeToolsData: () => dispatch(getAllTimeTools())
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(ToolsCalendar);
