import React, {useEffect, useState} from 'react'
import styled from "styled-components";
import StyledIcon from "../../../../components/common/StyledIcon";
import EditableInput from "./EditableInput";
import {Td, Container, Table, Th, Tr} from './common';
import {connect} from "react-redux";
import {goToOrder} from "./utils";

const TdFirst = styled(Td)`
    color: rgb(52, 71, 103);
    font-size: 14px;
    padding-left: 0px;
`;

const TdEmpty = styled(Td)`
    text-align: center;
    color: rgb(200, 207, 227);
`;
export const TD = styled.td`
    height: 64px;
    font-size: 12px;
    font-weight: bold;
    border-top: 0.0625rem solid rgb(240, 242, 245);
    color: rgb(123, 128, 154);
    text-align: center;
    padding: 5px 0;
`;

const Button = styled.button`
    border: 1px solid rgb(9, 143, 233);
    border-radius: 8px;
    color: rgb(9, 143, 233);
    background-color: #fff;
    height: 32px;
    line-height: 30px;
    width: 120px;
`
function OrdersTable(props) {
    const [sortOrder, setSortOrder] = useState(-1);

    let sortedTableDataASC = props.data.sort((o1, o2) => o1.timestamp - o2.timestamp).map((el, index) => {
        return (
            <Tr key={index + el.startDate}>
                <TdFirst><EditableInput el={el} setOrderName={props.setOrderName}/></TdFirst>
                <TD>{el.orderNumber}</TD>
                <TD>{el.startDate}</TD>
                <TD>{el.partNumber.toString().slice(0, 100)}</TD>
                <TD><Button onClick={() => props.goToOrderSite(el._id)}>VIEW PROJECT</Button></TD>
            </Tr>
        )
    })
    let sortedTableDataDSC = props.data.sort((o1, o2) => o2.timestamp - o1.timestamp).map((el, index) => {
        return (
            <Tr key={index + el.startDate}>
                <TdFirst><EditableInput el={el} setOrderName={props.setOrderName}/></TdFirst>
                <TD>{el.orderNumber}</TD>
                <TD>{el.startDate}</TD>
                <TD>{el.partNumber.toString().slice(0, 100)}</TD>
                <TD><Button onClick={() => props.goToOrderSite(el._id)}>VIEW PROJECT</Button></TD>

            </Tr>
        )
    })

    return (<Container>
        <Table>
            <thead>
            <tr>
                <Th style={{width: 300, paddingLeft: 0}}>Order name</Th>
                <Th style={{paddingLeft: 0, textAlign: "center"}}>Order NO.</Th>
                <Th style={{paddingLeft: 0, textAlign: "center"}}>
                    Start Date
                    {(sortOrder === 1) && (<StyledIcon
                        glyph="triangle-top"
                        title="Edit sort order"
                        style={{fontSize: 12, marginLeft: 12, cursor: "pointer"}}
                        onClick={() => setSortOrder(-1)}
                    />)}
                    {sortOrder === -1 && (<StyledIcon
                        glyph="triangle-bottom"
                        title="Edit sort order"
                        style={{fontSize: 12, marginLeft: 12, cursor: "pointer"}}
                        onClick={() => setSortOrder(1)}
                    />)}

                </Th>
                <Th style={{paddingLeft: 0, textAlign: "center"}}>Part NO. / NAME</Th>
                <Th style={{paddingLeft: 0, textAlign: "center"}}></Th>
            </tr>
            </thead>
            <tbody>
            {sortOrder === -1 && (sortedTableDataDSC)}
            {sortOrder === 1 && (sortedTableDataASC)}
            {props.data.length === 0 && (
                <tr>
                    <TdEmpty colSpan="4">
                        No row to show
                    </TdEmpty>
                </tr>
            )}
            </tbody>
        </Table>
    </Container>)
}

function mapDispatchToProps () {
    return dispatch => ({
        goToOrderSite: (id) => dispatch(goToOrder(id))
    })
}

export default connect(null, mapDispatchToProps)(OrdersTable);
//
