import React from "react";
import { Route } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import DashboardPage from "../DashboardPage/components/DashboardPage";
import LoginPage from "../LoginPage/components/LoginPage";
import PasswordResetFinishPage from "../PasswordResetFinishPage/components/PasswordResetFinishPage";
import PasswordResetPage from "../PasswordResetPage/components/PasswordResetPage";
import Modals from "./Modals";
import PleaseLoginModal from "./PleaseLoginModal";
import CustomerPage from "../CustomerPanel/components/CustomerPage";
import UserHomeRedirector from "./common/UserHomeRedirector";
import BetaPage from "../CustomerPanel/Dashboard/BetaPage/BetaPage";
import 'typeface-roboto'
/**
 * The root component rendered by the app. Contains routes to auth stuff.
 */

export default class AppComponent extends React.Component {
    render() {
        return (
            <div>
                <PleaseLoginModal />
                <Modals />
                <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} />
                <Route path="/customer" component={CustomerPage} />
                <Route path="/customer2" component={BetaPage} />
                <Route path="/dashboard" component={DashboardPage} />
                <Route path="/login/password-reset/:userId/:token" component={PasswordResetFinishPage} />
                <Route path="/login/password-reset" component={PasswordResetPage} />
                <Route path="/login" component={LoginPage} />
                <Route path="/detect-user-home" component={UserHomeRedirector} />
            </div>
        );
    }
}
