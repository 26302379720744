import { makeActionCreator, selectObjectKeys, encodeParams, validateClockTime } from "../../utils";
import { apiFetch } from "../../actions/apiActions";
import { showConfirmModal } from "../../actions/modalsActions";

export const ROW_ADDED = "REPORTS.EMPLOYEE_TIME_TABLE.ROW_ADDED";
export const ROW_REMOVED = "REPORTS.EMPLOYEE_TIME_TABLE.ROW_REMOVED";
export const NAME_CHANGED = "REPORTS.EMPLOYEE_TIME_TABLE.NAME_CHANGED";
export const DATE_OF_BIRTH_CHANGED = "REPORTS.EMPLOYEE_TIME_TABLE.DATE_OF_BIRTH_CHANGED";
export const FROM_TIME_CHANGED = "REPORTS.EMPLOYEE_TIME_TABLE.TIME_CHANGED";
export const TO_TIME_CHANGED = "REPORTS.EMPLOYEE_TIME_TABLE.TO_TIME_CHANGED";
export const BRAKE_CHANGED = "REPORTS.EMPLOYEE_TIME_TABLE.BRAKE_CHANGED";
export const ARRIVAL_TIME_CHANGED = "REPORTS.EMPLOYEE_TIME_TABLE.ARRIVAL_TIME_CHANGED";
export const EMPLOYEE_ID_CHANGED = "REPORTS.EMPLOYEE_TIME_TABLE.EMPLOYEE_ID_CHANGED";
export const EMPLOYEE_DATA_CHANGED = "REPORTS.EMPLOYEE_TIME_TABLE.EMPLOYEE_DATA_CHANGED";
export const EMPLOYEE_SEARCH_CACHE_KEY_CHANGED = "REPORTS.EMPLOYEE_TIME_TABLE.EMPLOYEE_CACHE_KEY_CHANGED";
export const EMPLOYEE_CONFLICT_STATUS_CHANGED = "REPORTS.EMPLOYEE_TIME_TABLE.EMPLOYEE_CONFLICT_STATUS_CHANGED";
export const EMPLOYEE_CONFLICT_REPORT_ID_CHANGED = "REPORTS.EMPLOYEE_TIME_TABLE.EMPLOYEE_CONFLICT_REPORT_ID_CHANGED";
export const EMPLOYEE_CONFLICT_MESSAGE_CHANGED = "REPORTS.EMPLOYEE_TIME_TABLE.EMPLOYEE_CONFLICT_MESSAGE_CHANGED";

// conflict status
export const CS_NOT_AVAILABLE = "CS_NOT_AVAILABLE";
export const CS_LOADING = "CS_LOADING";
export const CS_NO_CONFLICT = "CS_NO_CONFLICT";
export const CS_CONFLICT = "CS_CONFLICT";
export const CS_ERROR = "CS_ERROR";

export const rowAdded = makeActionCreator(ROW_ADDED),
    rowRemoved = makeActionCreator(ROW_REMOVED, "rowIndex"),
    nameChanged = makeActionCreator(NAME_CHANGED, "rowIndex", "payload"),
    dateOfBirthChanged = makeActionCreator(DATE_OF_BIRTH_CHANGED, "rowIndex", "payload"),
    fromTimeChanged = makeActionCreator(FROM_TIME_CHANGED, "rowIndex", "payload"),
    toTimeChanged = makeActionCreator(TO_TIME_CHANGED, "rowIndex", "payload"),
    brakeChanged = makeActionCreator(BRAKE_CHANGED, "rowIndex", "payload"),
    arrivalTimeChanged = makeActionCreator(ARRIVAL_TIME_CHANGED, "rowIndex", "payload"),
    employeeIdChanged = makeActionCreator(EMPLOYEE_ID_CHANGED, "rowIndex", "payload"),
    employeeDataChanged = makeActionCreator(EMPLOYEE_DATA_CHANGED, "rowIndex", "payload"),
    employeeSearchCacheKeyChanged = makeActionCreator(EMPLOYEE_SEARCH_CACHE_KEY_CHANGED, "key", "payload"),
    employeeConflictStatusChanged = makeActionCreator(EMPLOYEE_CONFLICT_STATUS_CHANGED, "rowIndex", "payload"),
    employeeConflictReportIdChanged = makeActionCreator(EMPLOYEE_CONFLICT_REPORT_ID_CHANGED, "rowIndex", "payload"),
    employeeConflictMessageChanged = makeActionCreator(EMPLOYEE_CONFLICT_MESSAGE_CHANGED, "rowIndex", "payload");

export function employeesSearched(query) {
    return async (dispatch, getState) => {
        let getData = async query => {
            let resp = await dispatch(apiFetch("/api/employees/search", {}, { query }));
            let data = await resp.json();
            if (resp.status !== 200) {
                throw new Error("API error", data.message);
            }
            return data;
        };
        try {
            let cache = getState().reports.employeeTimeTable.employeeSearchCache;
            if (!cache[query]) {
                let dataP = getData(query);
                dispatch(employeeSearchCacheKeyChanged(query, dataP));
                return dataP;
            }
            return await cache[query];
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error("Failed to search for employees", e);
            return [];
        }
    };
}

export function createNewEmployee(employeeData) {
    return async (dispatch, getState) => {
        let resp = await dispatch(
            apiFetch(encodeParams`/api/employees?connectedOrderId=${getState().reports.report.connectedOrderId}`, {
                method: "POST",
                body: JSON.stringify({
                    ...employeeData,
                    _id: undefined
                })
            })
        );
        let data = await resp.json();
        if (resp.status !== 200) {
            throw new Error("API didn't return 200: " + data.message);
        }
        return data;
    };
}

export function createNewEmployeesAndAttachIds() {
    return async (dispatch, getState) => {
        let employeeTimeTable = getState().reports.employeeTimeTable;
        let oldRows = employeeTimeTable.rows;
        let newRows = [];
        for (let i in oldRows) {
            let row = oldRows[i];
            let employeeId = null;
            if (row.employeeData) {
                if (row.employeeData._id) {
                    employeeId = row.employeeData._id;
                } else {
                    employeeId = (await dispatch(createNewEmployee(row.employeeData)))._id;
                }
            }
            newRows.push({
                ...row,
                employeeData: undefined, // unset employeeData so it won't be sent to the server
                employeeId
            });
        }

        return {
            ...employeeTimeTable,
            rows: newRows
        };
    };
}

export function checkConflictsForAllRows() {
    return async (dispatch, getState) => {
        for (let i = 0; i < getState().reports.employeeTimeTable.rows.length; i++) {
            await dispatch(checkConflicts(i, true));
        }
    };
}

export function checkConflicts(rowIndex, rethrowOnError = false) {
    return async (dispatch, getState) => {
        try {
            dispatch(employeeConflictStatusChanged(rowIndex, CS_LOADING));
            let row = getState().reports.employeeTimeTable.rows[rowIndex];
            if (
                !validateClockTime(row.fromTime) ||
                !validateClockTime(row.toTime) ||
                !row.employeeData ||
                !row.employeeData._id
            ) {
                dispatch(employeeConflictStatusChanged(rowIndex, CS_NOT_AVAILABLE));
                return;
            }
            let report = getState().reports.report;
            let resp = await dispatch(
                apiFetch(
                    encodeParams`/api/reports/time-conflicts?employeeId=${row.employeeData &&
                        row.employeeData._id}&fromTime=${row.fromTime}&toTime=${row.toTime}&orderId=${report.connectedOrderId}&controlDate=${new Date(report.controlDate).toJSON()}&currentReportId=${report.reportId}`
            ,{method: "POST",
                        body: JSON.stringify({
                            rows: getState().reports.employeeTimeTable.rows
                        }
                    )}),
                {}
            );
            let data = await resp.json();
            if (!resp.ok) {
                throw new Error(data.message);
            }
            if (data.conflicts.length > 0) {
                dispatch(employeeConflictReportIdChanged(rowIndex, data.conflicts[0]._id));
                dispatch(employeeConflictStatusChanged(rowIndex, CS_CONFLICT));
                dispatch(employeeConflictMessageChanged(rowIndex, data.conflicts[0].message));
            } else {
                dispatch(employeeConflictStatusChanged(rowIndex, CS_NO_CONFLICT));
            }
            // TODO: finish
        } catch (e) {
            dispatch(employeeConflictStatusChanged(rowIndex, CS_ERROR));
            console.error(e);
            if (rethrowOnError) {
                throw e;
            }
        }
    };
}
