import {
    ROW_ADDED,
    ROW_REMOVED,
    PART_NUMBER_CHANGED,
    REMARKS_CHANGED,
    TOTAL_CHECKED_CHANGED,
    OK_CHANGED,
    REWORKED_CHANGED,
    NOK_CHANGED,
    TOTAL_OK_CHANGED,
    LETTER_COLUMN_ADDED,
    LETTER_COLUMN_REMOVED,
    LETTER_COLUMN_CHANGED,
    SMALL_LETTER_COLUMN_ADDED,
    SMALL_LETTER_COLUMN_REMOVED,
    SMALL_LETTER_COLUMN_CHANGED,
    EMPTY_COLUMN_ADDED,
    EMPTY_COLUMN_REMOVED,
    EMPTY_COLUMN_CHANGED,
    EMPTY_COLUMN_LABEL_CHANGED,
    AUTOCOMPLETE_STATE_DEFAULT,
    PART_NUMBER_AUTOCOMPLETE_DATA_CHANGED,
    PART_NUMBER_AUTOCOMPLETE_STATE_CHANGED,
    EMPTY_COLUMNS_LOADED,
    CLEAN_UP_EMPTY_ROWS
} from "../actions/productQAActions";
import { REPORT_RESET, REPORT_DATA_CHANGED } from "../actions/reportActions";
import { updateRow } from "../../utils";

const LETTER_COLUMNS_LABELS = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "R",
    "S",
    "T",
    "U",
    "W",
    "X",
    "Y",
    "Z"
];

const SMALL_LETTER_COLUMNS_LABELS = [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "r",
    "s",
    "t",
    "u",
    "w",
    "x",
    "y",
    "z"
];

const makeRow = state => ({
    partNumber: "",
    autocompleteState: AUTOCOMPLETE_STATE_DEFAULT,
    autocompleteData: null,
    reworked: "",
    nok: "",
    totalChecked: "",
    ok: "",
    totalOK: "",
    letterColumnsContent: state ? state.letterColumns.map(_ => "") : [],
    smallLetterColumnsContent: state ? state.smallLetterColumns.map(_ => "") : [],
    emptyColumnsContent: state ? state.emptyColumns.map(_ => "") : [],
    remarks: ""
});

const makeInitialState = _ => ({
    rows: [makeRow()],
    letterColumns: [],
    smallLetterColumns:[],
    emptyColumns: []
});

export default function productQA(state = makeInitialState(), action) {
    switch (action.type) {
        case REPORT_RESET:
            return makeInitialState();
        case REPORT_DATA_CHANGED:
            return action.payload.productQA;
        case ROW_ADDED:
            return {
                ...state,
                rows: [...state.rows, makeRow(state)]
            };
        case ROW_REMOVED:
            return {
                ...state,
                rows: state.rows.filter((_, i) => i !== action.rowIndex)
            };
        case PART_NUMBER_CHANGED:
            return {
                ...state,
                rows: updateRow(state.rows, "partNumber", action)
            };
        case PART_NUMBER_AUTOCOMPLETE_DATA_CHANGED:
            return {
                ...state,
                rows: updateRow(state.rows, "autocompleteData", action)
            };
        case PART_NUMBER_AUTOCOMPLETE_STATE_CHANGED:
            return {
                ...state,
                rows: updateRow(state.rows, "autocompleteState", action)
            };
        case REMARKS_CHANGED:
            return {
                ...state,
                rows: updateRow(state.rows, "remarks", action)
            };
        case TOTAL_CHECKED_CHANGED:
            return {
                ...state,
                rows: updateRow(state.rows, "totalChecked", action)
            };
        case OK_CHANGED:
            return {
                ...state,
                rows: updateRow(state.rows, "ok", action)
            };
        case REWORKED_CHANGED:
            return {
                ...state,
                rows: updateRow(state.rows, "reworked", action)
            };
        case NOK_CHANGED:
            return {
                ...state,
                rows: updateRow(state.rows, "nok", action)
            };
        case TOTAL_OK_CHANGED:
            return {
                ...state,
                rows: updateRow(state.rows, "totalOK", action)
            };
        case LETTER_COLUMN_ADDED: {
            let newLetterColumns = [...state.letterColumns];
            let insertIndex = null;
            for (let i in LETTER_COLUMNS_LABELS) {
                if (!state.letterColumns[i] || state.letterColumns[i].label !== LETTER_COLUMNS_LABELS[i]) {
                    insertIndex = i;
                    newLetterColumns.splice(insertIndex, 0, { label: LETTER_COLUMNS_LABELS[i] });
                    break;
                }
            }
            if (insertIndex === null) return state;
            return {
                ...state,
                rows: state.rows.map(row => {
                    let newLetterColumnsContent = row.letterColumnsContent;
                    newLetterColumnsContent.splice(insertIndex, 0, "");
                    return {
                        ...row,
                        letterColumnsContent: newLetterColumnsContent
                    };
                }),
                letterColumns: newLetterColumns
            };
        }
        case LETTER_COLUMN_REMOVED:
            return {
                ...state,
                rows: state.rows.map(row => {
                    return {
                        ...row,
                        letterColumnsContent: row.letterColumnsContent.filter((_, i) => i !== action.columnIndex)
                    };
                }),
                letterColumns: state.letterColumns.filter((_, i) => i !== action.columnIndex)
            };
        case LETTER_COLUMN_CHANGED: {
            const updateColumnContent = columnContent => {
                return columnContent.map((content, columnIndex) => {
                    if (columnIndex !== action.columnIndex) return content;
                    return action.payload;
                });
            };
            return {
                ...state,
                rows: state.rows.map((row, rowIndex) => {
                    if (rowIndex !== action.rowIndex) return row;
                    return {
                        ...row,
                        letterColumnsContent: updateColumnContent(row.letterColumnsContent)
                    };
                })
            };
        }
        case EMPTY_COLUMN_ADDED: {
            let newColumn = {
                label: ""
            };
            return {
                ...state,
                emptyColumns: [...state.emptyColumns, newColumn],
                rows: state.rows.map(row => {
                    return {
                        ...row,
                        emptyColumnsContent: [...row.emptyColumnsContent, ""]
                    };
                })
            };
        }
        case EMPTY_COLUMN_REMOVED:
            return {
                ...state,
                rows: state.rows.map(row => {
                    return {
                        ...row,
                        emptyColumnsContent: row.emptyColumnsContent.filter((_, i) => i !== action.columnIndex)
                    };
                }),
                emptyColumns: state.emptyColumns.filter((_, i) => i !== action.columnIndex)
            };
        case EMPTY_COLUMN_CHANGED: {
            const updateEmptyColumnContent = columnContent => {
                return columnContent.map((content, columnIndex) => {
                    if (columnIndex !== action.columnIndex) return content;
                    return action.payload;
                });
            };
            return {
                ...state,
                rows: state.rows.map((row, rowIndex) => {
                    if (rowIndex !== action.rowIndex) return row;
                    return {
                        ...row,
                        emptyColumnsContent: updateEmptyColumnContent(row.emptyColumnsContent)
                    };
                })
            };
        }
        case EMPTY_COLUMN_LABEL_CHANGED:
            return {
                ...state,
                emptyColumns: state.emptyColumns.map((col, i) => {
                    if (i !== action.columnIndex) return col;
                    return {
                        ...col,
                        label: action.payload
                    };
                })
            };
        case EMPTY_COLUMNS_LOADED: {
            return {
                ...state,
                emptyColumns: action.payload.map(c => ({
                    label: c.label
                })),
                rows: state.rows.map(r => ({ ...r, emptyColumnsContent: action.payload.map(_ => "") }))
            };
        }
        case CLEAN_UP_EMPTY_ROWS: {
            return {
                ...state,
                rows: state.rows.filter(
                    row =>
                        !(
                            !row.partNumber &&
                            !row.remarks &&
                            !row.reworked &&
                            !row.nok &&
                            !row.totalChecked &&
                            !row.ok &&
                            !row.totalOK
                        )
                )
            };
        }
        case SMALL_LETTER_COLUMN_ADDED: {
            let newLetterColumns = [...state.smallLetterColumns];
            let insertIndex = null;
            for (let i in SMALL_LETTER_COLUMNS_LABELS) {
                if (!state.smallLetterColumns[i] || state.smallLetterColumns[i].label !== SMALL_LETTER_COLUMNS_LABELS[i]) {
                    insertIndex = i;
                    newLetterColumns.splice(insertIndex, 0, { label: SMALL_LETTER_COLUMNS_LABELS[i] });
                    break;
                }
            }
            if (insertIndex === null) return state;
            return {
                ...state,
                rows: state.rows.map(row => {
                    let newLetterColumnsContent = row.smallLetterColumnsContent;
                    newLetterColumnsContent.splice(insertIndex, 0, "");
                    return {
                        ...row,
                        smallLetterColumnsContent: newLetterColumnsContent
                    };
                }),
                smallLetterColumns: newLetterColumns
            };
        }
        case SMALL_LETTER_COLUMN_REMOVED:
            return {
                ...state,
                rows: state.rows.map(row => {
                    return {
                        ...row,
                        smallLetterColumnsContent: row.smallLetterColumnsContent.filter((_, i) => i !== action.columnIndex)
                    };
                }),
                smallLetterColumns: state.smallLetterColumns.filter((_, i) => i !== action.columnIndex)
            };
        case SMALL_LETTER_COLUMN_CHANGED: {
            const updateColumnContent = columnContent => {
                return columnContent.map((content, columnIndex) => {
                    if (columnIndex !== action.columnIndex) return content;
                    return action.payload;
                });
            };
            return {
                ...state,
                rows: state.rows.map((row, rowIndex) => {
                    if (rowIndex !== action.rowIndex) return row;
                    return {
                        ...row,
                        smallLetterColumnsContent: updateColumnContent(row.smallLetterColumnsContent)
                    };
                })
            };
        }
        default:
            return state;
    }
}
