import React from 'react';
import StyledIcon from "../../../../components/common/StyledIcon";
import {generateCustomerReport} from "../actions/detailsPageActions";
import {connect} from "react-redux";

function Export ({generateCustomerReport, orderId}) {
    return (
        <div style={{padding: 16, fontWeight: 200, fontSize: 16}}>
            <div style={{textAlign: "center", display: "block"}} onClick={()=>generateCustomerReport(orderId)}>
                <span>Download</span>
            </div>
        </div>
    )
}

function mapDispatchToProps() {
    return dispatch => ({
        generateCustomerReport: orderId => dispatch(generateCustomerReport(orderId))
    })
}

export default connect(null, mapDispatchToProps)(Export);
