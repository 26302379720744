import {encodeParams} from "../../../../utils";
import {push} from "react-router-redux";

export function getDetailsURL(orderId, state) {
    return (
        encodeParams`/customer/dashboard/order/${orderId}`
    );
}

function getDetailsURLForSimulation(orderId, userId) {
    return (
        encodeParams`/customer/${userId}/dashboard/order/${orderId}`
    );
}

export function goToOrder(orderId) {
    return async (dispatch, getState) => {
            if(getState().router.location.pathname.indexOf('/dashboard/simulations/customer/') !== -1) {
                dispatch(push(`${getDetailsURLForSimulation(orderId, getState().router.location.pathname.split("/")[4])}`));
            } else {
                dispatch(push(`${getDetailsURL(orderId, getState())}`));
            }
    };
}
