import React from "react";
import { connect } from "react-redux";
import { STATE_LOADED } from "../../actions/customerPanelExternalsActions";
import { TableBody } from "./Styles";

@connect(({ clientPanel }) => clientPanel.clientPanelExternals)
export default class WorkingHours2 extends React.Component {
    render() {
        let hoursSummary = "";
        let generateTableWithDetails = (hoursReportData) => {
            let result = ""
            Object.keys(hoursReportData).forEach(el => {
                !!hoursReportData[el] && (
                    result += (<tr>
                        <td>${el}: </td>
                        <td> ${hoursReportData[el]} working hours.</td>
                    </tr>)
                )
            })
            return result;
        }
        if (this.props.state === STATE_LOADED) {
            let hoursReportData = this.props.externals.workingHours;

            hoursSummary = (
                <tbody>
                {
                    hoursReportData.sumFlatRate ? (
                        <tr>
                            <td>Flat rate:</td>
                            <td> {hoursReportData.sumFlatRate} working hours.</td>
                        </tr>
                    ): ""
                }
                    {!!hoursReportData.sumWorkingHours && (
                        <tr>
                            <td>Detailed working hour(s): </td>
                            <td> {hoursReportData.sumWorkingHours} includes: </td>
                        </tr>
                    )}
                    {Object.keys(hoursReportData).map(el => {
                        let result;
                        if (el !== "sumWorkingHours" && el !== "sumFlatRate" && hoursReportData.sumWorkingHours){
                            result = (
                                <tr>
                                    <td>{el.slice(3, -5)}: </td>
                                    <td> {hoursReportData[el]}</td>
                                </tr>
                            )
                        }

                        return result;
                    })}
                {hoursReportData.sumWorkingHours && hoursReportData.sumFlatRate ? (
                    <tr>
                        <td>Summary flat rate + detailed working hour(s): </td>
                        <td>{hoursReportData.sumWorkingHours + hoursReportData.sumFlatRate}</td>
                    </tr>
                ): ""}
                </tbody>
            );
        }
        return <TableBody responsive={true}>{hoursSummary}</TableBody>;
    }
}
