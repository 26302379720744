import React from "react";
import { connect } from "react-redux";
import { STATE_LOADED } from "../../actions/customerPanelExternalsActions";
import { setTitle } from "./External";
import { TableBody } from "./Styles";

@connect(({ clientPanel }) => clientPanel.clientPanelExternals)
export default class GeneralSummaryPerPart extends React.Component {
    render() {
        function isLowerCase(string) {
            return string.toLowerCase() === string;
        }
        function isUpperCase(string) {
            return string.toUpperCase() === string;
        }
        let header = "";
        let content = "";
        if (this.props.state === STATE_LOADED) {
            let generalSummaryPerPart = this.props.externals.generalSummaryPerPart;
            header = (
                <tr>
                    {generalSummaryPerPart.header.map(i => (
                        <th title={setTitle(i.val, this.props.externals.letterColumnsDescription)} key={i.key} style={{width: i.width}}>
                            {i.val}
                        </th>
                    ))}
                </tr>
            );
            content = generalSummaryPerPart.rows.map((r, index) => {
                let row = (
                    <tr key={r.partNumber + index}>
                        <td>{r.partNumber}</td>
                        <td>{r.totalChecked}</td>
                        <td>{r.fromThisOk}</td>
                        <td>{r.reworked}</td>
                        {generalSummaryPerPart.header
                            .map((h, i) => {
                                if (i < generalSummaryPerPart.header.length - 1 && h.key.length === 1 && isLowerCase(h.key)) {
                                    return <td key={h.key}>{r[h.val]}</td>;
                                }
                                return false;
                            })
                            .filter(v => !!v)}
                        <td>{r.nok}</td>
                        {generalSummaryPerPart.header
                            .map((h, i) => {
                                if (i < generalSummaryPerPart.header.length - 1 && h.key.length === 1 && isUpperCase(h.key)) {
                                    return <td key={h.key}>{r[h.val]}</td>;
                                }
                                return false;
                            })
                            .filter(v => !!v)}
                        <td>{r.totalOk}</td>
                    </tr>
                );
                return row;
            });
        }
        return (
            <TableBody responsive={true}>
                <tbody>
                    {header}
                    {content}
                </tbody>
            </TableBody>
        );
    }
}
